import React from 'react';

import { ReactComponent as ImageSample } from 'Assets/image-sample.svg';

type MainPropsT = {
    className?: string
}

const ImageClaimNoPhoto: React.FC<MainPropsT> = ({ className }) => {
    return (
        <ImageSample
            className={className}
        />
    );
}
export default ImageClaimNoPhoto;