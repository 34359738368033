import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { codes } from 'country-calling-code';
import { useSelector } from 'react-redux';
import Calendar from 'react-calendar';
import cx from 'classnames';

import DropDownSearch from 'Components/DropDownSearch/DropDownSearch';
import { StateLocalT, ValidationFieldsT } from '../../CreateForm';
import { addDateHours, getDateYearsFromNow } from 'Helpers/date';
import { countriesCodes } from 'Helpers/languageCode';
import Checkbox from 'Components/Checkbox/Checkbox';
import PhoneDirect from "./PhoneDirect/PhoneDirect";
import { capitalizeString } from 'Helpers/strings';
import { PageConfigT } from "Store/modules/page";
import { validField } from 'Helpers/validForm';
import { defaultLanguage } from "Helpers/i18";
import useValidPhone from "./useValidPhone";
import { countrys } from 'Helpers/countrys';
import style from './Contact.module.scss';
import { AppStateT } from 'Store/modules';
import { Input } from 'Components';

type MainPropsT = {
    state: StateLocalT,
    setValidStep: (result: boolean) => void,
    updateClaim: (dataType: string, data: any) => void,
    updateState: (dataType: string, data: any) => void,
    mobile?: boolean,
    setCountryName: (name: string) => void
}

let timeoutZipCode: any = null;

const Contact: React.FC<MainPropsT> = ({ state, setValidStep, updateClaim, updateState, setCountryName }) => {

    const [travelDate, setTravelDate] = useState<Date | null>(null);
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const { t } = useTranslation();
    const language = useSelector<AppStateT, string>((state) => {
        const languageCode = countriesCodes.find((code) => state.i18n.language === code.countryCode)
        return languageCode?.localeCode || defaultLanguage
    });
    const [showModalPhoneDirect, setShowModalPhoneDirect] = useState(false);
    const validPhone = useValidPhone(state.claimData.contact, pageConfig.inputs);

    useEffect(() => {
        return () => clearTimeout(timeoutZipCode);
    }, []);

    useEffect(() => {
        const country = countrys.find(c => c.value === state.claimData.contact.country.code);
        if (country) setCountryName(country?.text);
    }, [state.claimData.contact.country.code, setCountryName ]);

    const checkValidContact = useCallback(() => {
        const isValid = validField('clientAddress-street', state.claimData.contact.address) &&
            validField('clientAddress-city', state.claimData.contact.city) &&
            validField('clientAddress-zipCode', state.claimData.contact.zipCode) &&
            validPhone;

        setValidStep(isValid);
    }, [state.claimData.contact, validPhone, setValidStep]);

    useEffect(() => {
        checkValidContact();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.claimData.contact, validPhone, checkValidContact]);

    const actionChangeCountry = useCallback((data: any, prefixPhone: string|null|undefined) => {
        updateClaim('contact', {
            ...state.claimData.contact,
            country: {
                name: data.text,
                code: data.value,
            },
            prefixPhone
        });
        checkValidContact();
    }, [updateClaim, state.claimData.contact, checkValidContact]);

    const actionChangeContact = useCallback((dataType: string, ev: React.ChangeEvent<HTMLInputElement>) => {
        const value = dataType === 'phone' && state.claimData.contact.prefixPhone ? ev.target.value.replace(/\D/g, '') : ev.target.value;
        updateClaim('contact', {
            ...state.claimData.contact,
            [dataType]: dataType === 'address' ? value : capitalizeString(value)
        });
        checkValidContact();
    }, [state.claimData.contact, updateClaim, checkValidContact])

    const onChangeCalendar = useCallback((date: Date) => {
        const newDate = addDateHours(date, 12);
        updateClaim('dateNextTravel', newDate);
        setTravelDate(newDate);
    }, [updateClaim]);

    const scrollDownCalendar = useCallback(() => {
        setTimeout(() => {
            const element = document.getElementById('bodySlider');
            if (element){
                element.scrollTop = element.scrollHeight;
            }
        }, 100);
    }, []);

    const actionCheckNextTravel = useCallback((scroll = false) => {
        if (!!state.claimData.dateNextTravel){
            setTravelDate(state.claimData.dateNextTravel);
            updateClaim('dateNextTravel', null);
        } else {
            updateClaim('dateNextTravel', travelDate || (new Date()));
        }
        if (scroll){
            scrollDownCalendar();
        }
    }, [state.claimData.dateNextTravel, setTravelDate, updateClaim, travelDate, scrollDownCalendar]);

    const handleFieldFocus = (field: keyof ValidationFieldsT) => {
        if (state.validation.timer[field]) {
            clearTimeout(state.validation.timer[field]!);
        }
    };
            
    const handleFieldBlur = (field: keyof ValidationFieldsT) => {
        const timer = setTimeout(() => {
            updateState('validation', {
                ...state.validation,
                show: {
                    ...state.validation.show,
                    [field]: true
                }
            });
        }, 1000);
    
        updateState('validation', {
            ...state.validation,
            timer: {
                ...state.validation.timer,
                [field]: timer
            }
        });
    };

    return (
        <div className={style.root}>

            <div className={style.header}>
                <span className={style.title}>{t('labels.address')}</span>
            </div>

            <div className={style.inputContainer}>
                <DropDownSearch
                    label={t('labels.country')}
                    data={countrys}
                    onChange={(data) => {
                        actionChangeCountry(data, codes.find(c => c.isoCode2 === data.value)?.countryCodes[0] || null);
                        checkValidContact();
                    }}
                    value={state.claimData.contact.country.name}
                    className={style.inputLayer}
                    t={t}
                    search
                    id={'search_new_claim_contact_country'}
                />
            </div>

            <div className={style.inputContainer}>
                <Input
                    value={state.claimData.contact.city}
                    onChange={ev => {
                        actionChangeContact('city', ev);
                        checkValidContact();
                    }}
                    label={t('labels.city')}
                    className={style.inputLayer}
                    maxLength={60}
                    id={'input_new_claim_contact_city'}
                    notValid={state.validation.show.city && !validField('clientAddress-city', state.claimData.contact.city)}
                    onFocus={() => handleFieldFocus('city')}
                    onBlur={() => handleFieldBlur('city')}
                    touched={!validField('clientAddress-city', state.claimData.contact.city)}
                />
                {state.validation.show.city && !validField('clientAddress-city', state.claimData.contact.city) && (
                    <div className={style.inputErrorLabel}>
                        {t('validation.invalidCity')}
                    </div>
                )}
            </div>

            <div className={style.inputContainer}>
                <Input
                    value={state.claimData.contact.address}
                    onChange={ev => {
                        actionChangeContact('address', ev);
                        checkValidContact();
                    }}
                    label={t('labels.address')}
                    className={style.inputLayer}
                    maxLength={60}
                    id={'input_new_claim_contact_address'}
                    notValid={state.validation.show.street && !validField('clientAddress-street', state.claimData.contact.address)}
                    onFocus={() => handleFieldFocus('street')}
                    onBlur={() => handleFieldBlur('street')}
                    touched={!validField('clientAddress-street', state.claimData.contact.address)}
                />
                {state.validation.show.street && !validField('clientAddress-street', state.claimData.contact.address) && (
                    <div className={style.inputErrorLabel}>
                        {t('validation.invalidStreet')}
                    </div>
                )}
            </div>

            <div className={style.inputContainer}>
                <Input
                    value={state.claimData.contact.zipCode}
                    onChange={ev => {
                        actionChangeContact('zipCode', ev);
                        checkValidContact();
                    }}
                    label={t('labels.zip')}
                    className={style.inputLayer}
                    maxLength={10}
                    id={'input_new_claim_contact_zip'}
                    notValid={state.validation.show.zipCode && !validField('clientAddress-zipCode', state.claimData.contact.zipCode)}
                    onFocus={() => handleFieldFocus('zipCode')}
                    onBlur={() => handleFieldBlur('zipCode')}
                    touched={!validField('clientAddress-zipCodeCommon', state.claimData.contact.zipCode)}
                />
                {state.validation.show.zipCode && !validField('clientAddress-zipCode', state.claimData.contact.zipCode) && (
                    <div className={style.inputErrorLabel}>
                        {t('validation.invalidZipCode')}
                    </div>
                )}
            </div>

            <div className={style.inputContainer}>
                <Input
                    value={state.claimData.contact.phone}
                    onChange={ev => {
                        actionChangeContact('phone', ev);
                        checkValidContact();
                    }}
                    label={t('createClaim.sumPhone')}
                    className={style.inputLayer}
                    alwaysSmallLabel
                    prefixText={state.claimData.contact.prefixPhone ? `+${state.claimData.contact.prefixPhone}` : null}
                    onPrefixClick={() => setShowModalPhoneDirect(true)}
                    id={'input_new_claim_contact_phone'}
                    notValid={state.validation.show.phoneNumber && !validPhone}
                    onFocus={() => handleFieldFocus('phoneNumber')}
                    onBlur={() => handleFieldBlur('phoneNumber')}
                />
                {state.validation.show.phoneNumber && !validPhone && (
                    <div className={style.inputErrorLabel}>
                        {t('validation.invalidPhoneNumber')}
                    </div>
                )}
            </div>

            <div className={cx(style.header, style.headerTravelling)}>
                {t('createClaim.calendarNextTravelQuestion2')}                
            </div>

            <div className={style.calendarCheck}>
                <div className={style.checkLayer}>
                    <Checkbox checked={!!!state.claimData.dateNextTravel} name={'Reg'} onChange={actionCheckNextTravel} id={"checkbox_new_claim_date_next_travel_no"} />  {t('common.no')}
                </div>
                <div className={style.checkLayer}>
                    <Checkbox checked={!!state.claimData.dateNextTravel} name={'Reg'} onChange={() => actionCheckNextTravel(true)} id={"checkbox_new_claim_date_next_travel_yes"} />  {t('common.yes')}
                </div>
            </div>

            {state.claimData.dateNextTravel && (
                <div className={cx(style.header, style.headerPickDate)}>
                    {t('createClaim.calendarNextTravelQuestion')}
                </div>
            )}

            <div className={cx(style.calendar, { [style.calendarDisable]: !!!state.claimData.dateNextTravel })}>
                <Calendar
                    value={state.claimData.dateNextTravel}
                    onChange={onChangeCalendar}
                    locale={language || 'en'}
                    minDate={new Date()}
                    maxDate={getDateYearsFromNow(5)}
                />
            </div>

            {showModalPhoneDirect &&
                <PhoneDirect
                    actionChange={(value) => {
                        updateClaim('contact', {
                            ...state.claimData.contact,
                            prefixPhone: value
                        });
                        setShowModalPhoneDirect(false);
                    }}
                    actionClose={() => setShowModalPhoneDirect(false)}
                    value={state.claimData.contact.prefixPhone}
                    t={t}
                />
            }

        </div>
    )
}

export default React.memo(Contact);