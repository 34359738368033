import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

import type { StateT as ClaimListStateT } from 'Store/modules/claim';
import { ClaimT } from "api/modules/claim";
import { AppStateT } from "Store/modules";

const useClaimBlackCase= (claimId: string) => {
    const claimList = useSelector<AppStateT, ClaimListStateT>(({ claim }) => claim);
    const [claim, setClaim] = useState<ClaimT|null>(null);
    const [checkedEditClaim, setCheckedEditClaim] = useState(false);

    useEffect(() => {
        const _claim = claimList?.data?.find(c => c.id === claimId);
        if (_claim){
            setClaim(_claim);
        } else {
            setClaim(null);
        }
        setCheckedEditClaim(true)

    }, [claimList, claimId]);

    return { claim, checkedEditClaim };
}
export default useClaimBlackCase;