import { UserInfoT, UserT } from 'api/modules/user';
export const SET_USER = 'SET_USER';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_NAME = 'SET_USER_NAME';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_APP_INITIALIZED = 'SET_APP_INITIALIZED';
export const SET_FIRST_TIME_LOGIN = 'SET_FIRST_TIME_LOGIN';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const RESET_USER = 'RESET_USER';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_RESET = 'UPDATE_USER_RESET';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const SET_USER_COORDS = 'SET_USER_COORDS';

function capitalizeName(name: string) {
  return name
    ?.trim()
    .toLowerCase()
    .split(' ')
    .map((nameChunk) => {
      const firstLetter = nameChunk.slice(0, 1);
      const rest = nameChunk.slice(1);

      return `${firstLetter.toUpperCase()}${rest}`;
    })
    .join(' ') || '';
}

export type SetUserT = {
  type: typeof SET_USER;
  data: UserT;
  token: string;
};

export type SetUserDataT = {
  type: typeof SET_USER_DATA;
  data: UserT;
};

export type SetUserEmailT = {
  type: typeof SET_USER_EMAIL;
  data: string;
};

export type SetUserTokenT = {
  type: typeof SET_USER_TOKEN;
  data: string;
}

export type SetUserLanguageT = {
  type: typeof SET_USER_LANGUAGE;
  language: string;
};

export type SetUserNameT = {
  type: typeof SET_USER_NAME;
  firstName: string;
  lastName: string;
  title: string;
};

export type SetAppInitializedT = {
  type: typeof SET_APP_INITIALIZED;
};

export type SetFirstTimeLoginT = {
  type: typeof SET_FIRST_TIME_LOGIN;
  firstTimeLogin: boolean;
};

export type ResetUserT = {
  type: typeof RESET_USER;
};

export type UpdateUserRequestT = {
  type: typeof UPDATE_USER_REQUEST;
  data: UserInfoT;
};

export type UpdateUserSuccessT = {
  type: typeof UPDATE_USER_SUCCESS;
  data: UserT;
};

export type UpdateUserFailureT = {
  type: typeof UPDATE_USER_FAILURE;
  error: string;
};

export type UpdateUserResetT = {
  type: typeof UPDATE_USER_RESET;
};

export type SetUserCoordsT = {
  type: typeof SET_USER_COORDS;
  coords: ICoords
};

export type ActionTypeT =
  | SetUserT
  | SetUserDataT
  | SetUserEmailT
  | SetUserTokenT
  | SetAppInitializedT
  | SetFirstTimeLoginT
  | ResetUserT
  | UpdateUserRequestT
  | UpdateUserSuccessT
  | UpdateUserFailureT
  | UpdateUserResetT
  | SetUserNameT
  | SetUserLanguageT
  | SetUserCoordsT;

export type StateT = {
  initialized: boolean;
  loggedIn: boolean;
  email: string | null;
  lastName: string | null;
  firstName: string | null;
  title: string | null;
  data: UserT | null;
  firstTimeLogin: boolean;
  accountInfo: {
    loading: boolean;
    loaded: boolean;
    error: null | string;
  };
  loaction: LocationT,
  token: string
};

const initialState: StateT = {
  initialized: false,
  loggedIn: false,
  email: null,
  firstName: null,
  lastName: null,
  title: null,
  data: null,
  firstTimeLogin: false,
  accountInfo: {
    loading: false,
    loaded: false,
    error: null,
  },
  loaction: {
    address: '',
    city: '',
    coords: { lat: 0, lng: 0 },
    country: '',
    countryCode: '',
    formattedAddress: '',
    zipCode: ''
  },
  token: ''
};

export default function authorizationReducer(
  state: StateT = initialState,
  action: ActionTypeT,
): StateT {
  switch (action.type) {
    case SET_APP_INITIALIZED: {
      return {
        ...state,
        initialized: true,
      };
    }
    case SET_USER: {
      return {
        ...state,
        loggedIn: true,
        data: {
          ...action.data,
          firstName: capitalizeName(action.data.firstName),
          lastName: capitalizeName(action.data.lastName),
          title: action.data.title,
        },
      };
    }
    case SET_USER_DATA: {
      return {
        ...state,
        data: {
          ...action.data,
        },
      };
    }
    case SET_USER_EMAIL: {
      return {
        ...state,
        email: action.data,
      };
    }
    case SET_USER_TOKEN: {
      return {
        ...state,
        token: action.data
      }
    }
    case SET_USER_COORDS: {
      return {
        ...state,
        data: {
          ...state.data,
          location: {
            ...state.data.location,
            coords: action.coords
          }
        }
      };
    }
    case SET_USER_NAME: {
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        title: action.title,
      };
    }
    case SET_FIRST_TIME_LOGIN: {
      return {
        ...state,
        firstTimeLogin: action.firstTimeLogin,
      };
    }
    case RESET_USER: {
      return {
        ...initialState,
        initialized: true,
      };
    }
    case UPDATE_USER_REQUEST: {
      return {
        ...state,
        accountInfo: {
          ...initialState.accountInfo,
          loading: true,
        },
      };
    }
    case UPDATE_USER_SUCCESS: {

      return {
        ...state,
        data: {
          ...action.data,
          firstName: capitalizeName(action.data.firstName),
          lastName: capitalizeName(action.data.lastName),
          title: capitalizeName(action.data.title),
        },
        accountInfo: {
          ...initialState.accountInfo,
          loaded: true,
        },
      };
    }
    case SET_USER_LANGUAGE: {

      return {
        ...state,
        data: {
          ...state.data,
          language: action.language,
        } as any
      };
    }
    case UPDATE_USER_FAILURE: {
      return {
        ...state,
        accountInfo: {
          ...initialState.accountInfo,
          error: action.error,
        },
      };
    }
    case UPDATE_USER_RESET: {
      return {
        ...state,
        accountInfo: {
          ...initialState.accountInfo,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export function setUser(data: UserT, token: string): SetUserT {
  return {
    type: SET_USER,
    data,
    token,
  };
}

export function setUserData(data: UserT): SetUserDataT {
  return {
    type: SET_USER_DATA,
    data,
  };
}

export function setUserEmail(email: string): SetUserEmailT {
  return {
    type: SET_USER_EMAIL,
    data: email?.replace(' ', '').toLowerCase(),
  };
}

export function setUserToken(token: string): SetUserTokenT {
  return {
    type: SET_USER_TOKEN,
    data: token
  };
}

export function setAppInitialized(): SetAppInitializedT {
  return {
    type: SET_APP_INITIALIZED,
  };
}

export function setFirstTimeLogin(firstTimeLogin: boolean = true): SetFirstTimeLoginT {
  return {
    type: SET_FIRST_TIME_LOGIN,
    firstTimeLogin,
  };
}

export function resetUser() {
  return {
    type: RESET_USER,
  };
}

export function updateUserRequest(personalInfo: UserInfoT): UpdateUserRequestT {
  return {
    type: UPDATE_USER_REQUEST,
    data: personalInfo,
  };
}

export function updateUserSuccess(personalInfo: UserT): UpdateUserSuccessT {
  return {
    type: UPDATE_USER_SUCCESS,
    data: personalInfo,
  };
}

export function updateUserFailure(error: string): UpdateUserFailureT {
  return {
    type: UPDATE_USER_FAILURE,
    error,
  };
}

export function updateUserReset(): UpdateUserResetT {
  return {
    type: UPDATE_USER_RESET,
  };
}

export function setUserName(firstName: string, lastName: string, title: string): SetUserNameT {
  return {
    type: SET_USER_NAME,
    firstName,
    lastName,
    title,
  };
}

export function setUserLanguage(language: string): SetUserLanguageT {
  return {
    type: SET_USER_LANGUAGE,
    language,
  };
}

export function setUserCoords(coords: ICoords): SetUserCoordsT {
  return {
    type: SET_USER_COORDS,
    coords
  };
}
