import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import CustomModal from 'Components/CustomModal/CustomModal';
import styles from './ModalEmailQuestion.module.scss';
import { Button } from 'Components';

type MainPropsT = {
    email: string,
    handlePositive: () => void,
    handleNegative: () => void
}

const ModalEmailQuestion: React.FC<MainPropsT> = ({ email, handlePositive, handleNegative }) => {
    const { t } = useTranslation();

    return (
        <CustomModal open>
            <div className={styles.centerModalText}> {parse(t('createClaim.secondaryEmailQuestion', { email: `<strong className=${styles.strongEmail}>${email}</strong>` }))} </div>
            <div className={styles.modalButtons}>
                <Button onClick={handleNegative} type={'button'} color={'secondary'}>{t('common.no')}</Button>
                <Button onClick={handlePositive} type={'button'} color={'primary'}>{t('common.yes')}</Button>
            </div>
        </CustomModal>
    );
};

export default ModalEmailQuestion;