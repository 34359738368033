import React, { useMemo } from 'react';

import ChatGoogleReview from "../ChatGoogleReview/ChatGoogleReview";
import { FeedMessageLine } from "../../DataStructure/Components";
import { CaseT, ClaimT } from "../../DataStructure/interfaces";

type MainPropsT = {
    t: (data: string, params?: any) => string,
    review?: CaseT['review'],
    claim: ClaimT
}

const Closed: React.FC<MainPropsT> = ({ t, review, claim }) => {

    const isShowReview = useMemo(() => !!review?.googleMapsUrl || !!review?.trustPilotUrl, [review?.googleMapsUrl, review?.trustPilotUrl]);
    // const dataShow = useMemo(() => getFeedTypeData(CLAIM_STATUSES.CLOSED, t, { urlTrustPilot: review?.trustPilotUrl, urlGoogleReview: review?.googleMapsUrl }, null, !isShowReview), [t, review, isShowReview]);

    return (
        <>
            <FeedMessageLine
                data={t('caseStatusesBasic.CLOSED_MAIN')}
                spaceBottom
                marked
            />
            {isShowReview &&
                <ChatGoogleReview
                    t={t}
                    review={{
                        googleMapsUrl: review.googleMapsUrl,
                        trustPilotUrl: review.trustPilotUrl
                    }}
                    hideHeaderText
                    marked
                />
            }
        </>
    );
}
export default Closed;