import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RatioBox } from 'Components';
import Lottie from 'react-lottie';
import heic2any from "heic2any";
import cx from 'classnames';

// import { fileToBlob } from 'Helpers/fileToBlob';
import { ReactComponent as UploadIcoDoc } from 'Assets/upload-doc.svg';
import { ReactComponent as UploadIcoPdf } from 'Assets/upload-pdf.svg';
import { ReactComponent as LuggageIcon } from 'Assets/luggage.svg';
import { ReactComponent as CameraIcon } from 'Assets/camera.svg';
import { ReactComponent as DeleteIcon } from 'Assets/delete.svg';
import { ReactComponent as PlusIcon } from 'Assets/plus.svg';
import animationData from 'Assets/lotties/loading.json';
import NoImageSvgPath from 'Assets/no-image.svg';

import { base64ToFile, changeFileNewName, convertToJpgAndResize, filesApproved, fileToBase64, getFileData, randomNumber, safeFileName } from 'Helpers/files';
import styles from './ReportUploadBox.module.scss';
import { runNotify } from 'Helpers/notifications';
import { isSafari } from 'Helpers/browser';
import Ico from 'Components/Ico/Ico';
import CONFIG from 'Config';

export interface IImage {
    file: File;
    name: string;
    url: string;
    base64?: string,
    originalDraftKey?: string
}

export interface IReportUploadBox {
    removable?: boolean;
    onChange?: (image: IImage | null) => void;
    onRemove?: () => void;
    name: string;
    hideName?: boolean;
    value?: IImage;
    noInternalState?: boolean;
    acceptableFileFormats?: string,
    buttonShowAction?: { action: () => void, label: string }|null,
    fitWidth?: boolean,
    width?: number,
    addMode?: boolean,
    excludeImages?: IImage[],
    onlyImages?: boolean,
    disabled?: boolean,
    id?: string,
    className?: string
}

let timer: any = null;

export default function ReportUploadBox(props: IReportUploadBox){
    const { onChange, onRemove, removable = true, value, noInternalState, acceptableFileFormats, buttonShowAction, fitWidth = false, width = null, addMode = false, excludeImages, onlyImages, disabled = false, id, className } = props;
    const [file, setFile] = useState<IImage | null>(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        return clearTimeout(timer);
    }, []);

    const onFileUploaded = useCallback(async (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;
        setLoading(true);
        if (!evt.target.files || !evt.target.files?.length) {
            setLoading(false);
            return;
        }

        // const compareFile = (fileName: string, size: number): boolean => {
        //     const isIphone = iPhone();
        //     return isIphone ? excludeImages.some(photo => photo.base64.size === size) : excludeImages.some(photo => photo.file.name === fileName);
        // }
    
        // let fileTarget = changeFileNewName(evt.target.files[0], safeFileName(evt.target.files[0].name));
        let fileTarget = changeFileNewName(evt.target.files[0], safeFileName(evt.target.files[0].name));

        const fileData = evt.target.files && evt.target.files.length > 0 ? getFileData(fileTarget.name) : null;

        if (Math.ceil(fileTarget.size / 1024 / 1024) > CONFIG.MAX_IMAGE_UPLOUD_SIZE_MB){
            runNotify({ message: t('common.fileTooBig'), type: 'error' });
            setError(true);
            setLoading(false);
            return;
        }

        if ((!(fileData && filesApproved.includes(fileData.ext.toLowerCase())) && (fileTarget.type.indexOf('image') < 0 && fileTarget.type.indexOf('pdf') < 0))){
            runNotify({ message: fileData?.type === 'image' ? t('createClaim.errorUploadInvalidPhoto') : t('createClaim.errorUploadInvalidFile'), type: 'error' });
            setError(true);
            setLoading(false);
            return;
        }

        if (fileData.ext === 'heic'){
            const heicDataFile = getFileData(fileTarget.name);
            try{
                const convertedJpg = await heic2any({
                    blob: fileTarget,
                    toType: "image/jpeg",
                    quality: 0.9
                });
                fileTarget = new File([convertedJpg as any], safeFileName(heicDataFile.name) + '.jpg' ,{type: "image/jpg", lastModified: new Date().getTime()})
            } catch(error){
                fileTarget = null;
            }
        }

        if (!fileTarget){
            runNotify({ message: t('createClaim.errorUploadInvalidPhoto'), type: 'error' });
            setError(true);
            setLoading(false);
            return;
        }

        if (fileData.mimeType.indexOf('image') <0 && !filesApproved.includes(fileData.ext.toLowerCase())){
            if (onlyImages){
                runNotify({ message: t('createClaim.errorUploadInvalidFile'), type: 'error' });
                setError(true);
                setLoading(false);
                return;
            }
            const newName = `${randomNumber(1000,9999)}-${fileTarget.name}`;
            const newImage = {
                file: fileTarget,
                name: newName,
                url: isSafari() ? window.webkitURL.createObjectURL(fileTarget) : window.URL.createObjectURL(fileTarget),
                base64: await fileToBase64(fileTarget)
            };
            if (!noInternalState) {
                setFile(newImage);
            }
    
            if (onChange) {
                onChange(newImage);
            }
            setLoading(false);
            return;
        }

        let newImageTarget = null;
        const dataConvert: any = await convertToJpgAndResize(fileTarget);
        if (dataConvert){
            const dataFile = getFileData(fileTarget.name);
            const newName = `${randomNumber(1000,9999)}-${dataFile.name}.jpg`;

            newImageTarget = {
                file: base64ToFile(dataConvert.base64, newName),
                name: newName,
                url: isSafari() ? window.webkitURL.createObjectURL(dataConvert.blob) : window.URL.createObjectURL(dataConvert.blob),
                base64: dataConvert.base64
            };

            if (excludeImages && Array.isArray(excludeImages) && excludeImages.some(im => im.file.size === newImageTarget.file.size)){
                runNotify({ message: fileData?.type === 'image' ? t('createClaim.errorUploadAlreadyPhoto') : t('createClaim.errorUploadAlreadyFile'), type: 'error' });
                setError(true);
                setLoading(false);
                return;
            }

            if (!noInternalState) {
                setFile(newImageTarget);
            }

            if (onChange) {
                onChange(newImageTarget);
            }
            setLoading(false);
        } else {
            const newName = `${randomNumber(1000,9999)}-${fileTarget.name}`;

            const newImage = {
                file: fileTarget,
                name: newName,
                url: isSafari() ? window.webkitURL.createObjectURL(fileTarget) : window.URL.createObjectURL(fileTarget),
                base64: await fileToBase64(fileTarget)
            };
            if (!noInternalState) {
                setFile(newImage);
            }
        
            if (onChange) {
                onChange(newImage);
            }
            setLoading(false);
        }

        // const dataOcr: IReadDocument|null = await getImageOcrData({ files: [{ filename: fileTarget.name }], images: newImageTarget ? [newImageTarget.file] : [fileTarget], missingDocumentName: props?.documentName || 'DPR Copy' });
        // if (dataOcr){
        //     console.log('dataOcr: ', dataOcr);
        // }

    }, [noInternalState, onChange, onlyImages, t, excludeImages, disabled]);

    useEffect(() => {
        setFile(value || null);
    }, [value]);

    useEffect(() => {
        if (error){
            timer = setTimeout(() => {
                setError(false);
            }, 2000);
        }
    }, [error]);

    

    const onRemoveHandler = useCallback((evt: React.MouseEvent<any>) => {
        evt.preventDefault();
        evt.stopPropagation();

        if (removable && onRemove) {
            return onRemove();
        }

        if (!noInternalState) {
            setFile(null);
        }

        if (onChange) {
            onChange(null);
        }
    }, [removable, onRemove, onChange, noInternalState]);

  const fileData = file ? getFileData(file.file.name) : { ext: null };

  return (
    <div className={cx(styles.uploadBoxContainer, buttonShowAction && styles.uploadBoxContainerWithButton, fitWidth && styles.uploadBoxContainerFit)} style={width ? { width } : undefined}>
        <RatioBox width={130} height={130} className={cx(styles.uploadBox, className, { [styles.error]: error, [styles.loading]: loading, [styles.disabled]: !!disabled })}>
            {loading && <div className={styles.loader}><Lottie options={{ animationData }} height={150} width={150} /></div>}
            <label>
                <input
                    type="file"
                    accept={props.name.indexOf('missingDocument') >=0 ? (filesApproved.length > 0 ? filesApproved.map(str => '.'+str+",") : '')+".pdf,image/*" : (filesApproved.length > 0 ? filesApproved.map(str => '.'+str+",") : '')+"image/*"}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        onFileUploaded(evt);
                    }}
                    disabled={disabled}
                    id={id}
                />

                {!addMode &&
                    <>
                        {(file && (fileData.ext !== 'doc' && fileData.ext !== 'pdf') && !(fileData && filesApproved.includes(fileData.ext as string))) ? <img className={styles.preview} src={file.url || NoImageSvgPath} alt="" />:
                            <>
                            {fileData.ext &&
                                <div className={styles.icoUpload}>
                                    {filesApproved.includes(fileData.ext) ?
                                        <LuggageIcon className={styles.luggageIcon} />:
                                        <>
                                        {fileData.ext === 'pdf' ?
                                            <UploadIcoPdf className={styles.cameraIcon} />:
                                            <UploadIcoDoc className={styles.cameraIcon} />
                                        }
                                        </>
                                    }
                                </div>
                            }
                            </>
                        }
                        {!file && (
                            <>
                                {acceptableFileFormats ? (
                                    <div className={styles.cameraContainer}>
                                        <CameraIcon className={styles.cameraIconWithText} />
                                        <p className={styles.fileFormat}>{acceptableFileFormats}</p>
                                    </div>
                                ) : (
                                    <CameraIcon className={styles.cameraIcon} />
                                )}
                            </>
                        )}
                        {file && <DeleteIcon className={styles.trashIcon} onClick={onRemoveHandler} />}
                    </>
                }

                {addMode && <PlusIcon className={styles.plusIcon} />}

            </label>
        </RatioBox>

        {!props.hideName &&
            <div className={styles.uploadName}>
                <span className={styles.uploadNameText}>{t(props.name)}</span>
                {buttonShowAction &&
                    <>
                    <div className={styles.moreActionButton} onClick={buttonShowAction.action}>
                        <Ico action={buttonShowAction.action} />
                    </div>
                    </>
                }
            </div>
        }
    </div>
  );
}