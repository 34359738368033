import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router";
import { useDispatch } from 'react-redux';

import { setUserEmail, setUserName as setUserNameAction } from 'Store/modules/user';
import { resetLoginState } from 'Store/modules/authorization';
import { LoginDataT, loginUser } from "services/api";
import { Layout, LoginForm } from 'Components';
import type { LoginT } from 'api/modules/user';
import { validField } from "Helpers/validForm";

export default function Login() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const params  = useParams();
    const [email] = useState(params?.email && validField('email', params?.email) ? params.email : '');

    const onSubmit = useCallback((data: LoginT) => {
        setLoading(true);
        loginUser(data.email, (result: boolean, dataResult: LoginDataT) => {
            setLoading(false);
            if (result){
                dispatch(setUserEmail(data.email));
                navigate('/confirm');
            } else {
                navigate('/register');
            }
        });
    },[dispatch, navigate]);

    const setUserName = useCallback((firstName: string, lastName: string, title: string) => {
        dispatch(setUserNameAction(firstName, lastName, title));
    }, [dispatch]);

    useEffect(() => {
            return () => {
                dispatch(resetLoginState());
            };
    }, [dispatch]);

    return (
        <Layout>
            <LoginForm
                onSubmit={onSubmit}
                submitted={loading}
                setUserName={setUserName}
                email={email}
            />
        </Layout>
    );
}
