import { useTranslation } from 'react-i18next';
import styles from './InfoWarningClose.module.scss';
import CustomModal from 'Components/CustomModal/CustomModal';
import { Button } from 'Components';

const MODAL_TYPE = 'modal';
const COMPACT_TYPE = 'compact';
const DEFAULT_MODAL_TYPE = MODAL_TYPE;

interface ISaveClaimAsDraftModal {
	type?: 'modal' | 'compact';
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
	onDeny: () => void;
	withReopenChat?: boolean;
}

const STRATEGY = {
	[MODAL_TYPE]: SaveAsDraftModal,
	[COMPACT_TYPE]: SaveAsDraftModal,
};

function SaveAsDraftModal(props: Omit<ISaveClaimAsDraftModal, 'type'>) {
  const { t } = useTranslation();
  return (
    <CustomModal 
      open={props.open}         
      title={   
        <>
          {t('createClaim.closeHeader')}<br />
        </>
      }   
      onClose={props.onClose}
    >
				<div className={styles.saveModalContainer}>
					<div className={styles.bodyText}>{t('createClaim.closeContent')}</div>
					<div className={styles.buttonsGroup}>
						<Button onClick={props.onClose} type={'button'} color={'secondary'}>{t('common.cancel')}</Button>
						<Button onClick={props.onDeny} type={'button'} color={'primary'}>{t('common.confirm')}</Button>
					</div>
				</div>
    </CustomModal>
  );
}

export default function InfoWarningClose({
  	type = DEFAULT_MODAL_TYPE,
  ...props
}: ISaveClaimAsDraftModal) {
  const Component = STRATEGY[type] as React.FC<Omit<ISaveClaimAsDraftModal, 'type'>>;

  return <Component {...props} />;
}
