import { useEffect, useState, useCallback } from "react";

import { formatDateSaleforce } from "Helpers/date";
import { IClaimDraft } from "Store/Types/claim";
import { ClaimCreateT } from "./CreateForm";

const useCheckChanges = (draft: IClaimDraft|null, claimData: ClaimCreateT, sendSocket: (event: string, data: any) => void, blockFlow?: boolean) => {
    const [previousData, setPreviousData] = useState<ClaimCreateT>(claimData);

    const checkChanges = useCallback(() => {
        const dataSaveToChange: IClaimDraft = draft || {};

        if (!draft || blockFlow) return;
        if (draft.updatedAt) delete draft.updatedAt;
        if (draft.createdAt) delete draft.createdAt;

        if (claimData.referenceNumber !== previousData.referenceNumber) dataSaveToChange.referenceNumber = claimData.referenceNumber;
        if (claimData.airlineName !== previousData.airlineName) dataSaveToChange.airlineName = claimData.airlineName;
        if (claimData.airlineId !== previousData.airlineId) dataSaveToChange.airlineId = claimData.airlineId;
        if (claimData.airlineDateLanding !== previousData.airlineDateLanding) dataSaveToChange.airlineDateLanding = formatDateSaleforce(new Date(claimData.airlineDateLanding));
        if (claimData.luggageType !== previousData.luggageType) dataSaveToChange.case = { ...dataSaveToChange.case, luggageType: claimData.luggageType };
        if (claimData.details.brand !== previousData.details.brand) dataSaveToChange.luggage = { ...dataSaveToChange.luggage, brand: claimData.details.brand };
        if (claimData.luggageColor !== previousData.luggageColor) dataSaveToChange.luggage = { ...dataSaveToChange.luggage, color: claimData.luggageColor };
        if (claimData.details.size !== previousData.details.size) dataSaveToChange.luggage = { ...dataSaveToChange.luggage, size: claimData.details.size };
        if (claimData.details.capacity !== previousData.details.capacity) dataSaveToChange.luggage = { ...dataSaveToChange.luggage, capacity: claimData.details.capacity };
        if (claimData.details.cost !== previousData.details.cost) dataSaveToChange.luggage = { ...dataSaveToChange.luggage, cost: claimData.details.cost ? +(claimData.details.cost) : null };
        if (claimData.details.length !== previousData.details.length) dataSaveToChange.luggage = { ...dataSaveToChange.luggage, length: claimData.details.length };
        if (claimData.luggageMaterial !== previousData.luggageMaterial) dataSaveToChange.luggage = { ...dataSaveToChange.luggage, material: claimData.luggageMaterial };
        if (claimData.details?.model !== previousData.details?.model) dataSaveToChange.luggage.model = claimData.details?.model;
        if (claimData.details.serialNumber !== previousData.details.serialNumber) dataSaveToChange.luggage = { ...dataSaveToChange.luggage, serialNumber: claimData.details.serialNumber };
        if (claimData.tagNumber !== previousData.tagNumber) dataSaveToChange.luggage = { ...dataSaveToChange.luggage, tagNumber: claimData.tagNumber };
        if (claimData.details.width !== previousData.details.width) dataSaveToChange.luggage = { ...dataSaveToChange.luggage, width: claimData.details.width };
        if (Array.isArray(claimData.damage)  && JSON.stringify(previousData.damage) !== JSON.stringify(claimData.damage)) dataSaveToChange.luggage = { ...dataSaveToChange.luggage, damages: claimData.damage };
        if (claimData.details.currency !== previousData.details.currency) dataSaveToChange.luggage = { ...dataSaveToChange.luggage, currencyIsoCode: claimData.details.currency };
        if (claimData.details.date !== previousData.details.date) dataSaveToChange.luggage = { ...dataSaveToChange.luggage, date: (new Date(claimData.details.date)).toISOString() };
        if (claimData.dateNextTravel !== previousData.dateNextTravel) dataSaveToChange.dateNextTravel = claimData.dateNextTravel ? (new Date(claimData.dateNextTravel)).toISOString() : null;
        if (claimData.landingStationSelected !== previousData.landingStationSelected) dataSaveToChange.landingStation = claimData.landingStationSelected;
        if (claimData.contact.address !== previousData.contact.address) dataSaveToChange.location = { ...dataSaveToChange.location, address: claimData.contact.address };
        if (claimData.contact.city !== previousData.contact.city) dataSaveToChange.location = { ...dataSaveToChange.location, city: claimData.contact.city };
        if (claimData.contact.country !== previousData.contact.country){
            dataSaveToChange.location = {...dataSaveToChange.location, country: claimData.contact.country.name};
            dataSaveToChange.location = {...dataSaveToChange.location, countryCode: claimData.contact.country.code};
        }
        if (claimData.contact.zipCode !== previousData.contact.zipCode) dataSaveToChange.location = { ...dataSaveToChange.location, zipCode: claimData.contact.zipCode };
        if (claimData.contact?.coords !== previousData.contact?.coords){
            dataSaveToChange.location = {
                ...dataSaveToChange.location,
                coords: {
                    lat: claimData.contact?.coords?.lat || 0,
                    lng: claimData.contact?.coords?.lon || 0,
                }
            };
        }

        if (dataSaveToChange && Object.entries(dataSaveToChange).length > 0){
            sendSocket('onClaimDraftUpdate', dataSaveToChange)
        }

        setPreviousData(claimData);
    }, [claimData, previousData, sendSocket, draft, blockFlow]);

    useEffect(() => {
        const timer = setTimeout(checkChanges, 1000);
        return () => clearTimeout(timer);
    }, [claimData, checkChanges]);
}

export default useCheckChanges;