import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import cx from 'classnames';

import { ReactComponent as MailIcon } from 'Assets/mail.svg';

import { useDeviceType } from 'Helpers/responsiveContainers';
import TitleBlock from 'Components/TitleBlock/TitleBlock';
import { setUserEmail } from 'Store/modules/user';
import type { LoginT } from 'api/modules/user';
import { validField } from 'Helpers/validForm';
import Loader from 'Components/Loader/Loader';
import useTranslate from "Hooks/useTranslate";
import Button from 'Components/Button/Button';
import styles from './LoginForm.module.scss';
import Input from 'Components/Input/Input';
import { AppStateT } from 'Store/modules';

type MainPropsT = {
    onSubmit: (data: LoginT) => any;
    submitted: boolean;
    setUserName: (firstName: string, lastName: string, title: string) => void;
    email?: string
}

const LoginForm: React.FC<MainPropsT> = ({ onSubmit, submitted, setUserName, email: emailProps }) => {
    const t = useTranslate();
    const navigate = useNavigate();
    const [valid, setValid] = useState(false);
    const dispatch = useDispatch();
    const { isMobile } = useDeviceType();
    const email = useSelector<AppStateT, string | null>((state) => state.user.email?.replace(' ', '').toLowerCase()) || '';

    useEffect(() => {
        if (emailProps && validField('email', emailProps)){
            dispatch(setUserEmail(emailProps))
        }
    }, [emailProps, dispatch]);

    useEffect(() => {
        setValid(validField('email', email));
    }, [email]);

    const actionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setUserEmail(event.target.value.replace(' ', '').toLowerCase()))
    }, [dispatch]);

    const actionSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (valid) {
            onSubmit({ email: email?.replace(' ', '').toLowerCase() });
        }
    }, [email, onSubmit, valid]);

    return (
        <div className={cx(styles.root, { [styles.isNotMobile]: !isMobile })}>
            <form className={styles.main} onSubmit={actionSubmit}>
                {submitted &&
                    <div className={styles.loader}>
                        <Loader />
                    </div>
                }

                <TitleBlock title={t('login.title')} subtitle={t('login.subtitle')} withIcon />

                <Input
                    label={t('labels.email')}
                    Icon={MailIcon}
                    value={email?.replace(' ', '').toLowerCase()}
                    onChange={actionChange}
                    name='email'
                    id={'input_login_email'}
                    inputMode='email'
                />

                <div className={styles.buttons}>
                    <Button type={'submit'} className={styles.button} disabled={!valid} id={'button_login_submit'}>
                        {t('common.continue')}
                    </Button>
                </div>

                <div className={styles.noRegister} id={'button_no_register'} onClick={() => {
                    dispatch(setUserEmail(email));
                    navigate('/register');
                }}>
                    {t('login.register')}
                </div>
            </form>
        </div>
    );
}

export default LoginForm;
