import { useNavigate } from "react-router";
import React, { useCallback } from 'react';
import { useDispatch } from "react-redux";
import cx from 'classnames';

import { ReactComponent as ImageLuggage } from 'Assets/add_claim_baggage_details.svg';
import FeedMessageLine from "Components/FeedMessageLine/FeedMessageLine";
import { ClaimT } from "PageComponents/Feed/DataStructure/interfaces";
import { useDeviceType } from "Helpers/responsiveContainers";
import { setBlackCaseId } from "Store/modules/page";
import useTranslate from "Hooks/useTranslate";
import style from './BlackCase.module.scss';
import { Button } from "Components";

type MainPropsT = {
    claim: ClaimT,
    openCreateClaimDesktop: () => void
}

const BlackCase: React.FC<MainPropsT> = ({ claim, openCreateClaimDesktop }) => {
    const { isMobile } = useDeviceType();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const t = useTranslate();

    const actionPressButton = useCallback(() => {
        dispatch(setBlackCaseId(claim.id));
        if (isMobile){
            return navigate('/create');
        }
        openCreateClaimDesktop();
    }, [isMobile, navigate, openCreateClaimDesktop, claim.id, dispatch]);

    return (
        <div className={cx(style.root, { [style.isDesktop]: !isMobile })}>
            <div className={style.imageTopLayer}>
                <ImageLuggage className={style.imageLuggage} />
            </div>
            <FeedMessageLine
                data={[
                    t('BlackCase.feedLine1'),
                    t('BlackCase.feedLine2')
                ]}
                dataOverText={{ text: t('BlackCase.foodOverText'), placeInLastCloud: false }}
            />
            <FeedMessageLine
                data={<div className={style.formLayer}><Button onClick={actionPressButton}>{t('BlackCase.feedButton')}</Button></div>}
                center
                showAvatar={false}
                hideAvatar
                transparent
            />
        </div>
    );
}
export default BlackCase;