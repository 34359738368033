import React, { useLayoutEffect } from 'react';
import { useNavigate } from "react-router";
import parse from 'html-react-parser';

import useFindReferenceNumber from "Hooks/useFindReferenceNumber";
import style from './ModalDuplicatesRN.module.scss';
import useTranslate from "Hooks/useTranslate";
import { StateLocalT } from "../CreateForm";
import { ClaimT } from "api/modules/claim";
import { Button} from "Components";
import CustomModal from 'Components/CustomModal/CustomModal';
import { useMobile } from 'Helpers/responsiveContainers';

type MainPropsT = {
    actionClose: () => void,
    actionContinue: () => void,
    show?: boolean,
    casesInSalesforce: StateLocalT['casesInSalesforce'],
    referenceNumber: string,
    actionCloseModalMain?: () => void
}

const ModalDuplicatesRN: React.FC<MainPropsT> = ({ actionClose, show = true, casesInSalesforce, referenceNumber, actionContinue, actionCloseModalMain }) => {

    const t = useTranslate();
    const claimByReferenceNumber: ClaimT|null = useFindReferenceNumber(casesInSalesforce.map(rn => rn.ReferenceNo1__c));
    const navigate = useNavigate();
    const isMobile = useMobile();

    useLayoutEffect(() => {
        if (claimByReferenceNumber?.id){
            const handleClick = () => {
            actionClose();
                setTimeout(() => {
                    actionCloseModalMain();
                    navigate("/" + claimByReferenceNumber.id);
                }, 100);
            };
    
            setTimeout(() => {
                const element = document.querySelector("#actionOpenClaim");
                if (element){
                    element.addEventListener('click', handleClick);
                }
            }, 200);
            return () => {
                const element = document.querySelector("#actionOpenClaim");
                if (element) {
                    element.removeEventListener('click', handleClick);
                }
            };
        }
    }, [actionClose, actionCloseModalMain, claimByReferenceNumber?.id, navigate]);

    return (
        <CustomModal open={show} title={t('createClaim.duplicateModalTitle')} onClose={actionClose}>
            <div className={style.root}>
                {!!claimByReferenceNumber ? parse(t('createClaim.duplicateModalContent', { referenceNumber: `<strong>${referenceNumber}</strong> - <span id="actionOpenClaim">${t('createClaim.openClaimButton')}</span>` })) : parse(t('createClaim.duplicateModalContentNotfoundOnList', { referenceNumber: `<strong>${referenceNumber}</strong>` }))}
            </div>
            <div className={isMobile ? style.buttonsMobile : style.buttons}>
                <Button onClick={actionCloseModalMain} type={'button'} color={'secondary'} block id="button_duplicate_ref_number_close">{t('createClaim.duplicateModalCloseButton')}</Button>
                <Button onClick={actionContinue} type={'button'} color={'primary'} block id="button_duplicate_ref_number_submit">{t('common.continue')}</Button>
            </div>
        </CustomModal>
    );
}
export default ModalDuplicatesRN;