import React, { useMemo } from "react";

import { FeedMessageLine } from "../../../DataStructure/Components";
import { getTranslationOrFallback } from "Helpers/strings";

type MainPropsT = {
    country: string,
    countryCode: string,
    t: (data: string, params?: any) => string
}

const PickupProcessByPartner: React.FC<MainPropsT> = ({ country, countryCode, t }) => {

    const countryString = (country[0].toLowerCase() + country.slice(1)).replace(/ /g, '');

    const dataEntry = useMemo(() => {
        const translatedCountry = getTranslationOrFallback(t, `common.${countryString}`, country);
        return {
            country: `<strong>${translatedCountry} (${countryCode})</strong>`
        }
    }, [country, countryCode, countryString, t]);

    return (
        <FeedMessageLine
            data={(t('chatMessages.pickupProcessByPartner', dataEntry))}
        />
    );
}
export default PickupProcessByPartner;