import { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { formatDateWithTime } from 'Helpers/date';
import { CaseListItemDesktop } from 'Components';

import type { StateT as UserStateT } from 'Store/modules/user';
import styles from './CaseListDesktop.module.scss';
import useKeyImages from "Hooks/useKeyImages";
import { photoMainKey } from "Helpers/claim";
import { ClaimT } from 'api/modules/claim';
import { AppStateT } from 'Store/modules';

type Props = {
    onClickCase: (item: ClaimT) => void;
    data: ClaimT[];
    activeCase?: ClaimT;
};

interface IUserSelector {
    user: UserStateT['data'];
}

function CaseListDesktop({ data, activeCase, onClickCase }: Props) {

    const user= useSelector<AppStateT, IUserSelector>(({ user }) => ({ user: user.data }));
    const [top, setTop] = useState(230);
    const photoKeys = useKeyImages(data);

    useLayoutEffect(() => {
        const element = document.getElementById("tabClaims");
        if (element){
            var rect = element.getBoundingClientRect();
            setTop(rect.top);
        }
    }, [data.length, user]);

    return (
        <>
            <div className={styles.list} id="listClaims" style={{ top: `${top-38}px`, height: `calc(100vh - ${top + 50}px)` }}>
                {data.map((item, index) => (
                    <CaseListItemDesktop
                        unreadMessages={item.unreadCount}
                        key={index}
                        img={photoKeys.find(pk => pk?.key === photoMainKey(item))?.url || null}
                        referenceNumber={item.referenceNumber}
                        creationDate={item?.isArchived && item?.claimCreatedAt ? formatDateWithTime(new Date(item.claimCreatedAt).getTime()) : formatDateWithTime(new Date(item.createdAt).getTime())}
                        id={item.caseNumber || item.id.slice(-6)}
                        location={item?.location?.country && item?.location?.city ? `${item?.location?.country}, ${item?.location?.city?.split(',')[0]}` : undefined}
                        active={activeCase?.id === item.id}
                        onClick={() => onClickCase(item)}
                        airlineName={item.airlineName}
                        blackCase={item.isBlackCase && (item.feed?.entries || []).length <= 1}
                    />
                ))}
            </div>
        </>
    );
}

export default CaseListDesktop;
