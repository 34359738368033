import React, { useCallback, useEffect, useState } from 'react';
import { ICountryCodeItem } from 'country-calling-code';
// @ts-ignore
import FLAG from 'country-flag-icons/react/3x2';

import styles from './PhoneNumber.module.scss';

type MainPropsT = {
    onChange: (data: string) => void,
    onChangePhoneDigits: (data: string) => void,
    countryCode: string,
    label: string,
    setFocus: (status: boolean) => void,
    countrys: ICountryCodeItem[],
    setShowCountry: (param: boolean) => void,
    onBlur?: () => void;
    onFocus?: () => void
}

const PhoneNumber: React.FC<MainPropsT> = ({ onChange, onChangePhoneDigits, countryCode, label, setFocus, countrys, setShowCountry, onBlur, onFocus }) => {

    const [phone, setPhone] = useState('');
    const [countrySelected, setCountrySelected] = useState<ICountryCodeItem|undefined>(countrys.find((country: ICountryCodeItem) => country.isoCode2 === countryCode));

    useEffect(() => {
        setCountrySelected(countrys.find((country: ICountryCodeItem) => country.isoCode2 === countryCode));
    }, [countryCode, setCountrySelected, countrys]);

    const insertCountryDirection = useCallback((value: string) => {

        const prefix = countrySelected?.countryCodes ? `+${countrySelected?.countryCodes[0]}` : '';
        return value.indexOf(prefix)!==0 ? prefix + value : value;

    }, [countrySelected]);

    const handleChangeData = (event: React.ChangeEvent<HTMLInputElement>) => {

        const maxLength = 12;
        const v = +event.target.value > maxLength ? String(event.target.value).substring(0,maxLength - 1) :  event.target.value;
        setPhone(v);
        onChangePhoneDigits(v);
        onChange(insertCountryDirection(v));

    }

    return (
        <div className={styles.main}>
            <label>{label}</label>
            <div className={styles.inputMain}>
                <div className={styles.flag}>
                    <SelectCountry 
                        country={countrySelected}
                        setShowCountry={setShowCountry}
                        id={'select_register_phone_prefix'}
                    />
                </div>
                <div className={styles.input}>
                    <input
                        onChange={handleChangeData}
                        onFocus={() => {
                            setFocus(true);
                            onFocus?.();
                        }}
                        onBlur={() => {
                            setFocus(false)
                            onBlur?.();
                        }}
                        value={phone}
                        type={'number'}
                        inputMode="numeric"
                        id={'input_register_phone'}
                    />
                </div>
            </div>
        </div>
    )

}

export default PhoneNumber;

type CountryPropsT = {
    country: ICountryCodeItem|undefined,
    setShowCountry: (param: boolean) => void,
    id?: string
}

const SelectCountry: React.FC<CountryPropsT> = ({ country, setShowCountry, id }) => {
    if (!country) return (
        <div className={styles.countryLayer} onClick={event => {
            event.stopPropagation();
            setShowCountry(true);
        }}>&nbsp;</div>
    )

    return (
        <div className={styles.countryLayer} id={id} onClick={event => {
            event.stopPropagation();
            setShowCountry(true);
        }}>
            <div className={styles.flag}>
                <Flag code={country?.isoCode2} title={country.country} /> <span />
            </div>
            {/* <div className={styles.countryName}>{country?.country}</div> */}
            <div className={styles.phoneDirection}>+{country?.countryCodes}</div>
        </div>
    )
}

type FlagPropsT = {
    code: string,
    title: string
}

export const Flag: React.FC<FlagPropsT> = ({ code, title }) => {

    switch (code){
        case 'AC': return <FLAG.AC title={title} width={25} />
        case 'AD': return <FLAG.AD title={title} width={25} />
        case 'AE': return <FLAG.AE title={title} width={25} />
        case 'AF': return <FLAG.AF title={title} width={25} />
        case 'AG': return <FLAG.AG title={title} width={25} />
        case 'AI': return <FLAG.AI title={title} width={25} />
        case 'AL': return <FLAG.AL title={title} width={25} />
        case 'AM': return <FLAG.AM title={title} width={25} />
        case 'AO': return <FLAG.AO title={title} width={25} />
        case 'AR': return <FLAG.AR title={title} width={25} />
        case 'AS': return <FLAG.AS title={title} width={25} />
        case 'AT': return <FLAG.AT title={title} width={25} />
        case 'AU': return <FLAG.AU title={title} width={25} />
        case 'AW': return <FLAG.AW title={title} width={25} />
        case 'AX': return <FLAG.AX title={title} width={25} />
        case 'AZ': return <FLAG.AZ title={title} width={25} />
        case 'BA': return <FLAG.BA title={title} width={25} />
        case 'BB': return <FLAG.BB title={title} width={25} />
        case 'BD': return <FLAG.BD title={title} width={25} />
        case 'BE': return <FLAG.BE title={title} width={25} />
        case 'BF': return <FLAG.BF title={title} width={25} />
        case 'BG': return <FLAG.BG title={title} width={25} />
        case 'BH': return <FLAG.BH title={title} width={25} />
        case 'BI': return <FLAG.BI title={title} width={25} />
        case 'BJ': return <FLAG.BJ title={title} width={25} />
        case 'BL': return <FLAG.BL title={title} width={25} />
        case 'BM': return <FLAG.BM title={title} width={25} />
        case 'BN': return <FLAG.BN title={title} width={25} />
        case 'BO': return <FLAG.BO title={title} width={25} />
        case 'BQ': return <FLAG.BQ title={title} width={25} />
        case 'BR': return <FLAG.BR title={title} width={25} />
        case 'BS': return <FLAG.BS title={title} width={25} />
        case 'BT': return <FLAG.BT title={title} width={25} />
        case 'BW': return <FLAG.BW title={title} width={25} />
        case 'BY': return <FLAG.BY title={title} width={25} />
        case 'BZ': return <FLAG.BZ title={title} width={25} />
        case 'CA': return <FLAG.CA title={title} width={25} />
        case 'CC': return <FLAG.CC title={title} width={25} />
        case 'CD': return <FLAG.CD title={title} width={25} />
        case 'CF': return <FLAG.CF title={title} width={25} />
        case 'CG': return <FLAG.CG title={title} width={25} />
        case 'CH': return <FLAG.CH title={title} width={25} />
        case 'CI': return <FLAG.CI title={title} width={25} />
        case 'CK': return <FLAG.CK title={title} width={25} />
        case 'CL': return <FLAG.CL title={title} width={25} />
        case 'CM': return <FLAG.CM title={title} width={25} />
        case 'CN': return <FLAG.CN title={title} width={25} />
        case 'CO': return <FLAG.CO title={title} width={25} />
        case 'CR': return <FLAG.CR title={title} width={25} />
        case 'CU': return <FLAG.CU title={title} width={25} />
        case 'CV': return <FLAG.CV title={title} width={25} />
        case 'CW': return <FLAG.CW title={title} width={25} />
        case 'CX': return <FLAG.CX title={title} width={25} />
        case 'CY': return <FLAG.CY title={title} width={25} />
        case 'CZ': return <FLAG.CZ title={title} width={25} />
        case 'DE': return <FLAG.DE title={title} width={25} />
        case 'DJ': return <FLAG.DJ title={title} width={25} />
        case 'DK': return <FLAG.DK title={title} width={25} />
        case 'DM': return <FLAG.DM title={title} width={25} />
        case 'DO': return <FLAG.DO title={title} width={25} />
        case 'DZ': return <FLAG.DZ title={title} width={25} />
        case 'EC': return <FLAG.EC title={title} width={25} />
        case 'EE': return <FLAG.EE title={title} width={25} />
        case 'EG': return <FLAG.EG title={title} width={25} />
        case 'EH': return <FLAG.EH title={title} width={25} />
        case 'ER': return <FLAG.ER title={title} width={25} />
        case 'ES': return <FLAG.ES title={title} width={25} />
        case 'ET': return <FLAG.ET title={title} width={25} />
        case 'FI': return <FLAG.FI title={title} width={25} />
        case 'FJ': return <FLAG.FJ title={title} width={25} />
        case 'FK': return <FLAG.FK title={title} width={25} />
        case 'FM': return <FLAG.FM title={title} width={25} />
        case 'FO': return <FLAG.FO title={title} width={25} />
        case 'FR': return <FLAG.FR title={title} width={25} />
        case 'GA': return <FLAG.GA title={title} width={25} />
        case 'GB': return <FLAG.GB title={title} width={25} />
        case 'GD': return <FLAG.GD title={title} width={25} />
        case 'GE': return <FLAG.GE title={title} width={25} />
        case 'GF': return <FLAG.GF title={title} width={25} />
        case 'GG': return <FLAG.GG title={title} width={25} />
        case 'GH': return <FLAG.GH title={title} width={25} />
        case 'GI': return <FLAG.GI title={title} width={25} />
        case 'GL': return <FLAG.GL title={title} width={25} />
        case 'GM': return <FLAG.GM title={title} width={25} />
        case 'GN': return <FLAG.GN title={title} width={25} />
        case 'GP': return <FLAG.GP title={title} width={25} />
        case 'GQ': return <FLAG.GQ title={title} width={25} />
        case 'GR': return <FLAG.GR title={title} width={25} />
        case 'GT': return <FLAG.GT title={title} width={25} />
        case 'GU': return <FLAG.GU title={title} width={25} />
        case 'GW': return <FLAG.GW title={title} width={25} />
        case 'GY': return <FLAG.GY title={title} width={25} />
        case 'HK': return <FLAG.HK title={title} width={25} />
        case 'HN': return <FLAG.HN title={title} width={25} />
        case 'HR': return <FLAG.HR title={title} width={25} />
        case 'HT': return <FLAG.HT title={title} width={25} />
        case 'HU': return <FLAG.HU title={title} width={25} />
        case 'ID': return <FLAG.ID title={title} width={25} />
        case 'IL': return <FLAG.IL title={title} width={25} />
        case 'IE': return <FLAG.IE title={title} width={25} />
        case 'IM': return <FLAG.IM title={title} width={25} />
        case 'IN': return <FLAG.IN title={title} width={25} />
        case 'IO': return <FLAG.IO title={title} width={25} />
        case 'IQ': return <FLAG.IQ title={title} width={25} />
        case 'IR': return <FLAG.IR title={title} width={25} />
        case 'IS': return <FLAG.IS title={title} width={25} />
        case 'IT': return <FLAG.IT title={title} width={25} />
        case 'JE': return <FLAG.JE title={title} width={25} />
        case 'JM': return <FLAG.JM title={title} width={25} />
        case 'JO': return <FLAG.JO title={title} width={25} />
        case 'JP': return <FLAG.JP title={title} width={25} />
        case 'KE': return <FLAG.KE title={title} width={25} />
        case 'KG': return <FLAG.KG title={title} width={25} />
        case 'KH': return <FLAG.KH title={title} width={25} />
        case 'KI': return <FLAG.KI title={title} width={25} />
        case 'KM': return <FLAG.KM title={title} width={25} />
        case 'KN': return <FLAG.KN title={title} width={25} />
        case 'KP': return <FLAG.KP title={title} width={25} />
        case 'KR': return <FLAG.KR title={title} width={25} />
        case 'KW': return <FLAG.KW title={title} width={25} />
        case 'KY': return <FLAG.KY title={title} width={25} />
        case 'KZ': return <FLAG.KZ title={title} width={25} />
        case 'LA': return <FLAG.LA title={title} width={25} />
        case 'LB': return <FLAG.LB title={title} width={25} />
        case 'LC': return <FLAG.LC title={title} width={25} />
        case 'LI': return <FLAG.LI title={title} width={25} />
        case 'LK': return <FLAG.LK title={title} width={25} />
        case 'LR': return <FLAG.LR title={title} width={25} />
        case 'LS': return <FLAG.LS title={title} width={25} />
        case 'LT': return <FLAG.LT title={title} width={25} />
        case 'LU': return <FLAG.LU title={title} width={25} />
        case 'LV': return <FLAG.LV title={title} width={25} />
        case 'LY': return <FLAG.LY title={title} width={25} />
        case 'MA': return <FLAG.MA title={title} width={25} />
        case 'MC': return <FLAG.MC title={title} width={25} />
        case 'MD': return <FLAG.MD title={title} width={25} />
        case 'ME': return <FLAG.ME title={title} width={25} />
        case 'MF': return <FLAG.MF title={title} width={25} />
        case 'MG': return <FLAG.MG title={title} width={25} />
        case 'MH': return <FLAG.MH title={title} width={25} />
        case 'MK': return <FLAG.MK title={title} width={25} />
        case 'ML': return <FLAG.ML title={title} width={25} />
        case 'MM': return <FLAG.MM title={title} width={25} />
        case 'MN': return <FLAG.MN title={title} width={25} />
        case 'MO': return <FLAG.MO title={title} width={25} />
        case 'MP': return <FLAG.MP title={title} width={25} />
        case 'MQ': return <FLAG.MQ title={title} width={25} />
        case 'MR': return <FLAG.MR title={title} width={25} />
        case 'MS': return <FLAG.MS title={title} width={25} />
        case 'MT': return <FLAG.MT title={title} width={25} />
        case 'MU': return <FLAG.MU title={title} width={25} />
        case 'MV': return <FLAG.MV title={title} width={25} />
        case 'MW': return <FLAG.MW title={title} width={25} />
        case 'MX': return <FLAG.MX title={title} width={25} />
        case 'MY': return <FLAG.MY title={title} width={25} />
        case 'MZ': return <FLAG.MZ title={title} width={25} />
        case 'NA': return <FLAG.NA title={title} width={25} />
        case 'NC': return <FLAG.NC title={title} width={25} />
        case 'NE': return <FLAG.NE title={title} width={25} />
        case 'NF': return <FLAG.NF title={title} width={25} />
        case 'NG': return <FLAG.NG title={title} width={25} />
        case 'NI': return <FLAG.NI title={title} width={25} />
        case 'NL': return <FLAG.NL title={title} width={25} />
        case 'NO': return <FLAG.NO title={title} width={25} />
        case 'NP': return <FLAG.NP title={title} width={25} />
        case 'NR': return <FLAG.NR title={title} width={25} />
        case 'NU': return <FLAG.NU title={title} width={25} />
        case 'NZ': return <FLAG.NZ title={title} width={25} />
        case 'OM': return <FLAG.OM title={title} width={25} />
        case 'PA': return <FLAG.PA title={title} width={25} />
        case 'PE': return <FLAG.PE title={title} width={25} />
        case 'PF': return <FLAG.PF title={title} width={25} />
        case 'PG': return <FLAG.PG title={title} width={25} />
        case 'PH': return <FLAG.PH title={title} width={25} />
        case 'PK': return <FLAG.PK title={title} width={25} />
        case 'PL': return <FLAG.PL title={title} width={25} />
        case 'PM': return <FLAG.PM title={title} width={25} />
        case 'PR': return <FLAG.PR title={title} width={25} />
        case 'PS': return <FLAG.PS title={title} width={25} />
        case 'PT': return <FLAG.PT title={title} width={25} />
        case 'PW': return <FLAG.PW title={title} width={25} />
        case 'PY': return <FLAG.PY title={title} width={25} />
        case 'QA': return <FLAG.QA title={title} width={25} />
        case 'RO': return <FLAG.RO title={title} width={25} />
        case 'RS': return <FLAG.RS title={title} width={25} />
        case 'RE': return <FLAG.RE title={title} width={25} />
        case 'RU': return <FLAG.RU title={title} width={25} />
        case 'RW': return <FLAG.RW title={title} width={25} />
        case 'SA': return <FLAG.SA title={title} width={25} />
        case 'SB': return <FLAG.SB title={title} width={25} />
        case 'SC': return <FLAG.SC title={title} width={25} />
        case 'SD': return <FLAG.SD title={title} width={25} />
        case 'SE': return <FLAG.SE title={title} width={25} />
        case 'SG': return <FLAG.SG title={title} width={25} />
        case 'SH': return <FLAG.SH title={title} width={25} />
        case 'SI': return <FLAG.SI title={title} width={25} />
        case 'SJ': return <FLAG.SJ title={title} width={25} />
        case 'SK': return <FLAG.SK title={title} width={25} />
        case 'SL': return <FLAG.SL title={title} width={25} />
        case 'SM': return <FLAG.SM title={title} width={25} />
        case 'SN': return <FLAG.SN title={title} width={25} />
        case 'SO': return <FLAG.SO title={title} width={25} />
        case 'SR': return <FLAG.SR title={title} width={25} />
        case 'SS': return <FLAG.SS title={title} width={25} />
        case 'ST': return <FLAG.ST title={title} width={25} />
        case 'SY': return <FLAG.SY title={title} width={25} />
        case 'SV': return <FLAG.SV title={title} width={25} />
        case 'SX': return <FLAG.SX title={title} width={25} />
        case 'SZ': return <FLAG.SZ title={title} width={25} />
        case 'TA': return <FLAG.TA title={title} width={25} />
        case 'TC': return <FLAG.TC title={title} width={25} />
        case 'TD': return <FLAG.TD title={title} width={25} />
        case 'TG': return <FLAG.TG title={title} width={25} />
        case 'TH': return <FLAG.TH title={title} width={25} />
        case 'TJ': return <FLAG.TJ title={title} width={25} />
        case 'TK': return <FLAG.TK title={title} width={25} />
        case 'TL': return <FLAG.TL title={title} width={25} />
        case 'TM': return <FLAG.TM title={title} width={25} />
        case 'TN': return <FLAG.TN title={title} width={25} />
        case 'TO': return <FLAG.TO title={title} width={25} />
        case 'TR': return <FLAG.TR title={title} width={25} />
        case 'TW': return <FLAG.TW title={title} width={25} />
        case 'TT': return <FLAG.TT title={title} width={25} />
        case 'TV': return <FLAG.TV title={title} width={25} />
        case 'TZ': return <FLAG.TZ title={title} width={25} />
        case 'UG': return <FLAG.UG title={title} width={25} />
        case 'UA': return <FLAG.UA title={title} width={25} />
        case 'US': return <FLAG.US title={title} width={25} />
        case 'UY': return <FLAG.UY title={title} width={25} />
        case 'UZ': return <FLAG.UZ title={title} width={25} />
        case 'VA': return <FLAG.VA title={title} width={25} />
        case 'VC': return <FLAG.VC title={title} width={25} />
        case 'VE': return <FLAG.VE title={title} width={25} />
        case 'VG': return <FLAG.VG title={title} width={25} />
        case 'VI': return <FLAG.VI title={title} width={25} />
        case 'VN': return <FLAG.VN title={title} width={25} />
        case 'VU': return <FLAG.VU title={title} width={25} />
        case 'WF': return <FLAG.WF title={title} width={25} />
        case 'WS': return <FLAG.WS title={title} width={25} />
        case 'XK': return <FLAG.XK title={title} width={25} />
        case 'YE': return <FLAG.YE title={title} width={25} />
        case 'YT': return <FLAG.YT title={title} width={25} />
        case 'ZA': return <FLAG.ZA title={title} width={25} />
        case 'ZM': return <FLAG.ZM title={title} width={25} />
        case 'ZW': return <FLAG.ZW title={title} width={25} />

    }

    return <FLAG.GB title={'English'} width={25} />;
}