import { IProps as SelectPropsT } from 'react-responsive-select';
import React, { useCallback } from 'react';
import cx from 'classnames';

import { ReactComponent as ArrowDown } from 'Assets/arrow-down.svg';
import { isIOS } from 'Helpers/responsiveContainers';
import styles from './Dropdown.module.scss';
import { isSafari } from "Helpers/browser";

export interface IDropdown {
	name: string;
	label: string;
	value: string;
	options: SelectPropsT['options'];
	onChange: (value: any) => void;
	className?: string;
	keyLang?:number;
	disabled?: boolean;
	noSelectionLabel?: string;
	isMobile?: boolean;
	hasEmptyValue?: boolean;
	id?: string
}

const Dropdown: React.FC<IDropdown> = ({ name, label, options, value, onChange, className, disabled = false, noSelectionLabel = '', isMobile = false, keyLang, hasEmptyValue = false, id = "select_register_salutation" }) => {

	const handleChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        onChange(e.target);
	}, [onChange]);

    return (
		<div className={cx(styles.root, className, { [styles.ios]: isIOS(), [styles.disabled]: disabled})}>
			{(!disabled) && (
				<ArrowDown className={styles.arrow}/>
			)}
			<span className={styles.label}>{label}</span>
			<select
				name={name}
				value={value || ''}
				onChange={handleChange}
				disabled={disabled}
				className={cx(styles.select, { [styles.safari]: isSafari })}
				id={id}
				title={options.find(o => o.value === value)?.text || ''}
			>
				{hasEmptyValue && <option value={''}></option>}
				{options.filter((option) => option.value).map((option) => (
					<option
						key={option.value}
						value={option.value || ''}
					>
						{option.text}
					</option>
				))}
			</select>
		</div>
    );
}

export default React.memo(Dropdown);