import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';

import type { StateT as ChatDataStateT } from 'Store/modules/claimChat/data';
import { ClaimStatusHistoryItemT } from "api/modules/claim";
import { ClaimProgressStageE } from 'Store/Enums/claim';
import { CLAIM_STATUSES } from "Config/statuses";
import StatusRow from "./StatusRow/StatusRow";
import styles from './CaseStatus.module.scss';
import { AppStateT } from "Store/modules";
import { useSelector } from "react-redux";

type StatusHistoryDataT = {
	status: CLAIM_STATUSES,
	date: Date,
	id: string
}

const remapStatusHistory = (data: ClaimStatusHistoryItemT): StatusHistoryDataT => {
	return {
		status: data.status,
		date: new Date(data.createdAt),
		id: data.id
	}
}

const CaseStatus: React.FC = () => {
	const { t } = useTranslation();
	const { claim } = useSelector<AppStateT, Pick<ChatDataStateT, 'claim'>>(({ claimChat }) => ({ claim: claimChat.data.claim }));

	const formattedDataOrder: StatusHistoryDataT[] = useMemo(() => {
		if (!claim?.statusHistory || !Array.isArray(claim.statusHistory)) {
			return [];
		}
	
		const seenStatuses = new Set<CLAIM_STATUSES>();
		const excludedStages = [
			ClaimProgressStageE.PICKUP_PROCESS_BY_PARTNER,
			ClaimProgressStageE.PICKUP_REPAIR_COMPLETED_BY_EXTERNAL_PARTNER,
			ClaimProgressStageE.PICKUP_EVALUATION_BY_CUSTOMER_SERVICE
		];

		return claim.statusHistory
			.filter((item) => {
				if (seenStatuses.has(item.status)) {
					return false;
				}
				if (item.status === CLAIM_STATUSES.SOLUTION_PICKUP_PENDING && claim.feed?.entries?.some(entry => excludedStages.includes(entry.stage))) {
					return false;
				}
				seenStatuses.add(item.status);
				return true;
			})
			.map(remapStatusHistory)
			.reverse();
	}, [claim?.statusHistory, claim.feed?.entries]);

	return (
		<div className={styles.caseStatus}>
			<span className={styles.title}>{t('caseDetails.caseStatus')}</span>
			{formattedDataOrder.map((d, idx) => (
				<StatusRow
					claim={claim}
					date={d.date}
					status={d.status}
					index={idx}
					t={t}
					id={d.id}
					key={`d-${claim.id}-${d.id}`}
				/>
			))}
		</div>
	);
}

export default CaseStatus;
