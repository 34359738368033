import React, { useCallback, useEffect, useReducer, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ImgRimowa from 'Assets/new_claim/serial_number_rimowa.png';
import ImgTumi from 'Assets/new_claim/serial_number_tumi.png';

import PhoneDirect from 'PageComponents/CreateClaim/CreateClaim/CreateForm/Steps/Contact/PhoneDirect/PhoneDirect';
import { RequiredDocumentT } from 'PageComponents/CreateClaim/CreateClaim/CreateForm/CreateForm';
import { resetReportState, StateT as ReportStateT } from 'Store/modules/claimChat/report';
import { Button, Input, ChatMessageGroup, LoadableContent } from 'Components';
import { IImage } from 'PageComponents/Chat/ReportUploadBox/ReportUploadBox';
import ExternalnfoData from "Components/ExternalnfoData/ExternalnfoData";
import DropDownSearch from 'Components/DropDownSearch/DropDownSearch';
import { PageConfigT, changeModalTooltip } from 'Store/modules/page';
import { type StateT as UserStateT } from 'Store/modules/user';
import { getUrlMissingDoc, sizeAdapter } from 'Helpers/claim';
import { MissingDocExampleT } from "Store/modules/claim";
import Resolution from './Resolution/Resolution';
import style from './ReportBlock.module.scss';
import DropImage from './DropImage/DropImage';
import InputData from './InputData/InputData';
import useTranslate from "Hooks/useTranslate";
import { codes } from 'country-calling-code';
import { makeSlug } from "Helpers/strings";
import { AppStateT } from 'Store/modules';
import Ico from 'Components/Ico/Ico';
import Store from './Store';
import BlockText from "Components/BlockText/BlockText";

export type PhotoT = {
    images: IImage[],
    type: string
}

export type InputT = {
    value: string,
    type: string
}

export type LocalStateT = {
    images: PhotoT[],
    inputs: InputT[],
    valid: boolean,
}

const defaultState: LocalStateT = {
    images: [],
    inputs: [],
    valid: false
}

type IReportBlock = {
    report: ReportT;
    onSubmit: (data: any) => void;
    requredDocuments: RequiredDocumentT[],
    missingDocsExamples: MissingDocExampleT[]
}

const TAG_TO_NAME: { [key in string]: string } = {
    REFERENCE_NO: 'reportTags.referenceNumber',
    FILE_REFERENCE: 'reportTags.referenceNumber',
    TICKET_BOARDING_CARD: 'reportTags.ticketBoardingCard',
    PHOTOS_OF_LUGGAGE: 'reportTags.photosOfLuggage',
    BAGGAGE_TAG: 'reportTags.baggageTag',
    AIRLINE_AUTHORIZATION_LETTER_COPY: 'reportTags.airlinesAuthorizationLetterCopy',
    SIZE: 'reportTags.dimensions',
    MEASUREMENT: 'reportTags.size',
    SERIAL_NO: 'reportTags.serialNumber',
    AIRPORT_DOCUMENT: 'reportTags.missingDocument',
    BAGGAGE_TAG_COPY: 'reportTags.baggageTag',

    PHOTO_OF_THE_LOGO: 'missingDocuments.PhotoOfTheLogo',
    PHOTO_OF_LUGGAGE_TOP: 'reportTags.photTop',
    PHOTO_OF_LUGGAGE_BOTTOM: 'reportTags.photoBottom',
    PHOTO_OF_LUGGAGE_LEFT: 'reportTags.photoLeft',
    PHOTO_OF_LUGGAGE_RIGHT: 'reportTags.photoRight',
    PHOTO_FROM_THE_FRONT: 'reportTags.photoFront',
    PHOTO_OF_THE_DAMAGE: 'reportTags.photoDamage',
    AIRLINE_PROCESSING_NUMBER: 'reportTags.airlineProcessingNumber',// string
    BOARDING_CARD_COPY: 'reportTags.boardingCard',
    BOOKING_CONFIRMATION: 'reportTags.bookingConfirmation',
    DPR_COPY: 'reportTags.dprCopy',
    EMAIL_FROM_THE_AIRLINE: 'reportTags.emailFromAirline',
    R_AND_D_COPY: 'createClaim.missedRndCopy',
    E_TICKET: 'reportTags.eticket',// zdjęcie biletu elektronicznego
    FLIGHT_TICKET_COPY: 'reportTags.flightTicket',
    PO_NUMBER: 'reportTags.poNumber',// numer rezerwacji (po number)// string
    PURCHASE_RECEIPT_COPY: 'reportTags.purchaseReceipt',
    RIMOWA_SERIAL_NUMBER: 'reportTags.rimowaSerialNumber',// string
    TUMI_SERIAL_NUMBER: 'reportTags.tumiSerialNumber',// string
    STAMPED_LEAFLET: 'reportTags.stampedLeaflet',
    WARRANTY_CARD_COPY: 'reportTags.warrantyCard',
    ADDRESS: 'reportTags.adderess',
    PHONE_NUMBER: 'labels.phone',
    PRODUCT: 'createClaim.detBrand',
    MANUFACTURER: 'missingDocuments.Manufacturer',
    TK_RELEASE_FORM_PL: 'missingDocuments.TKReleaseFormPL',
    TK_RELEASE_FORM_DE: 'missingDocuments.TKReleaseFormDE',

    BAGGAGE_TAG_ORIGINAL: 'reportTags.baggageTagOriginal', 
    BOARDING_CARD_ORIGINAL: 'reportTags.boardingCardOriginal',
    DPR_ORIGINAL: 'reportTags.dprOriginal',
    FLIGHT_TICKET_ORIGINAL: 'reportTags.flightTicketOriginal'
};

// R_AND_D_COPY
// RELEASE_AND_DISCHARGE_QR
// TK_RELEASE_FORM_DE
// TK_RELEASE_FORM_PL

const TAG_INPUTS = [ 'SIZE', 'SERIAL_NO', 'REFERENCE_NO', 'RIMOWA_SERIAL_NUMBER', 'TUMI_SERIAL_NUMBER', 'AIRLINE_PROCESSING_NUMBER', 'ADDRESS', 'PHONE_NUMBER', 'MANUFACTURER', 'PRODUCT', 'MEASUREMENT', 'FILE_REFERENCE' ];
export const AIRPORT_DOCUMENT = 'AIRPORT_DOCUMENT';
const rejectedBrands = ['10639', '10711'];
const MIN_INPUT_TAG_CHARS = { SIZE: 1, MANUFACTURER: 2 };
const MIN_INPUT_CHARS = 3;

const ReportBlock: React.FC<IReportBlock> = ({ report, onSubmit, requredDocuments, missingDocsExamples }) => {

    const user = useSelector<AppStateT, UserStateT['data']>(({ user }) => user.data);
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);

    const [state, setState] = useReducer(Store, defaultState);
    const t = useTranslate();
    const reportState = useSelector<AppStateT, ReportStateT>(({ claimChat }) => claimChat.report);
    const [showModalPhoneDirect, setShowModalPhoneDirect] = useState(false);
    
    const getInitialPrefixText = useCallback(() => {
        const code = codes.find(c => c.isoCode2 === user?.location?.countryCode);
        return code ? code.countryCodes[0] : '';
    }, [user?.location?.countryCode]);
    const dispatch = useDispatch();
    const [showExamples, setShowExamples] = useState(false);
    const [prefixText, setPrefixText] = useState(getInitialPrefixText());
    const isMounted = useRef(false);

    const { tumiSerialNumber, rimowaSerialNumber, referenceNumber, serialNumber, airlineProcessingNumber, phone } = pageConfig.inputs;
    const reportSend = reportState.loaded;
    const brands = useMemo(() => pageConfig.claim.brands.map(brand => {
        return { value: brand, text: brand }
    }), [pageConfig.claim.brands]);

    const getInputData = useCallback((tag: string, value: string) => {
        if (tag === 'TUMI_SERIAL_NUMBER') {
            return {
                maxLength: tumiSerialNumber.maxLength,
                success: value?.length === tumiSerialNumber.maxLength && !!value?.match(tumiSerialNumber.regexp)
            }
        }
        if (tag === 'RIMOWA_SERIAL_NUMBER') {
            return {
                maxLength: rimowaSerialNumber.maxLength,
                success: value?.length >= rimowaSerialNumber.minLength && value?.length <= rimowaSerialNumber.maxLength && !!value?.match(rimowaSerialNumber.regexp)
            }
        }
        if (tag === 'REFERENCE_NO' || tag === 'FILE_REFERENCE') {
            return {
                maxLength: referenceNumber.maxLength,
                success: value?.length >= referenceNumber.minLength && value?.length <= referenceNumber.maxLength && !!value?.match(referenceNumber.regexp)
            }
        }
        if (tag === 'SERIAL_NO') {
            return {
                maxLength: serialNumber.maxLength,
                success: value?.length >= serialNumber.minLength && value?.length <= serialNumber.maxLength && !!value?.match(serialNumber.regexp)
            }
        }
        if (tag === 'MEASUREMENT') {
            return {
                maxLength: 3,
                success: value?.length > 1 && value?.length <= 3 && !isNaN(+value) && +value >= 10
            };
        }
        if (tag === 'AIRLINE_PROCESSING_NUMBER') {
            return {
                maxLength: airlineProcessingNumber.maxLength,
                success: value?.length >= airlineProcessingNumber.minLength && value?.length <= airlineProcessingNumber.maxLength && !!value?.match(airlineProcessingNumber.regexp)
            }
        }
        if (tag === 'PHONE_NUMBER') {
            return {
                maxLength: phone.maxLength,
                success: prefixText ? ('+' + prefixText + value).match(phone.regexp) && value?.length >= phone.minLength - (prefixText.length + 1) && value?.length <= phone.maxLength - (prefixText.length + 1):
                        value.match(phone.regexp) && value?.length >= phone.minLength && value?.length <= phone.maxLength,
            }
        }
        return {
            maxLength: 40,
            success: value && value?.length > MIN_INPUT_CHARS
        }
    }, [
        airlineProcessingNumber,
        phone,
        referenceNumber,
        rimowaSerialNumber,
        serialNumber,
        tumiSerialNumber,
        prefixText
    ])

    const toolTipBrand = useCallback((tag: string) => {
        if (tag === 'RIMOWA_SERIAL_NUMBER'){
            dispatch(changeModalTooltip({ 
                title: t('createClaim.detTipSerialNr'), 
                content: t('createClaim.detTipSerialNrRimowaDesc'),
                object: <div className={style.toolTipImage}><img src={ImgRimowa} alt="" /></div>
            }));
        } else if (tag === 'TUMI_SERIAL_NUMBER'){
            dispatch(changeModalTooltip({ 
                title: t('createClaim.detTipSerialNr'), 
                content: t('createClaim.detTipSerialNrDescTumi'),
                object: <div className={style.toolTipImage}><img src={ImgTumi} alt="" /></div>
            }));
        }
    }, [dispatch, t]);

    useEffect(() => {
        if (reportSend) {
            dispatch(resetReportState());
        }
    }, [dispatch, reportSend]);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        return () => {
            localStorage.removeItem(`r-${report.id}`);
        }
    }, [report.id]);

    useEffect(() => {
        const tagsWithoutOriginal = report.tags.filter(d => d.value.indexOf('_ORIGINAL') < 0);
        for(let i=0;i<tagsWithoutOriginal.length;i++){
            if (!TAG_INPUTS.includes(tagsWithoutOriginal[i].value)){
                if (!(state.images?.find(im => im.type === tagsWithoutOriginal[i].value)?.images?.length > 0)){
                    return setState({ type: 'SET_VALID', data: false });
                }
            } else if (TAG_INPUTS.includes(tagsWithoutOriginal[i].value)){
                const _tagInput = state.inputs?.find(inp => inp.type === tagsWithoutOriginal[i].value);
                if (!_tagInput) {
                    return setState({ type: 'SET_VALID', data: false });
                }
                const inputData = getInputData(_tagInput.type, _tagInput.value);
                if (MIN_INPUT_TAG_CHARS?.[_tagInput.type]){
                    if (MIN_INPUT_TAG_CHARS[_tagInput.type] > _tagInput.value.length){
                        return setState({ type: 'SET_VALID', data: false });
                    }
                } else if (!inputData.success){
                    return setState({ type: 'SET_VALID', data: false });
                }
            }
        }
        return setState({ type: 'SET_VALID', data: true });
    }, [state.images, state.inputs, report.tags, getInputData]);

    const downloadDocument = useCallback((req: RequiredDocumentT) => {
        const url = getUrlMissingDoc(req.Url__c, user.location.countryCode);
        if (url){
            window.open(url);
        }
    }, [user.location.countryCode])

    const actionChangeInput = (dataType: string, data: string) => {
        setState({ type: 'SET_INPUTS', data, dataType });
    }

    const searchTitleFromReq = useCallback((tag: string) => {
        const reqDoc = requredDocuments?.find(doc => doc?.Url__c && tag.replaceAll('_', '-') === doc.Missing_Document_Type__c);
        if (reqDoc){
            return t('reportTags.missingDocument') + ': ' + reqDoc.Name;
        }
        return tag;
    }, [requredDocuments, t]);

    const getTitle = useCallback((tag: string) => {
        return TAG_TO_NAME[tag] ? t(TAG_TO_NAME[tag]) : searchTitleFromReq(tag);
    }, [searchTitleFromReq, t]);

    const showExampleData = useMemo(() => (tag: string): MissingDocExampleT|null => {
        return missingDocsExamples.find(m => m.name === tag) || null;
    }, [missingDocsExamples])

    const ShowComponent = useCallback((tag: string) => {

        const requreDocumentAirport = requredDocuments?.find(doc => !!doc?.Url__c && doc.Obligatory__c && doc.Missing_Document_Type__c.replaceAll('-', '_') === tag);

        const example = showExampleData(tag);
        if (!TAG_INPUTS.includes(tag)){
            return (
                <DropImage
                    images={state.images?.find(ph => ph.type === tag)?.images || []}
                    allImages={state.images.reduce((acc: IImage[], photoT: PhotoT) => {
                        return acc.concat(photoT.images);
                    }, [])}
                    type={tag}
                    t={t}
                    setImages={(dataType: string, data: IImage[]) => setState({ type: 'SET_IMAGES', data, dataType })}
                    documentRequire={requreDocumentAirport}
                    downloadDocument={downloadDocument}
                    title={t(TAG_TO_NAME[tag])}
                    id={'chat_report_file_' + makeSlug(tag)}
                    exampleData={example}
                />
            )
        } else {
            const value = state.inputs?.find(inp => inp.type === tag)?.value || '';
            if (tag === 'SIZE'){

                const sizes = Object.entries(pageConfig.claim.sizes).map(size => {
                    return { value: size[0], text: sizeAdapter(size[0], user.location.countryCode, user.language) };
                })

                const actionChangeSize = (data: any) => {
                    setState({ type: 'SET_INPUTS', data: data.value, dataType: 'SIZE' });
                }

                return (
                    <>
                        <div className={style.inputRow}>
                            <div className={style.dropdown}>
                                <DropDownSearch
                                    data={sizes.map(size => {
                                        return {
                                            text: sizeAdapter(size.value, user.location.countryCode, user.language),
                                            value: size.value
                                        };
                                    })}
                                    onChange={actionChangeSize}
                                    label={t(getTitle(tag))}
                                    value={value}
                                    className={style.inputLayer}
                                    success={value.length >= MIN_INPUT_TAG_CHARS[tag]}
                                    id={'chat_report_size_' + makeSlug(tag)}
                                    t={t}
                                />
                            </div>
                        </div>
                    </>
                )
            } else if (tag === 'MANUFACTURER') {
                return (
                    <div className={style.inputRow}>
                        <div className={style.dropdown}>
                            <DropDownSearch
                                data={[...brands.filter(brand => brand.value !== 'Other Brand' && !rejectedBrands.includes(brand.value)).sort(), ...brands.filter(brand => brand.value === 'Other Brand')]}
                                onChange={(data) => actionChangeInput(tag, data.value)}
                                label={t(getTitle(tag))}
                                value={state.inputs.find(inp => inp.type === tag)?.value || ''}
                                className={style.inputLayer}
                                success={value.length >= MIN_INPUT_TAG_CHARS[tag]}
                                id={'chat_report_manufacturer_' + makeSlug(tag)}
                                search
                                t={t}
                            />
                        </div>
                    </div>
                )
            } else if (tag === 'PHONE_NUMBER') {
                const updatePrefixText = (newPrefix: string) => setPrefixText(newPrefix);
                const actionChangePhoneNumber = (data: any) => {
                    const dataValue = prefixText ? data.target.value.replace(/\D/g, '') : data.target.value;
                    setState({ type: 'SET_INPUTS', data: dataValue, dataType: 'PHONE_NUMBER' });
                }

                return (
                    <>
                        <Input
                            value={value}
                            onChange={actionChangePhoneNumber}
                            label={getTitle(tag)}
                            className={style.inputLayer}
                            alwaysSmallLabel
                            success={getInputData(tag, value).success}
                            maxLength={getInputData(tag, value).maxLength}
                            prefixText={'+' + prefixText}
                            onPrefixClick={() => setShowModalPhoneDirect(true)}
                            id={'chat_report_phone_number_' + makeSlug(tag)}
                        />
                        {showModalPhoneDirect &&
                            <PhoneDirect
                                actionChange={(value) => {
                                    updatePrefixText(value);
                                    setShowModalPhoneDirect(false);
                                }}
                                actionClose={() => setShowModalPhoneDirect(false)}
                                value={prefixText}
                                t={t}
                            />
                        }
                    </>
                );
            } else if (tag === 'MEASUREMENT') {
                const actionChangeMeasurement = (data: any) => {
                    setState({ type: 'SET_INPUTS', data: data.target.value.replace(/\D/g, '').replace(/^0+/, ''), dataType: 'MEASUREMENT' });
                }
                return (
                    <>
                        <div className={style.inputRow}>
                            <Input
                                label={getTitle(tag)}
                                value={value}
                                onChange={actionChangeMeasurement}
                                className={style.inputLayer}
                                alwaysSmallLabel
                                rightModeData={t('common.cm')}
                                success={getInputData(tag, value).success}
                                maxLength={getInputData(tag, value).maxLength}
                                id={'chat_report_measurement_' + makeSlug(tag)}
                            />
                            {example &&
                                <div className={style.icoInfo}>
                                    <span className={style.headerIco}><Ico action={() => setShowExamples(old => !old)} /></span>
                                </div>
                            }
                        </div>
                        {showExamples &&
                            <ExternalnfoData
                                t={t}
                                missingType={example.SFTag}
                                missingDocumentsAdditionalInfo={example}
                            />
                        }
                    </>
                )
            }
            return (
                <div className={style.inputRow}>
                    <InputData
                        actionChange={actionChangeInput}
                        value={value}
                        type={tag}
                        title={getTitle(tag)}
                        maxLength={getInputData(tag, value).maxLength}
                        success={getInputData(tag, value).success}
                        exampleData={example}
                        id={'chat_report_input_' + makeSlug(tag)}
                    />
                    {(tag === 'RIMOWA_SERIAL_NUMBER' || tag === 'TUMI_SERIAL_NUMBER') &&
                        <div className={style.icoInfo}>
                            <span className={style.headerIco}><Ico action={() => toolTipBrand(tag)} /></span>
                        </div>
                    }
                </div>
            )
        }
    }, [downloadDocument, getTitle, brands, requredDocuments, state.images, state.inputs, t, pageConfig.claim.sizes, user.location.countryCode, user.language, toolTipBrand, getInputData, showModalPhoneDirect, prefixText, showExampleData, showExamples]);

    const actionSubmit = useCallback((event: React.FormEvent) => {
        event.preventDefault();
        const dataSend = {};
        const dataAirlineDocuments = [];
        report.tags.filter(d => d.value.indexOf('_ORIGINAL') < 0).forEach(data => {
            if (!TAG_INPUTS.includes(data.value)){
                const documentFile = requredDocuments?.find(doc => doc.Missing_Document_Type__c === data.value.replaceAll('_', '-') && doc.Url__c);
                let dataFiles = null
                dataFiles = state.images.filter(im => im.type === data.value).reduce((acc: IImage[], photoT: PhotoT) => {
                    return acc.concat(photoT.images);
                }, []);
                if (documentFile){
                    dataFiles.forEach((d: any) => {
                        dataAirlineDocuments.push(d);
                    });
                } else {
                    dataSend[data.value] = dataFiles;
                }
            } else {
                const input = state.inputs?.find(inp => inp.type === data.value);
                if (input) {
                    if (input.type === 'PHONE_NUMBER') {
                        dataSend[data.value] = `+${prefixText}${input.value}`;
                    } else if (input.type === 'MEASUREMENT') {
                        dataSend[data.value] = `${input.value}cm`
                    } else {
                        dataSend[data.value] = input.value;
                    }
                }
            }
        });
        if (dataAirlineDocuments.length > 0){
            dataSend[AIRPORT_DOCUMENT] = dataAirlineDocuments;
        }
        onSubmit(dataSend);
    }, [onSubmit, report.tags, state.images, state.inputs, requredDocuments, prefixText]);

    return (
        <div className={style.root} id="chat_report_problem">
            {!report?.isPostedReply ? (
                <ChatMessageGroup
                    direction="incoming"
                    data={[
                        {
                            render: () =>
                                report.tags.filter(d => d.value.indexOf('_ORIGINAL') < 0).length > 0 && (
                                    <LoadableContent loading={reportState.loading}>
                                        <form onSubmit={actionSubmit}>
                                            <div className={style.layerChatMain}>
                                                {t('report.reportMessage3')}
                                                {report.tags.filter(d => d.value.indexOf('_ORIGINAL') < 0).length > 0 && (
                                                    <ol>
                                                        {report.tags.filter(d => d.value.indexOf('_ORIGINAL') < 0).map(tag => (
                                                            <li key={`li-${tag.value}`}>{getTitle(tag.value)}</li>
                                                        ))}
                                                    </ol>
                                                )}
                                            </div>

                                            {report.tags.filter(data => data.value.indexOf('_ORIGINAL') < 0 && !TAG_INPUTS.includes(data.value)).length > 0 &&
                                                <BlockText content={t('reportProblem.infoImagesWarning')} fontBigger type={'error'} />
                                            }

                                            {report.tags
                                                .filter(d => d.value.indexOf('_ORIGINAL') < 0)
                                                .map(tag => (
                                                    <React.Fragment key={`tag-${tag.value}`}>{ShowComponent(tag.value)}</React.Fragment>
                                                ))
                                            }
                                            <Button className={style.buttonSend} type="submit" disabled={!state.valid}>
                                                {t('report.send')}
                                            </Button>
                                        </form>
                                    </LoadableContent>
                                ),
                            className: style.chatMessageMain,
                        },
                    ]}
                    avatar="bot"
                />
            ) : (
                <>
                    <Resolution report={report} t={t} tagToNameData={TAG_TO_NAME} requireDocuments={requredDocuments} user={user} />
                </>
            )}
        </div>
      );
}
export default React.memo(ReportBlock);