import cx from 'classnames';
import React from 'react';

import style from './BlockText.module.scss';

type MainPropsT = {
    type?: 'info'|'success'|'error'|'flat',
    content: string,
    className?: string,
    center?: boolean,
    fontBigger?: boolean,
    italic?: boolean
}

const BlockText: React.FC<MainPropsT> = ({ content, center, className, type = 'info', fontBigger, italic }) => {
    return (
        <div
            className={
                cx(
                    style.root,
                    {
                        [style.success]: type === 'success',
                        [style.error]: type === 'error',
                        [style.flat]: type === 'flat',
                        [style.bigger]: !!fontBigger,
                        [style.italic]: !!italic,
                        [style.center]: !!center
                    },
                    className
                )
            }
        >
            {content}
        </div>
    );
}
export default React.memo(BlockText);