import React from 'react';

import BlackCase from "PageComponents/BlackCase/BlackCase";
import { ClaimT } from "api/modules/claim";

type MainPropsT = {
    claim: ClaimT,
    t: (data: string, params?: any) => string,
    openCreateClaimDesktop?: () => void
}

const BlackCaseAwaitingPaxCompletion: React.FC<MainPropsT> = ({ claim, t, openCreateClaimDesktop }) => {
    return (
        <>
            <BlackCase
                claim={claim}
                openCreateClaimDesktop={openCreateClaimDesktop}
            />
        </>
    );
}
export default BlackCaseAwaitingPaxCompletion;