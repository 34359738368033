import { Button } from "Components";
import React from 'react';

import useTranslate from "Hooks/useTranslate";
import style from './ModalNotLandingStationSupported.module.scss';
import CustomModal from "Components/CustomModal/CustomModal";

type MainPropsT = {
    actionClose: () => void,
    actionCloseModalMain: () => void,
    landingStationCode: string,
    show?: boolean,
}

const ModalNotLandingStationSupported: React.FC<MainPropsT> = ({ actionClose, actionCloseModalMain, landingStationCode, show = false }) => {
    const t = useTranslate();

    return (
        <CustomModal 
            open={show} 
            title={`${t('createClaim.headerNoLandingStationSupport')}${landingStationCode ? ' (' + landingStationCode + ')' : ''}`} 
            onClose={actionClose} 
            dialogStyle={{ color: "#f14915" }}
        >
            <div className={style.root}>
                {t('createClaim.labelNoLandingStationSupport')}
            </div>
            <div className={style.buttons}>
                <Button onClick={actionCloseModalMain} type={'button'} color={'secondary'} block>{t('createClaim.duplicateModalCloseButton')}</Button>
                <Button onClick={actionClose} type={'button'} color={'primary'} block>{t('common.continue')}</Button>
            </div>
        </CustomModal>
    );
}

export default ModalNotLandingStationSupported;