import React, { useMemo } from "react";

import { FeedMessageLine } from "../../../DataStructure/Components";

type MainPropsT = {
    url: string,
    trackingNumber: string,
    t: (data: string, params?: any) => string
}

const PickupShipmentTracking: React.FC<MainPropsT> = ({ url, trackingNumber, t }) => {

    const dataEntry = useMemo(() => {
        return {
            shipmentCourier: url,
            shipmentTrackingNumber: `<strong>${trackingNumber}</strong>`
        }
    }, [trackingNumber, url]);

    return (
        <FeedMessageLine
            data={[[(t('chatMessages.pickupShipment')), (t('chatMessages.pickupShipmentTracking', dataEntry)), true]]}
        />
    );
}
export default PickupShipmentTracking;