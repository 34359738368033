import React, { useMemo } from "react";

import { FeedMessageLine } from "../../../DataStructure/Components";
import { CLAIM_STATUSES } from "../../../DataStructure/interfaces";
import { getFeedTypeData } from "../../../DataStructure/helpers";
import { getTranslationOrFallback } from "Helpers/strings";

type MainPropsT = {
    country: string,
    externalPartnerName: string,
    t: (data: string, params?: any) => string
}

// [SOLUTION_REPAIR_BY_EXTERNAL_PARTNER]
const PickupRepairCompletedByPartner: React.FC<MainPropsT> = ({ country, externalPartnerName, t }) => {

    const countryString = (country[0].toLowerCase() + country.slice(1)).replace(/ /g, '');

    const dataEntry = useMemo(() => {
        const translatedCountry = getTranslationOrFallback(t, `common.${countryString}`, country);
        return {
            country: `<i>${translatedCountry}</i>`
        }
    }, [country, countryString, t]);

    const dataEntryMain = useMemo(() => {
        const translatedCountry = getTranslationOrFallback(t, `common.${countryString}`, country);
        return {
            country: `<strong>${translatedCountry}</strong>`,
            externalPartnerName: `<strong><i>${externalPartnerName}</i></strong>`
        }
    }, [country, countryString, externalPartnerName, t]);

    const dataShow = useMemo(() => getFeedTypeData(CLAIM_STATUSES.SOLUTION_REPAIR_BY_EXTERNAL_PARTNER, t, dataEntry, dataEntryMain), [t, dataEntry, dataEntryMain]);

    return (
        <FeedMessageLine
            data={dataShow}
            spaceBottom
            dataOverText={{ text: t('caseStatuses.SOLUTION_REPAIR_BY_EXTERNAL_PARTNER') }}
        />
    )
}
export default React.memo(PickupRepairCompletedByPartner);