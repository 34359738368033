import React from 'react';

import style from './TextValue.module.scss';
import { InfoEdit } from '../Summary';

type MainPropsT = {
	label: string;
	value: string;
	stepIndex: string;
	color?: string | undefined;
	action?: (slug: string) => void;
};

const TextValue: React.FC<MainPropsT> = ({ label, value, color, action, stepIndex }) => {
	return (
		<div className={style.root}>
			<div className={style.label}>{label}</div>
			<div className={style.value}>
				{color && <div className={style.color} style={{ backgroundColor: color }} />}
				<span className={style.baseValue}>{value}</span>
			</div>
			<span>
				{action && <InfoEdit action={() => action?.(stepIndex)} />}
			</span>
		</div>
	);
};
export default TextValue;
