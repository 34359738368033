import { useEffect, useState } from 'react';

import { IImage } from "PageComponents/Chat/ReportUploadBox/ReportUploadBox";
import { ClaimFileT, ClaimT } from "api/modules/claim";
import { ClaimFileSourceE } from "Store/Enums/claim";
import { setImageDataUrl } from "Helpers/files";
import { countrys } from 'Helpers/countrys';
import { ColorsT } from "./CreateForm";

const useBlackCase = (claim: ClaimT|null, setState: (data: any) => void, brands: string[], colors: ColorsT | {}, materials: string[], blockAdd: boolean) => {

    const [statusBlackCase, setStatusBlackCase] = useState<'processing'|'done'|null>(null);

    useEffect(() => {
        if (claim && !blockAdd){
            setState({ type: 'SET_EDIT_CASE', data: claim });
            setStatusBlackCase('processing');

            const brand = brands.find(b => b.toLowerCase() === claim.luggage.brand.toLowerCase());
            const material = materials.find(m => m.toLowerCase() === claim.luggage.material.toLowerCase());
            const countryBase = countrys.find(c => c.text.toLowerCase() === (claim.location?.country || '').toLowerCase());

            const dataSave = {
                airlineDateLanding: claim.airlineDateLanding ? new Date(claim.airlineDateLanding) : null,
                airlineId: claim.airlineId,
                airlineName: claim.airlineName,
                referenceNumber: claim.referenceNumber || '',
                currency: claim.currencyIsoCode,
                luggageType: claim.luggage?.type || claim.luggageInput?.type,
                cost: claim.case.wtValue,
                landingStationSelected: claim.landingStation,
                damage: claim.luggage?.damages || claim.luggageInput?.damages || [],
                brand: brand || brands.find(b => b === 'Other brand'),
                functions: claim.luggage?.functions || claim.luggageInput?.functions || '',
                model: claim.luggage?.model || claim.luggageInput?.model || '',
                size: claim.luggage?.size || claim.luggageInput?.size || '',
                color: claim.luggage?.color || claim.luggageInput?.color || '',
                buyDate: claim.luggage.date ? new Date(claim.luggage.date) : (claim.luggageInput.date ? new Date(claim.luggageInput.date) : null),
                material: material || '',
                address: claim.location.address,
                city: claim.location.city,
                country: countryBase ? { code: countryBase.value, name: countryBase.text } : null,
                zipCode: claim.location.zipCode,
                salesforceId: claim.salesforceId || null
            }
            setState({
                type: 'SET_CLAIM_EDIT',
                data: dataSave
            });
            setStatusBlackCase('done');

        } else {
            setStatusBlackCase(null);
        }
    }, [claim, setState, brands, colors, materials, blockAdd]);

    useEffect(() => {
        if (!blockAdd && Array.isArray(claim?.case?.files?.photos)){
            const damagePosition = ['front', 'back', 'top', 'bottom', 'left', 'right'];
    
            const getFiles = async () => {
                const damages: ClaimFileT[] = [];
                const photos: { [key: string]: ClaimFileT } = {};
                const photosData: ClaimFileT[] = claim.case.files.photos.filter(photo => photo?.source !== ClaimFileSourceE.SALESFORCE);
                const photoUnrecognized: IImage[] = [];

                for (const currentFile of photosData) {
                    if (currentFile.type === 'damage' && !damages.some(d => d.thumbnail?.key === currentFile.thumbnail.key)) {
                        const dataResultDamages = await setImageDataUrl(currentFile.original.url);
                        if (dataResultDamages) {
                            damages.push(dataResultDamages);
                        }
                    } else if (damagePosition.includes(currentFile.type) && !photos[currentFile.type]) {
                        const dataResultPhotos = await setImageDataUrl(currentFile.original.url);
                        if (dataResultPhotos) {
                            photos[currentFile.type] = dataResultPhotos;
                        }
                    } else if (!damagePosition.includes(currentFile.type) && currentFile.type !== 'damage') {
                        const dataResult = await setImageDataUrl(currentFile.original.url);
                        if (dataResult) {
                            photoUnrecognized.push(dataResult);
                        }
                    }
                }
    
                if (damages.length > 0 || Object.entries(photos).length > 0 || photoUnrecognized.length > 0) {
                    setState({
                        type: 'ADD_WT_ATTACHEMENTS',
                        photoDamages: damages,
                        photos: photos,
                        photoUnrecognized
                    });
                }
            }
    
            getFiles();
        }
    }, [claim?.case?.files?.photos, setState, blockAdd]);

    return { statusBlackCase };

}
export default useBlackCase;