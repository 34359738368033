import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { useMemo } from "react";

import CustomModal from 'Components/CustomModal/CustomModal';
import { ReactComponent as MailIcon } from 'Assets/mail.svg';
import styles from './ModalSecondaryEmail.module.scss';
import { validField } from "Helpers/validForm";
import { Button, Input } from 'Components';

type MainPropsT = {
    secondaryEmail: string,
    handleNoReferenceNumber: () => void,
    handleConfirmClick: () => void,
    actionChange: (value: string) => void 
}

const ModalSecondaryEmail: React.FC<MainPropsT> = ({ secondaryEmail, handleNoReferenceNumber, handleConfirmClick, actionChange }) => {
    const { t } = useTranslation();
    const valid = useMemo(() => validField('email', secondaryEmail), [secondaryEmail]);

    return (
        <CustomModal open>
            <div className={styles.centerModalText}> {parse(t('createClaim.giveSecondaryEmail'))} </div>
            <Input
                label={t('labels.email')}
                Icon={MailIcon}
                value={secondaryEmail?.replace(' ', '').toLowerCase()}
                onChange={(event) => actionChange(event.target.value.replace(' ', '').toLowerCase())}
                name='secondaryEmail'
                id='input_login_email'
                className={styles.modalInput}
            />
            <div className={styles.modalButtons}>
                <Button onClick={handleNoReferenceNumber} type={'button'} color={'secondary'}>{t('common.cancel')}</Button>
                <Button onClick={handleConfirmClick} type={'button'} color={'primary'} disabled={!valid}>{t('common.confirm')}</Button>
            </div>
        </CustomModal>
    );
};

export default ModalSecondaryEmail;