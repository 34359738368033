import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import type { StateT as UserStateT } from 'Store/modules/user';
import style from './Begin.module.scss';

import { ReactComponent as IconDetails } from 'Assets/add_claim_passenger_details.svg';
import { ReactComponent as IconBaggage } from 'Assets/add_claim_baggage_details.svg';
import { ReactComponent as IconTags } from 'Assets/add_claim_baggage_tags.svg';
import { ReactComponent as IconPhotos } from 'Assets/add_claim_photos.svg';
import useSalutation from "Hooks/useSalutation";
import { userNameJoin } from 'Helpers/strings';
import { StepsE } from "Store/Enums/claim";

type MainProps = {
    user: UserStateT['data'],
    setStepHidden: (step: StepsE, hidden?: boolean) => void,
}

const StepSplashScreen: React.FC<MainProps> = ({ user, setStepHidden }) => {

    const { t } = useTranslation();
    const userTitle = useSalutation(user);

    useEffect(() => {
        setStepHidden(StepsE.WARNING_ATTACHMENTS, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dataBoxes = [
        { id: 'details', title: `1. ${t('createClaim.begin1Label')}`, content: t('createClaim.begin1Content') },
        { id: 'baggage', title: `2. ${t('createClaim.begin2Label')}`, content: t('createClaim.begin2Content') },
        { id: 'tags', title: `3. ${t('createClaim.begin3Label')}`, content: t('createClaim.begin3Content') },
        { id: 'photos', title: `4. ${t('createClaim.begin4Label')}`, content: t('createClaim.begin4Content') },
    ];

    return (
        <div className={style.root}>
            <div className={style.header}>
                {t('createClaim.titleHello')} {' '}
                <strong>{userNameJoin(user, userTitle.salutation, userTitle.title)}</strong>. {' '}
                {t('createClaim.titleContent')}{' '}
                {t('createClaim.startHeader')}
            </div>
            {dataBoxes.map((data) => (
                <BoxBlock
                    key={`box${data.id}`}
                    header={data.title}
                    content={data.content}
                    id={data.id}
                />
            ))}
        </div>
    )
}

export default StepSplashScreen;

type PropsBoxT = {
    header: string,
    content: string,
    id: string
}

const BoxBlock: React.FC<PropsBoxT> = ({ header, content, id }) => {

    return (
        <div className={style.box}>
            <div className={style.ico}>
                <ChooseIco icoType={id} />
            </div>
            <div className={style.boxData}>
                <div className={style.boxHeader}>{header}</div>
                <div className={style.boxContent}>{content}</div>
            </div>
        </div>
    );

}

type ChooseT = {
    icoType: string
}

const ChooseIco: React.FC<ChooseT> = ({ icoType }) => {
    switch(icoType){

        case 'details': return <IconDetails />
        case 'baggage': return <IconBaggage />
        case 'tags': return <IconTags />
        case 'photos': return <IconPhotos />

        default: return <IconPhotos />

    }
}