import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import cx from 'classnames';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from './Carousel.module.scss';
import NoImageSvgPath from 'Assets/no-image.svg';

interface ICarousel {
  images?: string[];
  className?: string;
  imageWrapClassName?: string;
  showArrows?: boolean;
  autoPlay?: boolean;
}

const MyCarousel: React.FC<ICarousel> = ({ images, className, imageWrapClassName, showArrows = true, autoPlay = false }) => {
  const mainImage = images[0]; 
  const reorderedImages = [mainImage, ...images.filter(img => img !== mainImage)];
  return (
    <div className={cx(styles.root, className)}>
      <Carousel showArrows={showArrows} autoPlay={!!autoPlay} dynamicHeight emulateTouch useKeyboardArrows infiniteLoop showStatus={false}> 
        {reorderedImages.map((src, index) => (
          <div className={cx(styles.imageWrapperMain, imageWrapClassName)} key={index}>
            <img
              className={cx(styles.carouselImage, { [styles.fit]: src ? false : true })}
              src={src || NoImageSvgPath}
              alt=""
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default MyCarousel;