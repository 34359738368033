import { LocalStateT } from "./RepairPickUpForm";

export const Store = (state: LocalStateT, action: any): LocalStateT => {

    switch(action.type){

        case 'SET_DATA': return {
            ...state,
            [action.dataType]: action.data
        }

        case 'SET_USER_STATE': return {
            ...state,
            ...action.data
        };

        case 'SET_ADDRESS': return {
            ...state,
            location: action.location
        }

        case 'SET_CONFIG': return {
            ...state,
            config: action.data,
            costExtraChecked: false
        }

        case 'SET_VALIDATE_LOCATION': return {
            ...state,
            isExtraServiceAllowed: action.data.isExtraServiceAllowed,
            isPickupAllowed: action.data.isPickupAllowed
        }

        case 'SET_VALID_COUNTRY': return {
            ...state,
            validCountry: action.data
        }

        default: return state;
    }

}