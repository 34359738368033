import { Button } from "Components";
import React from 'react';

import useTranslate from "Hooks/useTranslate";
import style from './ModalNotAirlineSupported.module.scss';
import CustomModal from "Components/CustomModal/CustomModal";


type MainPropsT = {
    actionClose: () => void,
    actionCloseModalMain: () => void,
    airlineCode: string,
    show?: boolean,
}

const ModalNotAirlineSupported: React.FC<MainPropsT> = ({ actionClose, actionCloseModalMain, airlineCode, show = false }) => {
    const t = useTranslate();

    return (
        <CustomModal 
            open={show}
            title={`${t('createClaim.headerNoAirlineSupport')}${airlineCode ? ' (' + airlineCode + ')' : ''}`} 
            onClose={actionClose} 
            dialogStyle={{color:"#f14915"}}
        >
            <div className={style.root}>
                {t('createClaim.labelNoAirlineSupport')}
            </div>
            <div className={style.buttons}>
                <Button onClick={actionCloseModalMain} type={'button'} color={'primary'} block>{t('createClaim.duplicateModalCloseButton')}</Button>
            </div>
        </CustomModal>
    );
}

export default ModalNotAirlineSupported;