import React, { useCallback, useState } from 'react';
import { useTranslation } from "react-i18next";
import cx from 'classnames';

import ExternalnfoData from "Components/ExternalnfoData/ExternalnfoData";
import { MissingDocExampleT } from "Store/modules/claim";
import style from './InputData.module.scss';
import Ico from 'Components/Ico/Ico';

type MainPropsT = {
    type: string,
    actionChange: (type: string, data: string) => void,
    value: string,
    title: string,
    onFocus?: (evt: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void;
    maxLength?: number,
    success?: boolean,
    exampleData: MissingDocExampleT|null
    id?: string,
    disabled?: boolean,
    notValid?: boolean
}

const InputData: React.FC<MainPropsT> = ({ type, actionChange, value, title, onFocus: propsOnFocus, onBlur: propsOnBlur, maxLength = 40, success, exampleData, id, disabled, notValid }) => {

    const [showExamples, setShowExamples] = useState(false);
    const [inFocus, setInFocus] = useState<boolean>(false);
    const { t } = useTranslation();

    const actionOpenExample = useCallback(() => {
        setShowExamples(old => !old);
    }, []);

    const onFocus = useCallback((evt: React.FocusEvent<HTMLInputElement>) => {
        if (typeof propsOnFocus === 'function') {
            propsOnFocus(evt);
        }
        setInFocus(true);
    }, [propsOnFocus]);
    
    const onBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (typeof propsOnBlur === 'function') {
            propsOnBlur(event);
        }
        setInFocus(false);
    }, [propsOnBlur]);

    return (
        <div className={cx(style.root, { [style.success]: success, [style.withError]: notValid, [style.active]: inFocus, })}>
            <div className={style.title}>
                <span>{title}</span>
                {exampleData &&
                    <div className={style.icoTooltip}>
                        <Ico action={() => actionOpenExample()} />
                    </div>
                }
            </div>
            <input
                value={value}
                maxLength={maxLength}
                onChange={(data) => actionChange(type, data.target.value)}
                onFocus={onFocus}
                onBlur={onBlur}
                id={id}
                disabled={disabled}
            />
            {showExamples &&
                <div className={style.examplesExternalInfo}>
                    <ExternalnfoData
                        t={t}
                        missingType={exampleData.SFTag}
                        missingDocumentsAdditionalInfo={exampleData}
                    />
                </div>
            }
        </div>
    );
}
export default InputData;