import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { codes } from 'country-calling-code';

import { AppStateT, changeModalTooltip, ClaimT, IImage, MissingDocExampleT, PageConfigT, ReportStateT, RequiredDocumentT, resetReportState, setMissingDocumentExamples, StateT, UserStateT } from "../../../DataStructure/interfaces";
import { Button, DropDownSearch, ExternalnfoData, FeedMessageLine, Ico, Input, LoadableContent, PhoneDirect } from "../../../DataStructure/Components";
import { getExamplesMissingDocuments, ReportSendT, sendReportProblem } from "../../../DataStructure/services";
import { getUrlMissingDoc, makeSlug, runNotify, sizeAdapter } from "../../../DataStructure/helpers";
import { ImgRimowa, ImgTumi } from "../../../DataStructure/attachments";
import style from './ReportProblem.module.scss';
import DropImage from "./DropImage/DropImage";
import InputData from "./InputData/InputData";
import Store from "./Store";
import BlockText from "Components/BlockText/BlockText";

export type PhotoT = {
    images: IImage[],
    type: string
}

export type InputT = {
    value: string,
    type: string
}

type ValidationFieldsT = {
    [key: string]: boolean;
}

type ValidationTimersT = {
    [key: string]: NodeJS.Timeout | null;
}

type ValidationStateT = {
    show: ValidationFieldsT,
    timer: ValidationTimersT
}

export type LocalStateT = {
    images: PhotoT[],
    inputs: InputT[],
    valid: boolean,
    sending: boolean,
    validation: ValidationStateT
}

const defaultState: LocalStateT = {
    images: [],
    inputs: [],
    valid: false,
    sending: false,
    validation: {
        show: {},
        timer: {}
    }
}

export const TAG_TO_NAME: { [key in string]: string } = {
    REFERENCE_NO: 'reportTags.referenceNumber',
    FILE_REFERENCE: 'reportTags.referenceNumber',
    TICKET_BOARDING_CARD: 'reportTags.ticketBoardingCard',
    PHOTOS_OF_LUGGAGE: 'reportTags.photosOfLuggage',
    BAGGAGE_TAG: 'reportTags.baggageTag',
    AIRLINE_AUTHORIZATION_LETTER_COPY: 'reportTags.airlinesAuthorizationLetterCopy',
    SIZE: 'reportTags.dimensions',
    MEASUREMENT: 'reportTags.size',
    SERIAL_NO: 'reportTags.serialNumber',
    AIRPORT_DOCUMENT: 'reportTags.missingDocument',
    BAGGAGE_TAG_COPY: 'reportTags.baggageTag',

    PHOTO_OF_THE_LOGO: 'missingDocuments.PhotoOfTheLogo',
    PHOTO_OF_LUGGAGE_TOP: 'reportTags.photTop',
    PHOTO_OF_LUGGAGE_BOTTOM: 'reportTags.photoBottom',
    PHOTO_OF_LUGGAGE_LEFT: 'reportTags.photoLeft',
    PHOTO_OF_LUGGAGE_RIGHT: 'reportTags.photoRight',
    PHOTO_FROM_THE_FRONT: 'reportTags.photoFront',
    PHOTO_OF_THE_DAMAGE: 'reportTags.photoDamage',
    AIRLINE_PROCESSING_NUMBER: 'reportTags.airlineProcessingNumber',// string
    BOARDING_CARD_COPY: 'reportTags.boardingCard',
    BOOKING_CONFIRMATION: 'reportTags.bookingConfirmation',
    DPR_COPY: 'reportTags.dprCopy',
    EMAIL_FROM_THE_AIRLINE: 'reportTags.emailFromAirline',
    R_AND_D_COPY: 'createClaim.missedRndCopy',
    E_TICKET: 'reportTags.eticket',// zdjęcie biletu elektronicznego
    FLIGHT_TICKET_COPY: 'reportTags.flightTicket',
    PO_NUMBER: 'reportTags.poNumber',// numer rezerwacji (po number)// string
    PURCHASE_RECEIPT_COPY: 'reportTags.purchaseReceipt',
    RIMOWA_SERIAL_NUMBER: 'reportTags.rimowaSerialNumber',// string
    TUMI_SERIAL_NUMBER: 'reportTags.tumiSerialNumber',// string
    STAMPED_LEAFLET: 'reportTags.stampedLeaflet',
    WARRANTY_CARD_COPY: 'reportTags.warrantyCard',
    ADDRESS: 'reportTags.adderess',
    PHONE_NUMBER: 'labels.phone',
    PRODUCT: 'createClaim.detBrand',
    MANUFACTURER: 'missingDocuments.Manufacturer',
    TK_RELEASE_FORM_PL: 'missingDocuments.TKReleaseFormPL',
    TK_RELEASE_FORM_DE: 'missingDocuments.TKReleaseFormDE',

    BAGGAGE_TAG_ORIGINAL: 'reportTags.baggageTagOriginal', 
    BOARDING_CARD_ORIGINAL: 'reportTags.boardingCardOriginal',
    DPR_ORIGINAL: 'reportTags.dprOriginal',
    FLIGHT_TICKET_ORIGINAL: 'reportTags.flightTicketOriginal'
};

// R_AND_D_COPY
// RELEASE_AND_DISCHARGE_QR
// TK_RELEASE_FORM_DE
// TK_RELEASE_FORM_PL

const TAG_INPUTS = [ 'SIZE', 'SERIAL_NO', 'REFERENCE_NO', 'RIMOWA_SERIAL_NUMBER', 'TUMI_SERIAL_NUMBER', 'AIRLINE_PROCESSING_NUMBER', 'ADDRESS', 'PHONE_NUMBER', 'MANUFACTURER', 'PRODUCT', 'MEASUREMENT', 'FILE_REFERENCE' ];
export const AIRPORT_DOCUMENT = 'AIRPORT_DOCUMENT';
const rejectedBrands = ['10639', '10711'];
const MIN_INPUT_TAG_CHARS = { SIZE: 1, MANUFACTURER: 2 };
const MIN_INPUT_CHARS = 3;

type MainPropsT = {
    t: (data: string, params?: any) => string,
    claim: ClaimT,
    disabled?: boolean,
    tags: string[],
    reportId: string,
    requiredDocuments: RequiredDocumentT[],
}

const ReportProblem: React.FC<MainPropsT> = ({ claim, t, disabled, tags: tagsProps, reportId, requiredDocuments }) => {

    const user = useSelector<AppStateT, UserStateT['data']>(({ user }) => user.data);
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);

    const [state, setState] = useReducer(Store, defaultState);
    const reportState = useSelector<AppStateT, ReportStateT>(({ claimChat }) => claimChat.report);
    const { missingDocumentExamples } = useSelector<AppStateT, StateT>(({ claim }) => claim);
    const [showModalPhoneDirect, setShowModalPhoneDirect] = useState(false);
    const [missingDocsExamples, setMissingDocsExamples] = useState<MissingDocExampleT[]>([]);
    const tags = useMemo(() => tagsProps, [tagsProps]);

    const getInitialPrefixText = useCallback(() => {
        const code = codes.find(c => c.isoCode2 === user?.location?.countryCode);
        return code ? code.countryCodes[0] : '';
    }, [user?.location?.countryCode]);
    const dispatch = useDispatch();
    const [showExamples, setShowExamples] = useState(false);
    const [prefixText, setPrefixText] = useState(getInitialPrefixText());
    const isMounted = useRef(false);

    const { tumiSerialNumber, rimowaSerialNumber, referenceNumber, serialNumber, airlineProcessingNumber, phone } = pageConfig.inputs;
    const reportSend = reportState.loaded;
    const brands = useMemo(() => pageConfig.claim.brands.map(brand => {
        return { value: brand, text: brand }
    }), [pageConfig.claim.brands]);

    const getInputData = useCallback((tag: string, value: string) => {
        if (tag === 'TUMI_SERIAL_NUMBER') {
            return {
                maxLength: tumiSerialNumber.maxLength,
                success: value?.length === tumiSerialNumber.maxLength && !!value?.match(tumiSerialNumber.regexp),
                errorMsg: 'validation.invalidTumiSerialNumber'
            }
        }
        if (tag === 'RIMOWA_SERIAL_NUMBER') {
            return {
                maxLength: rimowaSerialNumber.maxLength,
                success: value?.length >= rimowaSerialNumber.minLength && value?.length <= rimowaSerialNumber.maxLength && !!value?.match(rimowaSerialNumber.regexp),
                errorMsg: 'validation.invalidRimowaSerialNumber'
            }
        }
        if (tag === 'REFERENCE_NO' || tag === 'FILE_REFERENCE') {
            return {
                maxLength: referenceNumber.maxLength,
                success: value?.length >= referenceNumber.minLength && value?.length <= referenceNumber.maxLength && !!value?.match(referenceNumber.regexp),
                errorMsg: 'validation.invalidRefNumber'
            }
        }
        if (tag === 'SERIAL_NO') {
            return {
                maxLength: serialNumber.maxLength,
                success: value?.length >= serialNumber.minLength && value?.length <= serialNumber.maxLength && !!value?.match(serialNumber.regexp),
                errorMsg: 'validation.invalidSerialNumber'
            }
        }
        if (tag === 'MEASUREMENT') {
            return {
                maxLength: 3,
                success: value?.length > 1 && value?.length <= 3 && !isNaN(+value) && +value >= 10,
                errorMsg: 'validation.invalidSize'
            };
        }
        if (tag === 'AIRLINE_PROCESSING_NUMBER') {
            return {
                maxLength: airlineProcessingNumber.maxLength,
                success: value?.length >= airlineProcessingNumber.minLength && value?.length <= airlineProcessingNumber.maxLength && !!value?.match(airlineProcessingNumber.regexp),
                errorMsg: 'validation.invalidAirlineProcessingNumber'
            }
        }
        if (tag === 'PHONE_NUMBER') {
            return {
                maxLength: phone.maxLength,
                success: prefixText ? ('+' + prefixText + value).match(phone.regexp) && value?.length >= phone.minLength - (prefixText.length + 1) && value?.length <= phone.maxLength - (prefixText.length + 1):
                        value.match(phone.regexp) && value?.length >= phone.minLength && value?.length <= phone.maxLength,
                errorMsg: 'validation.invalidPhoneNumber'
            }
        }
        return {
            maxLength: 40,
            success: value && value?.length > MIN_INPUT_CHARS
        }
    }, [
        airlineProcessingNumber,
        phone,
        referenceNumber,
        rimowaSerialNumber,
        serialNumber,
        tumiSerialNumber,
        prefixText
    ]);

    const toolTipBrand = useCallback((tag: string) => {
        if (tag === 'RIMOWA_SERIAL_NUMBER'){
            dispatch(changeModalTooltip({ 
                title: t('createClaim.detTipSerialNr'), 
                content: t('createClaim.detTipSerialNrRimowaDesc'),
                object: <div className={style.toolTipImage}><img src={ImgRimowa} alt="" /></div>
            }));
        } else if (tag === 'TUMI_SERIAL_NUMBER'){
            dispatch(changeModalTooltip({ 
                title: t('createClaim.detTipSerialNr'), 
                content: t('createClaim.detTipSerialNrDescTumi'),
                object: <div className={style.toolTipImage}><img src={ImgTumi} alt="" /></div>
            }));
        }
    }, [dispatch, t]);

    /*
    * Read example documents,
    * It is hack provide doubled reading by doubled rendered
    * This state value: missingDocsExamples is not nessessary, exist becouse this chat is wrong rendered
    **/
    useEffect(() => {
        if (claim?.id && !claim?.closed) {
            if (!missingDocumentExamples.some(ex => ex.claimId === claim.id)) {
                getExamplesMissingDocuments(claim.id, (result: boolean, data?: MissingDocExampleT[]) => {
                    if (result && data) {
                        setMissingDocsExamples(data);
                        dispatch(setMissingDocumentExamples(claim.id, data));
                    } else {
                        setMissingDocsExamples([]);
                    }
                });
            } else {
                setMissingDocsExamples(missingDocumentExamples.find(ex => ex.claimId === claim.id)?.missingDocExamples || []);
            }
        }
    }, [claim?.id, claim?.closed, dispatch, missingDocumentExamples]);

    useEffect(() => {
        if (reportSend) {
            dispatch(resetReportState());
        }
    }, [dispatch, reportSend]);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        for(let i=0;i<tags.length;i++){
            if (!TAG_INPUTS.includes(tags[i])){
                if (!(state.images?.find(im => im.type === tags[i])?.images?.length > 0)){
                    return setState({ type: 'SET_VALID', data: false });
                }
            } else if (TAG_INPUTS.includes(tags[i])){
                const _tagInput = state.inputs?.find(inp => inp.type === tags[i]);
                if (!_tagInput) {
                    return setState({ type: 'SET_VALID', data: false });
                }
                const inputData = getInputData(_tagInput.type, _tagInput.value);
                if (MIN_INPUT_TAG_CHARS?.[_tagInput.type]){
                    if (MIN_INPUT_TAG_CHARS[_tagInput.type] > _tagInput.value.length){
                        return setState({ type: 'SET_VALID', data: false });
                    }
                } else if (!inputData.success){
                    return setState({ type: 'SET_VALID', data: false });
                }
            }
        }
        return setState({ type: 'SET_VALID', data: true });
    }, [state.images, state.inputs, tags, getInputData]);

    useEffect(() => {
        return () => {
            // Clear all validation timers on unmount
            Object.values(state.validation?.timer || {}).forEach(timer => {
                if (timer) clearTimeout(timer);
            });
        };
    }, [state.validation?.timer]);

    const handleFieldFocus = useCallback((field: string) => {
        if (state.validation?.timer?.[field]) {
            clearTimeout(state.validation.timer[field]!);
        }
    }, [state.validation]);

    const handleFieldBlur = useCallback((field: string) => {
        const timer = setTimeout(() => {
            setState({
                type: 'SET_VALIDATION',
                data: {
                    ...state.validation,
                    show: {
                        ...state.validation.show,
                        [field]: true
                    }
                }
            });
        }, 1000);

        setState({
            type: 'SET_VALIDATION',
            data: {
                ...state.validation,
                timer: {
                    ...state.validation.timer,
                    [field]: timer
                }
            }
        });
    }, [state.validation]);

    const actionChangeInput = useCallback((dataType: string, data: string) => {
        setState({ type: 'SET_INPUTS', data, dataType });
        handleFieldFocus(dataType);
    }, [handleFieldFocus])

    const downloadDocument = useCallback((req: RequiredDocumentT) => {
        const url = getUrlMissingDoc(req.Url__c, user.location.countryCode);
        if (url){
            window.open(url);
        }
    }, [user.location.countryCode])

    const searchTitleFromReq = useCallback((tag: string) => {
        const reqDoc = requiredDocuments?.find(doc => doc?.Url__c && tag.replaceAll('_', '-') === doc.Missing_Document_Type__c);
        if (reqDoc){
            return t('reportTags.missingDocument') + ': ' + reqDoc.Name;
        }
        return tag;
    }, [requiredDocuments, t]);

    const getTitle = useCallback((tag: string) => {
        return TAG_TO_NAME[tag] ? t(TAG_TO_NAME[tag]) : searchTitleFromReq(tag);
    }, [searchTitleFromReq, t]);

    const showExampleData = useMemo(() => (tag: string): MissingDocExampleT|null => {
        return missingDocsExamples.find(m => m.name === tag) || null;
    }, [missingDocsExamples]);

    const ShowComponent = useCallback((tag: string) => {

        const requreDocumentAirport = requiredDocuments?.find(doc => !!doc?.Url__c && doc.Obligatory__c && doc.Missing_Document_Type__c.replaceAll('-', '_') === tag);

        const example = showExampleData(tag);
        if (!TAG_INPUTS.includes(tag)) {
            return (
                <DropImage
                    images={state.images?.find(ph => ph.type === tag)?.images || []}
                    allImages={state.images.reduce((acc: IImage[], photoT: PhotoT) => {
                        return acc.concat(photoT.images);
                    }, [])}
                    type={tag}
                    t={t}
                    setImages={(dataType: string, data: IImage[]) => setState({ type: 'SET_IMAGES', data, dataType })}
                    documentRequire={requreDocumentAirport}
                    downloadDocument={downloadDocument}
                    title={t(TAG_TO_NAME[tag])}
                    id={'chat_report_file_' + makeSlug(tag)}
                    exampleData={example}
                    disabled={disabled}
                />
            )
        } else {
            const value = state.inputs?.find(inp => inp.type === tag)?.value || '';
            const inputData = getInputData(tag, value);
            const isValid = inputData.success;
            const showError = state.validation?.show?.[tag] && !isValid;

            if (tag === 'SIZE'){

                const sizes = Object.entries(pageConfig.claim.sizes).map(size => {
                    return { value: size[0], text: sizeAdapter(size[0], user.location.countryCode, user.language) };
                })

                const actionChangeSize = (data: any) => {
                    setState({ type: 'SET_INPUTS', data: data.value, dataType: 'SIZE' });
                }

                return (
                    <>
                        <div className={style.inputRow}>
                            <div className={style.dropdown}>
                                <DropDownSearch
                                    data={sizes.map(size => {
                                        return {
                                            text: sizeAdapter(size.value, user.location.countryCode, user.language),
                                            value: size.value
                                        };
                                    })}
                                    onChange={actionChangeSize}
                                    label={t(getTitle(tag))}
                                    value={value}
                                    className={style.inputLayer}
                                    success={value.length >= MIN_INPUT_TAG_CHARS[tag]}
                                    id={'chat_report_size_' + makeSlug(tag)}
                                    t={t}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                    </>
                )
            } else if (tag === 'MANUFACTURER') {
                return (
                    <div className={style.inputRow}>
                        <div className={style.dropdown}>
                            <DropDownSearch
                                data={[...brands.filter(brand => brand.value !== 'Other Brand' && !rejectedBrands.includes(brand.value)).sort(), ...brands.filter(brand => brand.value === 'Other Brand')]}
                                onChange={(data) => actionChangeInput(tag, data.value)}
                                label={t(getTitle(tag))}
                                value={state.inputs.find(inp => inp.type === tag)?.value || ''}
                                className={style.inputLayer}
                                success={value.length >= MIN_INPUT_TAG_CHARS[tag]}
                                id={'chat_report_manufacturer_' + makeSlug(tag)}
                                search
                                t={t}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                )
            } else if (tag === 'PHONE_NUMBER') {
                const updatePrefixText = (newPrefix: string) => setPrefixText(newPrefix);
                const actionChangePhoneNumber = (data: any) => {
                    const dataValue = prefixText ? data.target.value.replace(/\D/g, '') : data.target.value;
                    setState({ type: 'SET_INPUTS', data: dataValue, dataType: 'PHONE_NUMBER' });
                }

                return (
                    <>
                        <Input
                            value={value}
                            onChange={actionChangePhoneNumber}
                            label={getTitle(tag)}
                            className={style.inputLayer}
                            alwaysSmallLabel
                            maxLength={getInputData(tag, value).maxLength}
                            success={getInputData(tag, value).success}
                            prefixText={'+' + prefixText}
                            onPrefixClick={() => setShowModalPhoneDirect(true)}
                            notValid={showError}
                            onFocus={() => handleFieldFocus(tag)}
                            onBlur={() => handleFieldBlur(tag)}
                            id={'chat_report_phone_number_' + makeSlug(tag)}
                            disabled={disabled}
                        />
                        {showModalPhoneDirect &&
                            <PhoneDirect
                                actionChange={(value) => {
                                    updatePrefixText(value);
                                    setShowModalPhoneDirect(false);
                                }}
                                actionClose={() => setShowModalPhoneDirect(false)}
                                value={prefixText}
                                t={t}
                            />
                        }
                        {showError && (
                            <div className={style.inputErrorLabel}>
                                {t('validation.invalidPhoneNumber')}
                            </div>
                        )}
                    </>
                );
            } else if (tag === 'MEASUREMENT') {
                const actionChangeMeasurement = (data: any) => {
                    setState({ type: 'SET_INPUTS', data: data.target.value.replace(/\D/g, '').replace(/^0+/, ''), dataType: 'MEASUREMENT' });
                }
                return (
                    <>
                        <div className={style.inputRow}>
                            <div className={style.inputContainer}>
                                <Input
                                    label={getTitle(tag)}
                                    value={value}
                                    onChange={actionChangeMeasurement}
                                    className={style.inputLayer}
                                    alwaysSmallLabel
                                    rightModeData={t('common.cm')}
                                    success={getInputData(tag, value).success}
                                    maxLength={getInputData(tag, value).maxLength}
                                    notValid={showError}
                                    onBlur={() => handleFieldBlur(tag)}
                                    onFocus={() => handleFieldFocus(tag)}
                                    id={'chat_report_measurement_' + makeSlug(tag)}
                                    disabled={disabled}
                                />
                                {showError && (
                                    <div className={style.inputErrorLabel}>
                                        {t('validation.invalidSize')}
                                    </div>
                                )}
                            </div>
                            {example &&
                                <div className={style.icoInfo}>
                                    <span className={style.headerIco}><Ico action={() => setShowExamples(old => !old)} /></span>
                                </div>
                            }
                        </div>
                        
                        {showExamples &&
                            <ExternalnfoData
                                t={t}
                                missingType={example.SFTag}
                                missingDocumentsAdditionalInfo={example}
                            />
                        }
                    </>
                )
            }
            return (
                <div className={style.inputRow}>
                    <div className={style.inputContainer}>
                        <InputData
                            actionChange={actionChangeInput}
                            value={value}
                            type={tag}
                            title={getTitle(tag)}
                            maxLength={getInputData(tag, value).maxLength}
                            success={getInputData(tag, value).success}
                            notValid={showError}
                            onBlur={() => handleFieldBlur(tag)}
                            onFocus={() => handleFieldFocus(tag)}
                            exampleData={example}
                            id={'chat_report_input_' + makeSlug(tag)}
                            disabled={disabled}
                        />
                        {showError && (
                            <div className={style.inputErrorLabel}>
                                {t(getInputData(tag, value).errorMsg || '')}
                            </div>
                        )}
                    </div>
                    {(tag === 'RIMOWA_SERIAL_NUMBER' || tag === 'TUMI_SERIAL_NUMBER') &&
                        <div className={style.icoInfo}>
                            <span className={style.headerIco}><Ico action={() => toolTipBrand(tag)} /></span>
                        </div>
                    }
                </div>
            )
        }
    }, [downloadDocument, getTitle, brands, requiredDocuments, state.images, state.inputs, t, pageConfig.claim.sizes, user.location.countryCode, user.language, toolTipBrand, getInputData, showModalPhoneDirect, prefixText, showExampleData, showExamples, disabled, actionChangeInput, handleFieldBlur, handleFieldFocus, state.validation?.show]);

    const actionSubmit = useCallback((event: React.FormEvent) => {
        event.preventDefault();
        if (disabled) return;

        setState({ type: 'SET_SENDING', data: true });
        const dataSend: ReportSendT[] = [];
        const dataAirlineDocuments = [];
        tags.forEach(data => {
            if (!TAG_INPUTS.includes(data)){
                const documentFile = requiredDocuments?.find(doc => doc.Missing_Document_Type__c === data.replaceAll('_', '-') && doc.Url__c);
                let dataFiles = null;
                dataFiles = state.images.filter(im => im.type === data).reduce((acc: IImage[], photoT: PhotoT) => {
                    return acc.concat(photoT.images);
                }, []);
                if (documentFile){
                    dataFiles.forEach((d: any) => {
                        dataAirlineDocuments.push(d);
                    });
                } else {
                    // dataSend[data] = dataFiles;
                    dataSend.push({
                        tagType: data,
                        files: dataFiles
                    });
                }
            } else {
                const input = state.inputs?.find(inp => inp.type === data);
                if (input) {
                    let value = '';
                    if (input.type === 'PHONE_NUMBER') {
                        // dataSend[data] = `+${prefixText}${input.value}`;
                        value = `+${prefixText}${input.value}`;
                    } else if (input.type === 'MEASUREMENT') {
                        // dataSend[data] = `${input.value}cm`
                        value = `${input.value}cm`
                    } else {
                        // dataSend[data] = input.value;
                        value = input.value;
                    }
                    dataSend.push({
                        tagType: data,
                        tagValue: value
                    });
                }
            }
        });
        if (dataAirlineDocuments.length > 0){
            // dataSend[AIRPORT_DOCUMENT] = dataAirlineDocuments;
            dataSend.push({ tagType: AIRPORT_DOCUMENT, tagValue: dataAirlineDocuments });
        }
        // dispatch(sendReportRequest({ report, data: dataSend }));

        sendReportProblem(claim.id, reportId, dataSend, (result) => {
            setState({ type: 'SET_SENDING', data: false });
            if (!result){
                runNotify({ message: t('common.formSendError'), type: 'error' });
            }
        });

    }, [tags, state.images, state.inputs, requiredDocuments, prefixText, disabled, claim.id, t, reportId]);

    return (
        <div className={style.root}>
            <FeedMessageLine
                loading={state.sending}
                data={(
                    <>
                        {tags.length > 0 && (
                            <LoadableContent loading={reportState.loading}>
                                <form onSubmit={actionSubmit}>
                                    <div className={style.layerChatMain}>
                                        {t('report.reportMessage3')}
                                        {tags.length > 0 && (
                                            <ol>
                                                {tags.map(tag => (
                                                    <li key={`li-${tag}`}>{getTitle(tag)}</li>
                                                ))}
                                            </ol>
                                        )}
                                    </div>

                                    {tags.filter(data => !TAG_INPUTS.includes(data)).length > 0 &&
                                        <BlockText content={t('reportProblem.infoImagesWarning')} type={'error'} fontBigger italic />
                                    }

                                    {tags.map(tag => (
                                            <React.Fragment key={`tag-${tag}`}>{ShowComponent(tag)}</React.Fragment>
                                        ))
                                    }
                                    <Button className={style.buttonSend} type="submit" disabled={!state.valid}>
                                        {t('report.send')}
                                    </Button>
                                </form>
                            </LoadableContent>
                        )}
                    </>
                )}
            />
        </div>
    );
}
export default ReportProblem;