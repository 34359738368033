import CloseIcon from '@rsuite/icons/Close';
import cx from 'classnames';
import React from 'react';

import style from './Block.module.scss';
import BlockText from "Components/BlockText/BlockText";

type MainPropsT = {
    type?: 'info'|'success'|'error'|'flat',
    content: string,
    className?: string,
    center?: boolean,
    header?: string,
    valueTop?: string,
    actionClose?: () => void,
    showFromBottom?: boolean,
    fontBigger?: boolean,
    onlyText?: boolean,
    italic?: boolean
}

const Block: React.FC<MainPropsT> = ({ type = 'info', content, className, center, header, valueTop, actionClose, showFromBottom, fontBigger, onlyText, italic }) => {
    return (
        <div
            className={
                cx(
                    style.root,
                    {
                        [style.center]: !!center,
                        [style.error]: type === 'error',
                        [style.success]: type === 'success',
                        [style.flat]: type === 'flat',
                        [style.bigger]: !!fontBigger,
                        [style.fromBottom]: !!showFromBottom,
                        [style.onlyText]: !!onlyText,
                    },
                    className
                )
            }
        >
            {valueTop &&
                <div className={style.valueTop}><span>{valueTop}</span></div>
            }
            {!!actionClose &&
                <CloseIcon className={style.icon} onClick={actionClose} />
            }
            {header &&
                <div className={style.header}>{header}</div>
            }
            <div className={style.content}>
                <BlockText
                    content={content}
                    type={type}
                    center={center}
                    fontBigger={!!fontBigger}
                    italic={italic}
                />
            </div>
        </div>
    );
}
export default Block;