import { setOpenModalRedirectStore, setWaitingShopResult } from "Store/modules/page";
import runApi, { ApiSendMethods, ResultApiTypeT } from "api/base/runApi";
import { CatalogsE } from "Store/Enums/urlsCatalogs";
import { CartDataSendT } from "Helpers/useDataCart";
import { LuggageT } from "api/modules/store";

export const clearSensitiveDataCart = (dataCart: CartDataSendT): CartDataSendT => {

    return {
        ...dataCart,
        delivery: {
            ...dataCart.delivery,
            payment: {
                ...dataCart.delivery.payment,
                creditCard: {
                    ...dataCart.delivery.payment.creditCard,
                    cardNumber: '',
                    cvv: '',
                    exp: '',
                }
            }
        }
    }

}

export const filterDataStore = (data: LuggageT, isKRStoreView: boolean): boolean => {

    // remove empty product without color
    if (data.extension_attributes.simple_products.filter(ps => !!ps?.color?.swatch_value).length === 0){
        return false;
    }
    // remove no Material product
    if (data.extension_attributes.custom_options.filter(co => co.attribute_code === 'material').length === 0 ){
        return false;
    }

    return (
            isKRStoreView ? +data?.extension_attributes?.simple_products[0]?.base_price : +data?.extension_attributes?.simple_products[0]?.price
           ) > 0 && data.extension_attributes.simple_products.some(si => si.color?.type === 1);
}

export type ReturnShopUrlTokenT = {
    url: string,
    token: string
}

export const getUrlShopToken = async (catalog: CatalogsE, claimId: string, dispatch: (data: any) => void, callBack?: (result: boolean, data?: ReturnShopUrlTokenT, errorMessage?: string) => void) => {
    
    localStorage.setItem(`claim-redirect-shop-${claimId}`, catalog);
    dispatch(setOpenModalRedirectStore(true));
    runApi(`claim/${claimId}/catalog-credentials/${catalog}`, {}, (r: ResultApiTypeT) => {
        dispatch(setOpenModalRedirectStore(false));
        if (r.result){
            localStorageWaitingShop(claimId).create();
            dispatch(setWaitingShopResult(true));
            if (r.data?.url && (r.data.url.indexOf('https://') >=0 || r.data.url.indexOf('http://') >=0)){
                window.location.href = r.data.url; 
            }
            return callBack?.(r.result, r?.data);
        }
        callBack?.(false, undefined, r.error?.message);
    }, ApiSendMethods.get);
}

export const localStorageWaitingShop = (claimId: string) => {
    const name = `shop-waiting-${claimId}`;
    const maxLiveSeconds = 60 * 60;

    const remove = () => localStorage.removeItem(name);
    const create = () => localStorage.setItem(name, (new Date()).toISOString());
    const get = () => localStorage.getItem(name);
    const exist = () => {
        const result = localStorage.getItem(name);
        return !!result;
    }

    return {
        remove,
        create,
        exist,
        get,
        maxLiveSeconds
    }
}