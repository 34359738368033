import { StateT as UserStateT } from 'Store/modules/user';
import { UserT } from "api/modules/user";

export const capitalizeFirstLetter = (word: string): string => {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export const generateId = (length = 20) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const makeSlug = (data: string): string => {
    return data
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');
}

export const escapeSelector = (str: string): string => {
    return str
        .replace(/'/g, "\\'")
        .replace(/\s+/g, '-')
        .replace(/&/g, '\\&');
};

export const removeSpaces = (input: string) => {
    return input.replace(/\s/g, '')
}

export const userNameJoin = (user: UserT | UserStateT | null, userSlutation: string, userTitle?: string|null|undefined): string => {
    return `${userSlutation}${(userTitle && userTitle.indexOf('NONE') < 0) ? ` ${userTitle}` : ''} ${user?.firstName || ''}${user?.lastName ? (user?.firstName ? ' ' : '') + user?.lastName : ''}`;

}

export const getPropablyCountryNameByCode = (code: string) => {
    switch (code.toLowerCase()){
        case 'pl': return 'Poland';
        case 'de': return 'Germany';
        case 'es': return 'Spain';
        case 'ch': return 'Switzerland';
        default: return code || 'de';
    }
}

export const setLuggageTypeTitleLang = (slug: string): string => {

    switch(slug){
        case 'special-luggage': return 'createClaim.typeSpecial';
        case 'backpack': return 'createClaim.typeBackpack';
        case 'business': return 'createClaim.typeBusiness';
        case 'sport-luggage': return 'createClaim.typeSport';
        case 'baby-stroller': case 'baby-equipment': return 'createClaim.typeStroller';
        case 'music': return 'createClaim.typeMusic';
        case 'medical': return 'createClaim.typeMedical';
        case 'reisetasche': case 'travel-bag': return 'createClaim.typeReisetasche';
        case 'animal-cage': case 'animal cages': return 'createClaim.typeAnimal';

        default: return 'createClaim.typeLuggage';
    }
};

export const setMaterialTitleLang = (slug: string): string => {
    switch(slug){
        case 'hybrid': return 'createClaim.materialHybrid';
        case 'aluminum': return 'createClaim.materialAluminium';
        case 'leather': return 'createClaim.materialLeather';
        case 'hardshell': return 'createClaim.materialHardshell';

        default: return 'createClaim.materialSoftMaterial';
    }
}

export const setColorTranslateName = (name: string): string => {
    switch(name.toLowerCase()){
        case 'black': return 'createClaim.colorBlack';
        case 'white': return 'createClaim.colorWhite';
        case 'grey': return 'createClaim.colorGrey';
        case 'blue': return 'createClaim.colorBlue';
        case 'red': return 'createClaim.colorRed';
        case 'violet': return 'createClaim.colorViolet';

        default: return name;
    }
}

export const setCategoryTranslateName = (name: string): string => {
    switch(name.toLowerCase()){
        case '4 wheels': return 'shop.category4Wheels';
        case 'luggage': return 'shop.categoryLuggage';
        case 'hardshell suitcase': return 'shop.categoryHardShell';

        default: return name;
    }
}

export const setMaterialTranslateName = (name: string): string => {
    switch(name.trim().toLowerCase()){
        case 'hardside polypropylen': return 'shop.materialHardsidePolypropylene';
        case 'hardside abs': return 'shop.materialHardsideABS';

        default: return name;
    }
}
export const formatInput = (input: string): string => {
    if (typeof input !== 'string') {
        return input;
    }
    return input
        .split(/(\s+|-\s*)/) 
        .map(part => {
            if (part.trim() === '') {
                return part;
            }
            return part
                .split(/(\s+)/) 
                .map(word =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(''); 
        })
        .join(''); 
}

export const capitalizeString = (str: string, allWords = false): string => {
    if (typeof str !== 'string') {
      return str;
    }
  
    if (allWords) {
      return str
        .split(' ')
        .map(word => word.trim().charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
  
    return str.trim().charAt(0).toUpperCase() + str.slice(1);
}

export const toPascalCase = (str: string): string => {
    return str
        .split(/[\W_]+/)
        .map(word =>
            word.charAt(0).toUpperCase() +
            word.slice(1).toLowerCase()
        )
        .join('');
}

export const toPascalCaseUpper = (str: string): string => {
    return str
        .split(/[\W_]+/)
        .map(word => {
            if (word.length === 0) return '';
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join('');
}

export const isHtml = (text: string) => {
    return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(text);
}

export const isOnlyNumbersInString = (str: string): boolean => {
    return /^[0-9]+$/.test(str);
}

export const fixDocumentName = (type: string, t: (data: string) => string): string => {
    let phrase = 'missingDocuments.' + toPascalCase(type);
    let prismicData = t(phrase);
    if (phrase !== prismicData) return prismicData;
    phrase = 'missingDocuments.' + toPascalCaseUpper(type);
    prismicData = t(phrase);
    if (phrase !== prismicData) return prismicData;
    return type;
};

export const getTranslationOrFallback = (t: (data: string) => string, key: string, fallback: string) => {
    const translated = t(key);
    return translated === key ? fallback : translated;
};