import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import cx from 'classnames';

import { ReactComponent as CalendarIcon } from 'Assets/calendar.svg';
import ImgRimowa from 'Assets/new_claim/serial_number_rimowa.png';
import ImgTumi from 'Assets/new_claim/serial_number_tumi.png';

import { formatDateMonth, getDaysAgo, isCurrentMonth } from 'Helpers/date';
import DropDownSearch from 'Components/DropDownSearch/DropDownSearch';
import { PageConfigT, changeModalTooltip } from 'Store/modules/page';
import { type StateT as UserStateT } from 'Store/modules/user';
import { useDeviceType } from 'Helpers/responsiveContainers';
import { capitalizeFirstLetter } from 'Helpers/strings';
import BlockText from "Components/BlockText/BlockText";
import { countriesCodes } from 'Helpers/languageCode';
import { ValidationFieldsT } from '../../CreateForm';
import { StateLocalT } from '../../CreateForm';
import { defaultLanguage } from "Helpers/i18";
import { Input, Switcher } from 'Components';
import { sizeAdapter } from 'Helpers/claim';
import Block from "Components/Block/Block";
import { AppStateT } from 'Store/modules';
import style from './Details.module.scss';
import Ico from 'Components/Ico/Ico';

type MainProps = {
    state: StateLocalT,
    setValidStep: (result: boolean) => void,
    updateClaim: (dataType: string, data: any) => void,
    updateState: (dataType: string, data: any) => void
}

export type SizesShowT = 'height'|'length'|'capacity'|'full'|'functions'|null;

type BrandT = {
    value: string,
    text: string,
    disabled?: boolean|undefined
}

const requireSerialNumberBrands = ['Rimowa', 'Tumi'];
const rejectedBrands = ['10639', '10711'];
const sizeRequiredLanguages = [ 'cs-cz', 'cz' ];

const Details: React.FC<MainProps> = ({ state, setValidStep, updateClaim, updateState }) => {

    const user = useSelector<AppStateT, UserStateT['data']>(({ user }) => user.data);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const [sizesShow, setSizesShow] = useState<SizesShowT>(null);
    const language = useSelector<AppStateT, string>((state) => {
        const languageCode = countriesCodes.find((code) => state.i18n.language === code.countryCode)
        return languageCode?.localeCode || defaultLanguage
    });
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { details } = state.claimData;
    const { isMobile } = useDeviceType();
    const [brands, setBrands] = useState(state.brands.map(br => {
        return { value: br, text: capitalizeFirstLetter(br === 'Other Brand' ? t('createClaim.otherBrand') : br) };
    }));
    const [sizes] = useState(Object.entries(pageConfig.claim.sizes).map(size => {
        return { value: size[0], text: size[0] };
    }));
    const { tumiSerialNumber, rimowaSerialNumber } = pageConfig.inputs;
    const serialNumber = details.serialNumber;
    const detailsBrand = details.brand;
    const modelInput = details.model;

    useEffect(() => {
        setBrands(state.brands.map(br => {
            return { value: br, text: capitalizeFirstLetter(br === 'Other Brand' ? t('createClaim.otherBrand') : br) };
        }));
    }, [state.brands, t]);

    const validTumiSerialNumber = useMemo(() => (): boolean => {
        return capitalizeFirstLetter(detailsBrand) === 'Tumi' && serialNumber.length === tumiSerialNumber.maxLength && !!serialNumber.match(tumiSerialNumber.regexp)
    }, [serialNumber, tumiSerialNumber.regexp, tumiSerialNumber.maxLength, detailsBrand])

    const validRimowaSerialNumber = useMemo(() => (): boolean => {
        return capitalizeFirstLetter(detailsBrand) === 'Rimowa' && serialNumber.length >= rimowaSerialNumber.minLength && serialNumber.length <= rimowaSerialNumber.maxLength && !!serialNumber.match(rimowaSerialNumber.regexp)
    }, [serialNumber, rimowaSerialNumber.regexp, rimowaSerialNumber.minLength, rimowaSerialNumber.maxLength, detailsBrand])

    const validLuggageModel = useMemo(() => (model: string): boolean => {
        if (model === '') {
            return true;
        }

        if (model.length === 1) {
            return /^[a-zA-Z0-9]$/.test(model);
        }

        const pattern = /^[a-zA-Z0-9][a-zA-Z0-9\s-]*[a-zA-Z0-9]$/;
        return pattern.test(model) && model.length <= 50;
    }, [])

    const getValidationMessage = (fieldName: 'model' | 'serialNumber') => {
        const brand = capitalizeFirstLetter(detailsBrand);

        if (fieldName === 'serialNumber') {
            if (brand === 'Tumi' && state.validation.show.serialNumber && !validTumiSerialNumber()) {
                return t('validation.invalidTumiSerialNumber');
            }
            if (brand === 'Rimowa' && state.validation.show.serialNumber && !validRimowaSerialNumber()) {
                return t('validation.invalidRimowaSerialNumber');
            }
        }
        
        if (fieldName === 'model') {
            if (state.validation.show.model && !validLuggageModel(modelInput)) {
                return t('validation.detailsModel');
            }
        }

        return '';
    };

    const handleFieldFocus = (field: keyof ValidationFieldsT) => {
        if (state.validation.timer[field]) {
            clearTimeout(state.validation.timer[field]!);
        }
    };
    
    const handleFieldBlur = (field: keyof ValidationFieldsT) => {
        const timer = setTimeout(() => {
            updateState('validation', {
                ...state.validation,
                show: {
                    ...state.validation.show,
                    [field]: true
                }
            });
        }, 1000);
    
        updateState('validation', {
            ...state.validation,
            timer: {
                ...state.validation.timer,
                [field]: timer
            }
        });
    };

    useEffect(() => {
        let valid = true;
        if (
            (capitalizeFirstLetter(detailsBrand) === 'Rimowa' && !validRimowaSerialNumber()) ||
            (capitalizeFirstLetter(detailsBrand) === 'Tumi' && !validTumiSerialNumber()) ||
            !brands.some(b => b.value.toLowerCase() === detailsBrand.toLowerCase()) || 
            !state.claimData.details.date ||
            (sizeRequiredLanguages.includes(user.location.countryCode.toLowerCase()) && !state.claimData.details.size) ||
            (state.claimData.details.costKnow && (isNaN(+state.claimData.details.cost) ? 0 : +state.claimData.details.cost) <=0.9) ||
            !validLuggageModel(modelInput)
        ) {
            valid = false;
        }

        setValidStep(valid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sizesShow,
        detailsBrand,
        serialNumber,
        modelInput,
        state.requiredMissingDocuments,
        state.claimData.details.date,
        brands,
        state.claimData.details.cost,
        state.claimData.details.costKnow,
        user.location.countryCode,
        state.claimData.details.size
    ]);

    useEffect(() => {
        switch(state.claimData.luggageType){
            case 'Sport luggage': case "Sport":  case 'Music': return setSizesShow('functions');
            case 'Luggage': return setSizesShow('height');
            case 'Reisetasche': case 'Special luggage': return setSizesShow('length');
            case 'Backpack': return setSizesShow('capacity');
            case 'Baby stroller': case 'stroller': return setSizesShow(null);
            default: return setSizesShow('full');
        }
    }, [state.claimData.luggageType]);

    const actionChangeBrand = useCallback((brand: BrandT) => {
        const updatedDetails = {
            ...state.claimData.details,
            brand: capitalizeFirstLetter(brand.value),
            serialNumber: ''
        }

        const updatedValidation = {
            show: {
                ...state.validation.show,
                serialNumber: false
            },
            timer: {
                ...state.validation.timer,
                serialNumber: null
            }
        }

        updateClaim('details', updatedDetails);
        updateState('validation', updatedValidation);
    }, [state.claimData.details, state.validation, updateClaim, updateState])

    const actionChangeModel = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        updateClaim('details', {
            ...state.claimData.details,
            model: event.target.value
        });
    }, [state.claimData.details, updateClaim]);

    const actionChangeSerialNumber = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        updateClaim('details', {
            ...state.claimData.details,
            serialNumber: event.target.value
        });
    }, [state.claimData.details, updateClaim])

    const onChangeCalendar = useCallback((date: any) => {
        setShowDatePicker(false);
        if (date && !isCurrentMonth(date)){
            date.setDate(16);
        } else if (date){
            date.setDate(2);
        }
        updateClaim('details', {
            ...state.claimData.details,
            date
        });
    }, [state.claimData.details, updateClaim])

    const actionChangeCost = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        let cost = event.target.value.trim().replace(',', '.');
        const dataExp = cost.split('.');
        if (dataExp[1] && dataExp[1].length > 2){
            cost = `${dataExp[0]}.${dataExp[1].slice(0,2)}`
        }
        updateClaim('details', {
            ...state.claimData.details,
            cost: cost.replace(/[^0-9 .]/g, '')
        });
    }, [state.claimData.details, updateClaim]);

    const actionChangeCurrency = useCallback((data: string) => {
        updateClaim('details', {
            ...state.claimData.details,
            currency: data
        });
    }, [state.claimData.details, updateClaim])

    const toolTipBrand = (brand: string) => {
        if (brand === 'rimowa'){
            dispatch(changeModalTooltip({ 
                title: t('createClaim.detTipSerialNr'), 
                content: t('createClaim.detTipSerialNrRimowaDesc'),
                object: <div className={style.toolTipImage}><img src={ImgRimowa} alt="" /></div>
            }));
        } else if (brand === 'tumi'){
            dispatch(changeModalTooltip({ 
                title: t('createClaim.detTipSerialNr'), 
                content: t('createClaim.detTipSerialNrDescTumi'),
                object: <div className={style.toolTipImage}><img src={ImgTumi} alt="" /></div>
            }));
        }
    }

    const setSwitchCost = () => {
        updateClaim('details', {
            ...state.claimData.details,
            costKnow: !state.claimData.details.costKnow
        });
    };

    const actionChangeSize = (value: any) => {
        updateClaim('details', {
            ...state.claimData.details,
            size: value.value
        });
    }

    return (
        <div className={style.root}>
            {showDatePicker && <div className={style.maskDatePicker} onClick={() => setShowDatePicker(false)} />}

            <DropDownSearch
                data={[...brands.filter(brand => brand.value !== 'Other Brand' && !rejectedBrands.includes(brand.value)).sort(), ...brands.filter(brand => brand.value === 'Other Brand')]}
                onChange={actionChangeBrand}
                label={t('createClaim.detBrand')}
                value={detailsBrand === 'Other Brand' ? t('createClaim.otherBrand') : detailsBrand}
                className={style.inputLayer}
                search
                t={t}
                id={'search_new_claim_brand'}
            />
            <BlockText className={style.labelFieldAbove} content={t('common.requiredField')} type={!brands.some(brand => brand.value.toLowerCase() === detailsBrand.toLowerCase()) ? 'error' : brands.some(brand => brand.value === detailsBrand) ? 'success' : 'flat'} />
            <Input
                label={t('createClaim.detModel')}
                value={modelInput}
                onChange={(ev) => actionChangeModel(ev)}
                className={cx(style.inputLayer, {[style.error]: !!getValidationMessage('model')})}
                id={'input_new_claim_model'}
                notValid={!!getValidationMessage('model')}
                maxLength={50}
                onFocus={() => handleFieldFocus('model')}
                onBlur={() => handleFieldBlur('model')}
            />
            <BlockText className={style.labelFieldAbove} content={t('common.fieldOptional')} type={'flat'} />
            {(requireSerialNumberBrands.length > 0 && requireSerialNumberBrands.includes(capitalizeFirstLetter(detailsBrand))) &&
                <>
                    <div className={cx(style.serialNumber, { [style.serialNumberRows]: capitalizeFirstLetter(detailsBrand) === 'Rimowa' || capitalizeFirstLetter(detailsBrand) === 'Tumi'})}>
                        <div className={style.serialNumberContainer}>
                            <Input
                                label={t('createClaim.detSerialNumber')}
                                value={serialNumber}
                                onChange={(ev) => actionChangeSerialNumber(ev)}
                                className={cx(style.inputLayer, {
                                    [style.error]: (
                                        capitalizeFirstLetter(detailsBrand) === 'Tumi' 
                                            ? (state.validation.show.serialNumber && !validTumiSerialNumber()) 
                                            : (state.validation.show.serialNumber && !validRimowaSerialNumber())
                                    )
                                })}
                                id={'input_new_claim_serial_number'}
                                notValid={!!getValidationMessage('serialNumber')}
                                maxLength={capitalizeFirstLetter(detailsBrand) === 'Tumi' ? pageConfig.inputs.tumiSerialNumber.maxLength : pageConfig.inputs.rimowaSerialNumber.maxLength}
                                onFocus={() => handleFieldFocus('serialNumber')}
                                onBlur={() => handleFieldBlur('serialNumber')}
                            />
                            <div className={cx(style.labelFieldAbove, { [style.error]: !validRimowaSerialNumber() || !validTumiSerialNumber(),
                                [style.good]: validRimowaSerialNumber() || validTumiSerialNumber() })}>{getValidationMessage('serialNumber') || t('common.requiredField')}
                            </div>
                        </div>
                        <div className={style.icoInfo}>
                            <span className={style.headerIco}><Ico action={() => toolTipBrand(detailsBrand.toLowerCase())} /></span>
                        </div>
                    </div>
                    <BlockText
                        className={style.labelFieldAbove}
                        content={t('common.requiredField')}
                        type={
                            capitalizeFirstLetter(detailsBrand) === 'Rimowa' ? !validRimowaSerialNumber() ? 'error' : 'success' : !validTumiSerialNumber() ? 'error' : 'success'
                        }
                    />
                </>
            }

            <DropDownSearch
                data={sizes.map(size => {
                    return {
                        text: sizeAdapter(size.value, user.location.countryCode, user.language),
                        value: size.value
                    };
                })}
                onChange={actionChangeSize}
                label={t('shop.size')}
                value={sizeAdapter(state.claimData.details.size, user.location.countryCode, user.language)}
                className={style.inputLayer}
                t={t}
                hasCrossIcon={!!state.claimData.details.size}
                // sizeRequiredLanguages.includes(user.location.countryCode)
                id={'search_new_claim_size'}
            />
            <BlockText
                content={sizeRequiredLanguages.includes(user.location.countryCode.toLowerCase()) ? t('common.requiredField') : t('common.fieldOptional')}
                className={style.labelFieldAbove}
                type={sizeRequiredLanguages.includes(user.location.countryCode.toLowerCase()) && !state.claimData.details.size ? 'error' : !sizeRequiredLanguages.includes(user.location.countryCode.toLowerCase()) && !!state.claimData.details.size ? 'success' : 'flat'}
            />

            <div className={style.calendarLayer} id="calendarLayer" onClick={!showDatePicker ? () => setShowDatePicker(old => !old) : undefined}>
                {showDatePicker &&
                    <div className={style.layerClickCalendar}>
                        <Calendar
                            value={state.claimData.details.date}
                            onChange={onChangeCalendar}
                            locale={language || 'en'}
                            maxDate={new Date()}
                            view={'year'}
                            onClickMonth={onChangeCalendar}
                            minDate={getDaysAgo(365 * 30)}
                        />
                    </div>
                }
                <Input
                    label={t('createClaim.detDate')}
                    value={state.claimData.details.date ? formatDateMonth(state.claimData.details.date) : ''}
                    disabled={showDatePicker}
                    id={'input_new_claim_date'}
                    className={style.calendarInput}
                    onChange={value => null}
                />
                <CalendarIcon className={style.calendar} />
                <BlockText
                    className={cx(style.labelFieldAbove, style.labelFieldAboveDate)}
                    content={t('common.requiredField')}
                    type={!state.claimData.details.date ? 'error' : state.claimData.details.date ? 'success' : 'flat'}
                />
            </div>
            
            <Block content={t('createClaim.infoAmountBuggage')} showFromBottom className={style.infoAmount} />
            <div className={style.costLayer}>
                <div className={style.costKnow}>
                    <div className={style.costTitle}>
                        {state.claimData.details.costKnow ? t('createClaim.specifyValueKnow') : t('createClaim.specifyValueDontKnow')}
                    </div>
                    <Switcher
                        name='sww'
                        value={state.claimData.details.costKnow}
                        onChange={setSwitchCost}
                    />
                </div>
                {state.claimData.details.costKnow &&
                    <div className={style.costInput}>
                        <Input
                            label={t('createClaim.detCost')}
                            value={state.claimData.details.cost > 0 ? String(state.claimData.details.cost) : ''}
                            onChange={actionChangeCost}
                            className={style.inputLayer}
                            inputMode={'numeric'}
                            inputModeNoNumber
                            alwaysSmallLabel={!!isMobile}
                            rightModeData={state.branchCurrenciesByLocation.map((c: any) => {
                                return { value: c, label: c };
                            })}
                            rightModeAction={actionChangeCurrency}
                            rightModeValue={state.claimData.details.currency}
                            maxLength={10}
                            id={'input_new_claim_cost'}
                        />
                    </div>
                }
            </div>
            <BlockText
                className={style.labelFieldAbove}
                content={t('createClaim.specifyValue')}
                type={state.claimData.details.costKnow && state.claimData.details.cost <=0 ? 'error' : 'success'}
            />
        </div>
    );
}
export default Details;
