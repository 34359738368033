import { MissingDocExampleT as MissingDocExampleMainT, setMissingDocumentExamples as setMissingDocumentExamplesMain, StateT as StateMainT } from "Store/modules/claim";
import { RequiredDocumentT as RequiredDocumentMainT } from "PageComponents/CreateClaim/CreateClaim/CreateForm/CreateForm";
import { StateT as ReportStateMainT, resetReportState as resetReportStateMain } from 'Store/modules/claimChat/report';
import { FeedMessageLineDataT as FeedMessageLineDataMainT } from "Components/FeedMessageLine/FeedMessageLine";
import { IImage as IImageMain } from "PageComponents/Chat/ReportUploadBox/ReportUploadBox";
import {
    ClaimT as ClaimMainT,
    VerdictT as VerdictMainT,
    ShipmentT as ShipmentMainT,
    OrderT as OrderMainT,
    CaseT as CaseMainT,
    OrderProductT as OrderProductMainT,
    OrderStatusE as OrderStatusMainE
} from "api/modules/claim";
import { ClaimChatStateT as ClaimChatStateMainT } from "Store/modules/claimChat";
import { changeModalTooltip as changeModalTooltipMain } from "Store/modules/page";
import { PaymentStatusesE as PaymentStatusesMainE } from "Store/Enums/Payment";
import { setShowContact as setShowContactMain } from "Store/modules/page";
import { CLAIM_STATUSES as CLAIM_STATUSES_MAIN } from "Config/statuses";
import { PageConfigT as PageConfigMainT } from "Store/modules/page";
import type { StateT as UserStateMainT } from 'Store/modules/user';
import { AppStateT as AppStateMainT } from "Store/modules";
import { PaymentsE as PaymentsMainE } from "Store/Types/order";
import { IImageT as IImageMainT } from "Store/Types/claim";

export enum ClaimProgressStageE {
    REGISTERED = 'REGISTERED',
    ASSESSMENT_PENDING = 'ASSESSMENT_PENDING',
    BLOCKED = 'BLOCKED',
    CLOSED = 'CLOSED',

    ASSESSMENT_EXPERT_EVALUATION_IN_PROCESS = 'ASSESSMENT_EXPERT_EVALUATION_IN_PROCESS',
    AUTOMATIC_VERDICT_STARTED = 'AUTOMATIC_VERDICT_STARTED',

    ASSESSMENT_VERDICT_PICKUP = 'ASSESSMENT_VERDICT_PICKUP',
    ASSESSMENT_VERDICT_ACCESSS_TO_SHOP = 'ASSESSMENT_VERDICT_ACCESSS_TO_SHOP',
    ASSESSMENT_VERDICT_MONEY_COMPENSATION_BY_DOLFI = 'ASSESSMENT_VERDICT_MONEY_COMPENSATION_BY_DOLFI',
    ASSESSMENT_VERDICT_MONEY_COMPENSATION_BY_AIRLINE = 'ASSESSMENT_VERDICT_MONEY_COMPENSATION_BY_AIRLINE',
    ASSESSMENT_VERDICT_ONE_TO_ONE_REPLACEMENT = 'ASSESSMENT_VERDICT_ONE_TO_ONE_REPLACEMENT',

    VALIDATION_PAX_MISSING_ORIGINAL_DOCUMENTS = 'VALIDATION_PAX_MISSING_ORIGINAL_DOCUMENTS',
    VALIDATION_PAX_PROBLEM_REPORTED = 'VALIDATION_PAX_PROBLEM_REPORTED',
    VALIDATION_PAX_PROBLEM_ADDRESSED = 'VALIDATION_PAX_PROBLEM_ADDRESSED',

    REPAIR_BUDGET_ACTION_REQUIRED_PAX_DATA = 'REPAIR_BUDGET_ACTION_REQUIRED_PAX_DATA',
    REPAIR_BUDGET_ACTION_COMPLETED_PAX_DATA = 'REPAIR_BUDGET_ACTION_COMPLETED_PAX_DATA',
    REPAIR_BUDGET_ACTION_REJECTED_PAX_DATA = 'REPAIR_BUDGET_ACTION_REJECTED_PAX_DATA',

    SHOP_ACTION_REQUIRED_SELECT_ITEMS = 'SHOP_ACTION_REQUIRED_SELECT_ITEMS',
    SHOP_ORDER_CONFIRMED = 'SHOP_ORDER_CONFIRMED',

    PICKUP_ACTION_COMPLETED_PAX_DATA = 'PICKUP_ACTION_COMPLETED_PAX_DATA',
    PICKUP_ACTION_REQUIRED_PAX_DATA = 'PICKUP_ACTION_REQUIRED_PAX_DATA',
    PICKUP_AWAITING_SPARE_PARTS = 'PICKUP_AWAITING_SPARE_PARTS',
    PICKUP_COLLECTED_FROM_PAX = 'PICKUP_COLLECTED_FROM_PAX',
    PICKUP_EVALUATION_BY_CUSTOMER_SERVICE = 'PICKUP_EVALUATION_BY_CUSTOMER_SERVICE',
    PICKUP_PROCESS_BY_PARTNER = 'PICKUP_PROCESS_BY_PARTNER',
    PICKUP_REPAIR_COMPLETED = 'PICKUP_REPAIR_COMPLETED',
    PICKUP_REPAIR_COMPLETED_BY_EXTERNAL_PARTNER = 'PICKUP_REPAIR_COMPLETED_BY_EXTERNAL_PARTNER',
    PICKUP_REPAIR_IN_PROGRESS = 'PICKUP_REPAIR_IN_PROGRESS',
    PICKUP_SHIPMENT = 'PICKUP_SHIPMENT',
    PICKUP_SHIPMENT_TRACKING = 'PICKUP_SHIPMENT_TRACKING',

    SHIPMENT_READY_FOR_DISPATCH = 'SHIPMENT_READY_FOR_DISPATCH',
    SHIPMENT_IN_PROGRESS = 'SHIPMENT_IN_PROGRESS',

    MONEY_COMPENSATION_ACTION_REQUIRED_PAX_ACCOUNT_DATA = 'MONEY_COMPENSATION_ACTION_REQUIRED_PAX_ACCOUNT_DATA',
    MONEY_COMPENSATION_REFUND_IN_PROGRESS = 'MONEY_COMPENSATION_REFUND_IN_PROGRESS',

    AWAITING_PROGRESS = 'AWAITING_PROGRESS',

    BLACKCASE_AWAITING_PAX_COMPLETION = 'BLACKCASE_AWAITING_PAX_COMPLETION',
    DOWNLOADED_FROM_SF = 'DOWNLOADED_FROM_SF',
    BLACKCASE_RETURNED_TO_SF = 'BLACKCASE_RETURNED_TO_SF',
}

export interface IClaimFeedEntry {
    createdAt: string;
    id: string;
    isHidden?: boolean;
    isDisabled?: boolean;
    stage: ClaimProgressStageE
    readAt?: string,
    validUntil?: string,
    values?: any
}

export interface IClaimFeed {
    claimId: string;
    createdAt?: string;
    entries: IClaimFeedEntry[];
    id: string;
    unreadEntries: number,
    updatedAt?: string;
    userId: string;
}

export type FeedMessageLineDataT = FeedMessageLineDataMainT;

export type ClaimT = ClaimMainT;

export const CLAIM_STATUSES = CLAIM_STATUSES_MAIN;
export type VerdictT = VerdictMainT;

export type AppStateT = AppStateMainT;

export type PageConfigT = PageConfigMainT;

export type ClaimChatStateT = ClaimChatStateMainT;
export type UserStateT = UserStateMainT;

export type IImageT = IImageMainT;
export type ShipmentT = ShipmentMainT;
export const setShowContact = setShowContactMain;
export type CaseT = CaseMainT;
export type RequiredDocumentT = RequiredDocumentMainT;
export type ReportStateT = ReportStateMainT;
export const resetReportState = resetReportStateMain;
export type IImage = IImageMain;
export const changeModalTooltip = changeModalTooltipMain;
export type MissingDocExampleT = MissingDocExampleMainT;
export const setMissingDocumentExamples = setMissingDocumentExamplesMain;
export type StateT = StateMainT;
export type OrderT = OrderMainT;
export type OrderProductT = OrderProductMainT;
export const OrderStatusE = OrderStatusMainE;
export const PaymentStatusesE = PaymentStatusesMainE;
export const PaymentsE = PaymentsMainE;
