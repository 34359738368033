import { BrowserRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { Provider } from 'react-redux';
import { CustomProvider } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'react-toastify/dist/ReactToastify.css';
import { getEnvName } from 'Helpers/env';
import App from 'Router/Router';
import './styles/index.scss';
import './configure-api';
import store from 'Store';
import i18n from './i18n';

const envName = getEnvName();

const container = document.getElementById('root');
const root = createRoot(container!);

window.addEventListener('load', () => {
    i18n().initialize(store);
    const AppWithTranslations = withTranslation()(App);

    if (envName) {
        Sentry.init({
            dsn: "https://758c9ac0152446f1a04ddb7c001f0a02@o4504281603702784.ingest.sentry.io/4504315279704064",
            environment: envName,
            transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
            enabled: true,
            // debug: true
        });
    }

    root.render(
        <CustomProvider>
            <Provider store={store}>
                <BrowserRouter future={{ v7_startTransition: true }}>
                    <AppWithTranslations useSuspense={false} />
                </BrowserRouter>
            </Provider>
        </CustomProvider>
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register();
});

// OFB 61
