export enum CatalogsE {
    MAGENTO = 'MAGENTO',
    QSC = 'QSC',
    QCC = 'QCC',
    ENTER_AIR = 'ENTER_AIR',
    LCC = 'LCC',
    ONE_TO_ONE_REPLACEMENT = 'ONE_TO_ONE_REPLACEMENT',
    MONEY_COMPENSATION = 'MONEY_COMPENSATION',
    SPARE_PARTS_RESOLUTION = 'SPARE_PARTS_RESOLUTION',
    SPARE_PARTS_PAX_VALIDATION = 'SPARE_PARTS_PAX_VALIDATION',
}