import { useTranslation } from "react-i18next";
import React, { useState } from 'react';
import parse from 'html-react-parser';
import cx from 'classnames';

import { ReactComponent as BotIco } from 'Assets/chat_bot_small.svg';
import { ReactComponent as InfoIco } from 'Assets/exclamation.svg';

import { DataCheckChatStatusT } from 'Hooks/useStatusHasContent';
import { statusesExtendToShowImmediately } from "Helpers/rules";
import { ChatMessageGroup } from "Components";
import ChatMessage from "Components/ChatMessage/ChatMessage";
import useStatusHasContent from "Hooks/useStatusHasContent";
import style from './MessageChatMain.module.scss';
import { ShipmentT } from "api/modules/claim";
import CustomModal from "Components/CustomModal/CustomModal";


export type DataPropsT = {
    voucher: number,
    residualValue: number,
    templateValue: number,
    baggageAge: string,
    currencyIsoCode: string,
    airlineName: string,
    urlForm: string,
    urlTrustPilot: string,
    urlGoogleReview: string,
    pickupData: string,
}

type MainPropsT = {
    status: string,
    data: DataCheckChatStatusT,
    shipments: ShipmentT[]|undefined|null
}

const MessageChatMain: React.FC<MainPropsT> = ({ status, data, shipments }) => {

    const { t } = useTranslation();
    const { hasContent, content: contentExtend, header: contentMain, hasHeader } = useStatusHasContent(status, shipments, data);

    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            {hasHeader &&
                <>
                    <ChatMessageGroup
                        direction="incoming"
                        data={[
                            {
                                render: () => (
                                    <div className={cx(style.main)}>
                                        <div className={style.content}>
                                            {contentMain}
                                            {hasContent &&
                                                <>
                                                    
                                                    {statusesExtendToShowImmediately.includes(status as any) ?
                                                        <div className={style.extendContent}>
                                                            {parse(contentExtend)}
                                                        </div>:
                                                        <div className={style.icoInfo} onClick={() => setOpenModal(true)} title={t('chatMessages.readMore')}><InfoIco /></div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                ),
                                className: hasContent ? style.renderClass : null
                            },
                        ]}
                        avatar="bot"
                    />
                    {hasContent &&<>
                        <CustomModal open={openModal} title= {contentMain} onClose={() => setOpenModal(false)}>     
                            <div className={style.bodyModal}>
                                    <div className={style.ico}>
                                        <BotIco />
                                    </div>
                                    <div className={style.modalContent}>
                                        {parse(contentExtend)}
                                    </div>
                            </div>
                        </CustomModal>
                        </>
                    }
                </>
            }
            {(data?.resolution?.resolutionData?.location?.address) &&
                <ChatMessage data={<div>{t('chatMessages.resolutionAddress')}<div><strong>{`${data?.resolution?.resolutionData?.location?.formattedAddress}`}</strong></div></div>} />
            }
        </>
    );
}
export default MessageChatMain;