import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

import { ReactComponent as Repairable } from '../CaseDetailsDesktop/Assets/RepairableIcon.svg';
import { ReactComponent as BrokenHeart } from '../CaseDetailsDesktop/Assets/BrokenHeart.svg';
import { ReactComponent as LuggageIcon } from '../CaseDetailsDesktop/Assets/Luggage.svg';
import { ReactComponent as SaveIcon } from '../CaseDetailsDesktop/Assets/SaveIcon.svg';

import { ClaimHistoryT } from '../../../api/modules/case-resolution';
import { makeSlug, setLuggageTypeTitleLang } from 'Helpers/strings';
import { getUrlOfImage, getUrlOfImages } from "services/api";
import useFormattedPrice from 'Hooks/useFormattedPrice';
import styles from './CaseDetailsDesktop.module.scss';
import { ClaimFileSourceE } from "Store/Enums/claim";
import { AppStateT } from '../../../Store/modules';
import { Carousel, CaseStatus } from 'Components';
import { formatDateWithTime } from 'Helpers/date';
import { runNotify } from "Helpers/notifications";
import { PageConfigT } from 'Store/modules/page';
import Loading from "Components/Loading/Loading";
import type { ClaimT } from 'api/modules/claim';
import CustomModal from 'Components/CustomModal/CustomModal';

type Props = {
  data: ClaimT | null;
  onClose: () => void;
  claimHistory: Array<ClaimHistoryT> | null;
};

function CaseDetailsDesktop({ data, onClose, claimHistory }: Props) {
  const { t } = useTranslation();
  const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
  const formatPrice = useFormattedPrice(data.luggage.currencyIsoCode, pageConfig.noFractionCurrencies);
  const [photoKeys, setPhotoKeys] = useState<{ key: string, url: string }[]>([]);
  const [loadingDocumentKey, setLoadingDocumentKey] = useState<string>('');
  const [isValidPdfUrl, setIsValidPdfUrl] = useState<boolean>(false);

  useEffect(() => {
      try{
        const photos = !!data && data.case?.files?.photos ? data.case.files.photos.map(cf => cf?.source !== ClaimFileSourceE.SALESFORCE && cf?.source !== ClaimFileSourceE.ASSESSOR && (cf?.thumbnail?.key || cf?.original?.key)) : [];
        getUrlOfImages(photos, (result, dataResult) => result ? setPhotoKeys(dataResult) : setPhotoKeys([]));
      } catch(error){
        console.warn('Error while get images: ', error);
      }
  }, [data]);

  useEffect(() => {
    if (data?.pdfRaport && data.pdfRaport.key) {
      getUrlOfImage(data.pdfRaport.key, (result: boolean, url?: string) => {
        if (result && url) {
          setIsValidPdfUrl(true);
        } else {
          setIsValidPdfUrl(false);
          console.warn('Error while trying to get PDF url');
        }
      });
    } else {
      setIsValidPdfUrl(false);
      console.warn('PDF key is missing or invalid');
    }
}, [data?.pdfRaport]);

  const media = useMemo(() => photoKeys.map(pk => pk.url), [photoKeys]);

  const translateType = useCallback(() => {
    const slug = makeSlug(data.luggage.type);

    return t(setLuggageTypeTitleLang(slug));
  }, [t, data.luggage.type])

  const downloadInfo = useCallback(() => {

    setLoadingDocumentKey(data?.pdfRaport?.key);
    getUrlOfImage(data?.pdfRaport?.key, (result: boolean, url: string|undefined) => {
      setLoadingDocumentKey('');
      if (result && url){
        window.open(url, '_blank', 'noopener noreferrer');
      } else {
        runNotify({ message: t('caseDetails.errorReportFileNotFound'), type: 'error' });
      }
    });
  }, [data?.pdfRaport, t]);

  const lastTruthyVerdict = useMemo(() => {
    let lastTruthy = undefined;
    if (!claimHistory) {
      return lastTruthy;
    }
    for (let i = claimHistory.length - 1; i >= 0; i -= 1) {
      const currHistPoint = claimHistory[i];
      if (currHistPoint.verdict) {
        lastTruthy = currHistPoint.verdict as any;
        break;
      }
    }
    return lastTruthy;
  }, [claimHistory]);

  // const buggageTypeVerdict = (name: string): string => t(`caseTypes.${name === 'Baby-strollers' ? 'kids' : name.toLowerCase()}`);

  return (
    <CustomModal 
      size='lg'
      open 
      onClose={onClose} 
      title={t('caseDetails.modalTitle')} 
      dialogStyle={{height: 'calc(100vh - 50px)'}}
      objectData={
        <div>
          {/* <SendIcon /> */}
          {isValidPdfUrl && (
            <>
              {loadingDocumentKey === data?.pdfRaport?.key ?
                <div style={{ top: 10, position: 'relative' }}>
                  <Loading size={40} />
                </div>:
                <SaveIcon onClick={downloadInfo} />
              }
            </>
          )}
          {/* <WarningIcon onClick={() => setShowReportProblem(true)} /> */}
        </div>
      }
    >
      <>
        <div className={styles.textContainer}>
          <div className={styles.referenceNumberDesktop}>{data?.referenceNumber}</div>
          <div className={styles.textBlack}>{data?.caseNumber}</div>
        </div>
        <div className={styles.textContainer}>
          <div className={styles.textGray}>
            {data ? formatDateWithTime(new Date(data?.isArchived && data?.claimCreatedAt ? data.claimCreatedAt : data.createdAt)) : null}
          </div>
          <div className={styles.textGray}>
            {data?.location.city}, {data?.location.country}
          </div>
        </div>

        {media && (
          <Carousel
            images={media}
            className={styles.carousel}
            imageWrapClassName={styles.carouselItem}
          />
        )}

        <div className={styles.luggageInfoDesktop}>
          <div className={styles.infoItem}>
            {lastTruthyVerdict?.verdictType === 'irreparable' ? (
              <BrokenHeart className={styles.irrepairableIcon} />
            ) : (
              <Repairable className={styles.repairableIcon}/>
            )}
            {lastTruthyVerdict?.verdictType
              ? t(`verdictTypes.${lastTruthyVerdict?.verdictType}`)
              : 'N/A'}
          </div>
          <div className={styles.infoItem}>
            <LuggageIcon className={styles.luggageIcon}/>{' '}
            <div className={styles.infoItemPrice}>
              {lastTruthyVerdict?.resolution?.voucher
                ? parse(t('common.valueWithCurrency', {
                    currency: `<span class="price">${data.currencyIsoCode}</span>`,
                    value: `<span class="price">${formatPrice(lastTruthyVerdict?.resolution?.voucher || 0)}</span>`,
                  }))
                : 'N/A'}
            </div>
          </div>
          <div className={styles.infoItem}>
            {translateType()}
          </div>
        </div>
        <div className={styles.notesAndStatusContainer}>
            <div className={styles.caseStatus}>
              <CaseStatus />
            </div>
        </div>
      </>  
    </CustomModal>
  );
}

export default CaseDetailsDesktop;
