import CONFIG from "Config";
import axios from "axios";

export type AddressT = {
    address: string;
    zipCode: string;
    country: string;
    countryCode: string;
    city: string;
    route: string;
    streetNumber: string;
};

export type GoogleAddressT = {
    address: string;
    zipCode: string;
    countryName: string;
    countryCode: string;
    city: string;
};

const FIELD_TYPES: { [key: string]: string } = {
    street_number: 'streetNumber',
    route: 'route',
    postal_code: 'zipCode',
    country: 'country',
    locality: 'city',
};

function validateAddressFields(address: any) {
    for (const addressChunk of Object.values(FIELD_TYPES)) {
        if (!address[addressChunk]) {
            return false;
        }
    }

    return true;
}

export function getAddress(data: any[]): AddressT | null {
    const address = data.reduce((acc, item) => {
        // Find corresponding item type
        for (const type of item.types) {
            // @ts-ignore
            if (FIELD_TYPES[type]) {
                // @ts-ignore
                acc[FIELD_TYPES[type]] = item.long_name || item.short_name;

                if (FIELD_TYPES[type] === 'country') {
                    acc.countryCode = item.short_name;
                }
            }
        }

        return acc;
    }, {} as any);
    const valid = validateAddressFields(address);

    if (!valid) {
        return null;
    }

    return {
        ...address,
        address: `${address.route}, ${address.streetNumber}`,
    };
}

export const checkValidByGoogle = async (fullAddress: GoogleAddressT) => {
    try{
        const fullAddressString = `${fullAddress.address}, ${fullAddress.zipCode}, ${fullAddress.city}`
        const result = await axios.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + fullAddressString + '&language=en&key=' + CONFIG.GOOGLE_API.KEY);
        const addresses: RessultAddressByLocT[] = result.data.results.map(address => ({
            fullAddress: address.formatted_address,
            streetName: address.address_components.find(data => data.types.includes("route"))?.long_name || null,
            streetNumber: address.address_components.find(data => data.types.includes("street_number"))?.long_name ||
                          address.address_components.find(data => data.types.includes("premise"))?.long_name || null,
            postalCode: address.address_components.find(data => data.types.includes("postal_code"))?.long_name || null,
            city: address.address_components.find(data => data.types.includes("locality") && data.types.includes("political"))?.long_name ||
                  address.address_components.find(data => data.types.includes("postal_town"))?.long_name || null,
            subCity: address.address_components.find(data => data.types.includes("sublocality") && data.types.includes("political"))?.long_name ||
                     address.address_components.find(data => data.types.includes("neighborhood"))?.long_name || null,
            countryName: address.address_components.find(data => data.types.includes("country") && data.types.includes("political"))?.long_name || null,
            countryCode: address.address_components.find(data => data.types.includes("country") && data.types.includes("political"))?.short_name || null,
            province: address.address_components.find(data => data.types.includes("administrative_area_level_1") && data.types.includes("political"))?.long_name || null,
        }));
        const isAddressCorrect = addresses.some(a => (
            a.postalCode === fullAddress.zipCode &&
            (a.city === fullAddress.city || a.province === fullAddress.city || a.subCity === fullAddress.city) &&
            a.countryCode === fullAddress.countryCode &&
            `${a.streetName || a.city} ${a.streetNumber}` === fullAddress.address
        ));
        return !!(result?.data?.results) && result.data.results.length > 0 && result.data.results.some((r: any) => r.geometry?.location_type && !!(r.geometry.location_type === 'RANGE_INTERPOLATED' || r.geometry.location_type === 'ROOFTOP')) && isAddressCorrect;
    } catch(error){
        console.error('Error Api Google: ', error);
    }
}

export const getLocationByAddress = (address: GoogleAddressT, callBack: (results: { lat: number, lng: number }[] | null, response?: any) => void) => {
    const addressString = `${address.address}, ${address.city}`;
    axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${addressString}&components=postal_code:${address.zipCode}&language=en&key=${CONFIG.GOOGLE_API.KEY}`)
        .then(result => {
            if (result.data.status === 'OK' && result.data?.results && Array.isArray(result.data.results) && result.data.results.length > 0 &&
                result.data.results.some((r: any) => r.geometry?.location_type && !!(r.geometry.location_type === 'RANGE_INTERPOLATED' || r.geometry.location_type === 'ROOFTOP'))) {
                const locations = result.data.results.map((result: any) => result.geometry.location);
                callBack(locations);
            } else {
                const addressString2 = `${address.address}, ${address.city}`;
                axios.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + addressString2 + '&language=en&key=' + CONFIG.GOOGLE_API.KEY)
                    .then(result2 => {
                        if (result2.data.status === 'OK' && result2.data?.results && Array.isArray(result2.data.results) && result2.data.results.length > 0 &&
                            result2.data.results.some((r: any) => r.geometry?.location_type && !!(r.geometry.location_type === 'RANGE_INTERPOLATED' || r.geometry.location_type === 'ROOFTOP'))) {
                            const locations2 = result2.data.results.map((result: any) => result.geometry.location);
                            callBack(locations2);
                        } else {
                            axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address.address}&components=postal_code:${address.zipCode}&language=en&key=${CONFIG.GOOGLE_API.KEY}`)
                                .then(result3 => {
                                    if (result3.data.status === 'OK' && result3.data?.results && Array.isArray(result3.data.results) && result3.data.results.length > 0 &&
                                        result3.data.results.some((r: any) => r.geometry?.location_type && !!(r.geometry.location_type === 'RANGE_INTERPOLATED' || r.geometry.location_type === 'ROOFTOP'))) {
                                        const locations3 = result3.data.results.map((result: any) => result.geometry.location);
                                        callBack(locations3);
                                    } else {
                                        callBack(null);
                                    }
                                }).catch(error3 => {
                                    callBack(null);
                                    console.log('error reading map (addressString3)...: ', error3);
                                });
                        }
                    }).catch(error2 => {
                        callBack(null);
                        console.log('error reading map (addressString2)...: ', error2);
                    });
            }
        }).catch(error => {
            callBack(null);
            console.log('error reading map (addressString)...: ', error);
        });
}

export type RessultAddressByLocT = {
    fullAdress: string,
    streetName: string,
    streetNumber: string,
    postalCode: string,
    city: string,
    subCity: string,
    countryName: string,
    countryCode: string,
    province: string,
}

export const getAddressByLoc = (lat: number, lng: number, callBack: (results: RessultAddressByLocT[]) => void) => {
    axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&location_type=ROOFTOP|RANGE_INTERPOLATED&language=en&key=${CONFIG.GOOGLE_API.KEY}`)
    .then(result => {
        if (result.data.status === 'OK'){
            const addresses: RessultAddressByLocT[] = result.data.results.map(address => ({
                fullAdress: address.formatted_address,
                streetName: address.address_components.find(data => data.types.includes("route"))?.long_name || null,
                streetNumber: address.address_components.find(data => data.types.includes("street_number"))?.long_name ||
                              address.address_components.find(data => data.types.includes("premise"))?.long_name || null,
                postalCode: address.address_components.find(data => data.types.includes("postal_code"))?.long_name || null,
                city: address.address_components.find(data => data.types.includes("locality") && data.types.includes("political"))?.long_name ||
                      address.address_components.find(data => data.types.includes("postal_town"))?.long_name || null,
                subCity: address.address_components.find(data => data.types.includes("sublocality") && data.types.includes("political"))?.long_name ||
                         address.address_components.find(data => data.types.includes("neighborhood"))?.long_name || null,
                countryName: address.address_components.find(data => data.types.includes("country") && data.types.includes("political"))?.long_name || null,
                countryCode: address.address_components.find(data => data.types.includes("country") && data.types.includes("political"))?.short_name || null,
                province: address.address_components.find(data => data.types.includes("administrative_area_level_1") && data.types.includes("political"))?.long_name || null,
            }));

            const uniqueAddresses = Array.from(new Set(addresses.filter(address => address.postalCode).map(a => JSON.stringify(a)))).map(str => JSON.parse(str, (_, value) => {
                if (value === null || value === undefined) {
                    return "";
                }
                return value;
            }) as RessultAddressByLocT);
            callBack(uniqueAddresses);
        } else {
            callBack([]);
        }
    })
    .catch(error => {
        callBack([]);
        console.log('error reading map ...: ', error);
    });
}