import { ReactNode, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Modal, ModalProps } from "rsuite";
import { AppStateT } from "Store/modules";
import { setModalZIndex, incrementModalCount, decrementModalCount} from "Store/modules/page";
import CloseIcon from '@rsuite/icons/Close'; 
import styles from './CustomModal.module.scss'
import cx from 'classnames';

interface CustomModalProps extends ModalProps{
    open: boolean;
    onClose?: () => void;
    hideHeader?: boolean;
    title?: string | React.ReactNode;
    input?:React.ReactNode;
    objectData?: JSX.Element;
    children?: ReactNode;
    footer?: ReactNode;
    dialogStyle?: React.CSSProperties;
  }

  const CustomModal: React.FC<CustomModalProps> = ({
    open,
    onClose,
    hideHeader,
    title,
    input,
    objectData,
    children,
    footer,
    size,
    dialogStyle,
    ...rest
  }) => {
    const dispatch = useDispatch();
    const zIndex = useSelector<AppStateT, number>((state) => state.page.zIndex);
    const modalCount = useSelector<AppStateT, number>((state) => state.page.modalCount);
    const showHeader = useMemo(()=> !!onClose || !!title || !!objectData,[onClose, title, objectData]);
  
    useEffect(() => {
      if (open) {
        dispatch(incrementModalCount());
        dispatch(setModalZIndex(modalCount + 1));
      }
      return () => {
        if (open) {
          dispatch(decrementModalCount());
        }
      };
    }, [open, dispatch, modalCount]);
  
    return (
      <Modal open={open} onClose={onClose} backdropStyle={{ zIndex }} size={size} style={{ zIndex }} dialogStyle={dialogStyle} {...rest}>
        {showHeader && (
            <Modal.Header className={cx(styles.mainRow, {[styles.mainRowHidden] : !!hideHeader})} closeButton={false}>
              {objectData ? <>{objectData}</> : <div className={styles.dataObject}> </div>}
              {title ? <div className={styles.title}>{title}</div> : input?<div className={styles.input}>{input}</div>:<div className={styles.title}></div>}
              {onClose && 
                <div>
                  <IconButton
                      className={styles.circleButton}
                      icon={<CloseIcon />}
                      onClick={onClose}
                      appearance="subtle"
                    />
                </div>
              }
            </Modal.Header>
        )} 
        <Modal.Body className={cx(styles.bodyModal,{[styles.bodyAddClaim]:!!hideHeader})}>{children}</Modal.Body>
        <Modal.Footer>{footer}</Modal.Footer>
      </Modal>
    );
  };
  
  export default CustomModal;
