import axios from "axios";

import { fetchClaimListSuccess, fetchMissongDocs, MissingDocExampleT } from "Store/modules/claim";
import runApi, { ApiSendMethods, ResultApiTypeT, url } from "api/base/runApi";
import { ClaimT, IClaimFeed } from "api/modules/claim";
import { setClaimArchived } from "Store/modules/page";
import { IImageT } from "Store/Types/claim";
import { sendSentryError } from "./errors";
import api from "api/base";

export const getUrlOfImageAsync = async (key: string): Promise<any> => {
    if (!key) return false;

    const headers = {
        'Content-Type': 'application/json',
        Authorization: ''
    };

    const token = sessionStorage.getItem('token');

    if (token !== '' && token !== null) {
        headers.Authorization = 'Bearer ' + token;
    }

    try {
        const result = await axios({
            method: 'GET',
            url: url + `media/file/${encodeURIComponent(key)}`,
            headers
        });

        return result;
    } catch (error) {
        // throw error;
        console.warn('Error remove draft: ', error);
        return error;
    }
}

export const getUrlOfImage = (key: string, callBack: (result: boolean, url?: string) => void) => {
    if (!key) return callBack(false);
    runApi(`media/file/${encodeURIComponent(key)}`, {}, (r: ResultApiTypeT) => {
        if (r.result) {
            callBack(true, r.data.url);
        } else {
            callBack(false);
        }
    }, ApiSendMethods.get);
}

export const getUrlOfImageSync = async (key: string) => {
    return await api(`media/file/${encodeURIComponent(key)}`, {}, ApiSendMethods.get);
}

export const getUrlOfImages = (keys: string[], callBack: (result: boolean, data?: { key: string, url: string }[]) => void) => {
    if (keys.length === 0) return callBack(false);
    runApi(`media/files/get`, keys.filter(k => !!k), (r: ResultApiTypeT) => {
        if (r.result) {
            callBack(true, r.data);
        } else {
            callBack(false);
        }
    }, ApiSendMethods.post);
}

export const getClaims = (isArchived: boolean, userId: string, dispatch: (data: any) => void, callBack?: (result: boolean, data: ClaimT[]) => void) => {

    const removeDuplicateCases = (data: ClaimT[]) => {
        const uniqueCases = {};
        const result = [];
        
        for (const item of data) {
            if (!uniqueCases[item.caseNumber]) {
                uniqueCases[item.caseNumber] = true;
                result.push(item);
            }
        }
        
        return result;
    }

    runApi(`${isArchived ? 'archive/' : ''}claim`, {}, (r: ResultApiTypeT) => {
        if (r.result) {
            const claimsCorrect = removeDuplicateCases(r.data.filter((data: ClaimT) => data.userId === userId));
            const claimsIncorrect = r.data.filter((data: ClaimT) => data.userId !== userId);
            dispatch(fetchClaimListSuccess(claimsCorrect));
            dispatch(setClaimArchived(isArchived));
            if (claimsIncorrect.length > 0){
                sendSentryError(`Wrong user claims [User id: ${userId}]`, claimsIncorrect, 'error');
            }
        } else {
            dispatch(fetchClaimListSuccess([]));
        }
        dispatch(fetchMissongDocs());
        callBack?.(r.result, r?.data || []);
    }, ApiSendMethods.get);
}

export const getClaimFeed = (claimId: string, callBack: (result: boolean, data: IClaimFeed) => void) => {
    runApi(`claim/${claimId}/feed/read`, {}, (r: ResultApiTypeT) => {
        callBack(r.result, r.data);
    }, ApiSendMethods.get);
}

export const getExamplesMissingDocuments = (claimId: string, callBack: (result: boolean, data?: MissingDocExampleT[]) => void) => {
    runApi(`claim/${claimId}/missing-documents`, {}, (r: ResultApiTypeT) => {
        if (r.result) {
            callBack(true, r.data);
        } else {
            callBack(false);
        }
    }, ApiSendMethods.get);
}

export type LoginDataT = {
    message: string,
    verificationCode: string
}

export const loginUser = (email: string, callBack: (result: boolean, data?: LoginDataT) => void) => {
    runApi(`user/login`, { email }, (r: ResultApiTypeT) => {
        callBack?.(r.result, r?.data);
    }, ApiSendMethods.post);
}

export type ReportSendT = {
    files?: IImageT[],
    tagType: string,
    tagValue?: any
}

export const sendReportProblem = async (claimId: string, reportId: string, data: ReportSendT[], callBack?: (result: boolean, data?: any) => void): Promise<ReportT | undefined | null> => {

    try {
        const formData = new FormData();
        data.forEach(d => {
            if (d.files && Array.isArray(d.files) && d.files.length > 0) {
                d.files.forEach(d1 => {
                    formData.append(d.tagType, d1.file);
                });
            } else if (d.tagValue) {
                formData.append(d.tagType, d.tagValue);
            }
        });

        const token = sessionStorage.getItem('token');

        const response = await axios.post(
            `${url}claim/${claimId}/report/${reportId}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token,
                },
                withCredentials: true
            },
        );

        callBack?.(!!response, response);

        return response.data;
    } catch (error) {
        callBack?.(false);
        console.error('Błąd podczas wysyłania dokumentu:', error);
        throw error;
    }

}

type DocumentUploadParamsT = {
    missingDocumentName: string;
    files: any;
    images: File[];
}

export const getImageOcrData = async ({ missingDocumentName, files, images }: DocumentUploadParamsT): Promise<any> => {

    try {
        const formData = new FormData();
        images.forEach(image => {
            formData.append('attachments', image);
        });

        formData.append('missingDocumentName', missingDocumentName);
        formData.append('files', JSON.stringify(files));

        const token = sessionStorage.getItem('token');

        const response = await axios.post(
            `${url}claim/register/read-documents`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token,
                },
                withCredentials: true
            }
        );

        return response.data;
    } catch (error) {
        console.error('Błąd podczas wysyłania dokumentu:', error);
        throw error;
    }

}