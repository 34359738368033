import { CartT, DeliveryT, SetValidFormT } from "Store/modules/luggageStore/shop";
import Regs from "./regexps";

import CardVisa from 'Assets/cards/logo-visa.png';
// import CardAmericanExpress from 'Assets/cards/american-express.png';
// import CardBankOfAmerica from 'Assets/cards/bank-of-america.png';
// import CardBarklays from 'Assets/cards/barclays.png';
// import CardCapitalOne from 'Assets/cards/capital-one.png';
import CardMasterCard from 'Assets/cards/master-card.png';
import { shopRequireNipCountrys } from "./rules";


export const chooseImageCart = (cardNumber: string): string | null => {
    if (!!cardNumber.trim().replaceAll("-", "").match(/^4[0-9]{12}(?:[0-9]{3})?$/)){
        return CardVisa;
    }
    if (!!cardNumber.trim().replaceAll("-", "").match(/^5[1-5][0-9]{14}$/)){
        return CardMasterCard;
    }

    return null;
}


export const validField = (type: string, data: any): boolean => {

    switch(type){
        case 'clientAddress-name': return data.length >= 2 && data.length <= 40 && !!data.match(Regs.letters)
        case 'clientAddress-surname': return data.length >= 2 && data.length <= 40 && !!data.match(Regs.surname)
        case 'clientAddress-street': return data.length >= 5 && data.length <= 60 && !!data.match(Regs.street)
        case 'clientAddress-city': return data.length >= 1 && data.length <= 60 && !!data.match(Regs.city)
        case 'clientAddress-zipCode': return data.length >= 3 && data.length <= 10 && !!data.match(Regs.zipCode)
        case 'clientAddress-country': return data.length >= 3 && data.length <= 100 && !!data.match(Regs.letters)
        case 'clientAddress-phoneNumber': return data.length >= 5
        case 'clientAddress-email': return data.length >= 6 && data.length <= 100 && !!data.match(Regs.email);
        case 'clientAddress-nip-brazil': case 'clientAddressDelivery-nip-brazil': return !!data.match(Regs.nipBrazilCpf);
        case 'clientAddress-companyTaxNumber': case 'clientAddressDelivery-companyTaxNumber': return data.length >= 5 && data.length <= 40;

        case 'clientAddressDelivery-name': return data.length >= 2 && data.length <= 100 && !!data.match(Regs.letters)
        case 'clientAddressDelivery-surname': return data.length >= 2 && data.length <= 100 && !!data.match(Regs.surname)
        case 'clientAddressDelivery-street': return data.length >= 5 && data.length <= 100
        case 'clientAddressDelivery-city': return data.length >= 3 && data.length <= 100 && !!data.match(Regs.city)
        case 'clientAddressDelivery-zipCode': return data.length >= 4 && data.length <= 20 && !!data.match(Regs.numbers)
        case 'clientAddressDelivery-zipCodeCommon': return data.length >= 4 && data.length <= 20
        case 'clientAddressDelivery-country': return data.length >= 3 && data.length <= 100 && !!data.match(Regs.letters)
        case 'clientAddressDelivery-phoneNumber': return data.length >= 9
        case 'clientAddressDelivery-email': return data.length >= 6 && data.length <= 100 && !!data.match(Regs.email);
        case 'creditCard-firstName': return data.length >= 2 && data.length <= 100 && !!data.match(Regs.letters)
        case 'creditCard-lastName': return data.length >= 3 && data.length <= 100 && !!data.match(Regs.letters)
        case 'creditCard-cardNumber': return !!data.match(Regs.creditCardNumber);
        case 'creditCard-exp': return !!data.match(Regs.creditCardExp);
        case 'creditCard-cvv': return !!data.match(Regs.creditCardCvv);
        case 'paypal-id': return data.length >= 3 && data.length <= 100 && !!data.match(Regs.letters);
        case 'reference-number': return !!data.match(Regs.referenceNumber);
        case 'email': return data.length >= 6 && data.length <= 100 && !!data.match(Regs.email);
    }

    return true;
};

export const checkValidForm = (dispatch: (data: any) => void, cart: CartT, termsAccept: boolean, setValidForm: (data: boolean) => SetValidFormT, delivery: DeliveryT, totalAmount: number) => {

    dispatch(setValidForm(true));
    if (cart.products.length === 0) return dispatch(setValidForm(false));
    if (!termsAccept) return dispatch(setValidForm(false));

    if (totalAmount > 0 && !delivery.paymentChoose) return dispatch(setValidForm(false));

    const adressFieleds = ['name', 'surname', 'street', 'city', 'zipCode'];
    for(let i=0;i<adressFieleds.length;i++){
        if (!validField(`clientAddress-${adressFieleds[i]}`, delivery.clientAddress[adressFieleds[i]])){
            return dispatch(setValidForm(false));
        }
    }
    if (!delivery.clientAddressDeliveryOn){
        for(let i=0;i<adressFieleds.length;i++){
            if (!validField(`clientAddressDelivery-${adressFieleds[i]}`, delivery.clientAddressDelivery[adressFieleds[i]])){
                return dispatch(setValidForm(false));
            }
        }
        if (shopRequireNipCountrys.includes(delivery.clientAddressDelivery.countryCode) && !validField('clientAddressDelivery-nip-brazil', delivery.clientAddressDelivery.companyTaxNumber)){
            return dispatch(setValidForm(false));
        }
    }

    if (shopRequireNipCountrys.includes(delivery.clientAddress.countryCode) && !validField('clientAddress-nip-brazil', delivery.clientAddress.companyTaxNumber)){
        return dispatch(setValidForm(false));
    }

    if (totalAmount > 0 && delivery.paymentChoose === 'CREDIT_CARD'){
        const cardFields = ['cardNumber', 'exp', 'cvv'];
        let validCards = true;
        for(let i=0;i<cardFields.length;i++){
            if (!validField(`creditCard-${cardFields[i]}`, delivery.payment.creditCard[cardFields[i]])){
                validCards = false;
                
            }
        }
        if (validCards && !chooseImageCart(delivery.payment.creditCard.cardNumber)){
            validCards = false;
        }
        if (!validCards){
            return dispatch(setValidForm(false));
        }
    }
    // if (totalAmount > 0 && delivery.paymentChoose === 'PAYPAL'){
    //     if (!validField(`paypal-id`, delivery.payment.paypal.id)){
    //         return dispatch(setValidForm(false));
    //     }
    // }
    dispatch(setValidForm(true));
}