import React, { useMemo } from 'react';

import PickupEvaluationByCustomerService from './Pickup/PickupEvaluationByCustomerService/PickupEvaluationByCustomerService';
import BlackCaseAwaitingPaxCompletion from "./BlackCase/BlackCaseAwaitingPaxCompletion/BlackCaseAwaitingPaxCompletion";
import PickupRepairCompletedByPartner from './Pickup/PickupRepairCompletedByPartner/PickupRepairCompletedByPartner';
import AssessmentVerdictAccessShop from "./Shop/AssessmentVerdictAccessShop/AssessmentVerdictAccessShop";
import ReportProblemCompleted from "./ReportProblem/ReportProblemCompleted/ReportProblemCompleted";
import PickupShipmentInProcess from "./Pickup/PickupShipmentInProcess/PickupShipmentInProcess";
import RepairBudgetDataEntry from "./RepairBudget/RepairBudgetDataEntry/RepairBudgetDataEntry";
import RepairBudgetCompleted from "./RepairBudget/RepairBudgetCompleted/RepairBudgetCompleted";
import PickupProcessByPartner from './Pickup/PickupProcessByPartner/PickupProcessByPartner';
import PickupShipmentTracking from './Pickup/PickupShipmentTracking/PickupShipmentTracking';
import { ClaimProgressStageE, ClaimT, IClaimFeedEntry } from "../DataStructure/interfaces";
import PickupRepairCompleted from "./Pickup/PickupRepairCompleted/PickupRepairCompleted";
import PickupShipmentPending from './Pickup/PickupShipmentPending/PickupShipmentPending';
import AutomaticVerdictStarted from "./AutomaticVerdictStarted/AutomaticVerdictStarted";
import MCRefundProcess from "./MoneyCompensation/MCRefundProcess/MCRefundProcess";
import ReportProblem from "./ReportProblem/ReportProblemDataEntry/ReportProblem";
import OneToOneReplacement from "./OneToOneReplacement/OneToOneReplacement";
import PickupSpareParts from "./Pickup/PickupSpareParts/PickupSpareParts";
import PickupCompleted from "./Pickup/PickupCompleted/PickupCompleted";
import PickupDataEntry from "./Pickup/PickupDataEntry/PickupDataEntry";
import AssessmentProcess from "./AssessmentProcess/AssessmentProcess";
import AssessmentPending from "./AssessmentPending/AssessmentPending";
import MCDataEntry from "./MoneyCompensation/MCDataEntry/MCDataEntry";
import MCByAirline from "./MoneyCompensation/MCByAirline/MCByAirline";
import OriginalDocuments from "./OriginalDocuments/OriginalDocuments";
import PickupShipment from './Pickup/PickupShipment/PickupShipment';
import MCNoReport from "./MoneyCompensation/MCNoReport/MCNoReport";
import PickupDropOff from "./Pickup/PickupDropOff/PickupDropOff";
import PickupVerdict from "./Pickup/PickupVerdict/PickupVerdict";
import WaitingProgress from "./WaitingProgress/WaitingProgress";
import MCByDolfi from "./MoneyCompensation/MCByDolfi/MCByDolfi";
import PickupRepair from "./Pickup/PickupRepair/PickupRepair";
import SelectStore from "./Shop/SelectStore/SelectStore";
import CardOrder from "./Shop/CardOrder/CardOrder";
import Register from "./Register/Register";
import style from './Entry.module.scss';
import Blocked from "./Blocked/Blocked";
import Closed from "./Closed/Closed";

type MainPropsT = {
    entry: IClaimFeedEntry,
    t: (data: string, params?: any) => string,
    claim: ClaimT,
    isMobile: boolean,
    openCreateClaimDesktop?: () => void
}

const Entry: React.FC<MainPropsT> = ({ entry, t, claim, isMobile, openCreateClaimDesktop }) => {

    const chooseStage = useMemo(() => {

        const verdict = entry?.values?.verdict || claim?.verdict;

        switch (entry.stage){
            case ClaimProgressStageE.REGISTERED: return <Register t={t} />
            case ClaimProgressStageE.ASSESSMENT_PENDING: return <AssessmentPending t={t} />
            case ClaimProgressStageE.AWAITING_PROGRESS: return <WaitingProgress untilDate={entry.validUntil} createdAt={entry.createdAt} />
            case ClaimProgressStageE.AUTOMATIC_VERDICT_STARTED: return <AutomaticVerdictStarted t={t} />
            case ClaimProgressStageE.ASSESSMENT_EXPERT_EVALUATION_IN_PROCESS: return <AssessmentProcess t={t} />

            case ClaimProgressStageE.ASSESSMENT_VERDICT_ACCESSS_TO_SHOP: return <AssessmentVerdictAccessShop t={t} disabled={entry.isDisabled} verdict={entry.values?.verdict} airlineName={entry?.values?.airlineName} luggageCost={entry.values?.luggageCost || 0} luggageCurrencyIsoCode={entry?.values?.luggageCurrencyIsoCode || '?'} />
            case ClaimProgressStageE.SHOP_ACTION_REQUIRED_SELECT_ITEMS: return <SelectStore t={t} disabled={entry.isDisabled} catalogs={entry.values?.catalogs || []} claimId={claim.id} />
            case ClaimProgressStageE.SHOP_ORDER_CONFIRMED: return <CardOrder disabled={entry.isDisabled} order={entry.values?.order} />

            case ClaimProgressStageE.ASSESSMENT_VERDICT_PICKUP: return <PickupVerdict t={t} /> // start pickup

            case ClaimProgressStageE.PICKUP_ACTION_COMPLETED_PAX_DATA: return <PickupDropOff t={t} location={entry.values?.pickUp?.location} pickupDate={entry.values?.pickUp?.date} email={entry.values?.pickUp?.email} /> // show data user - [SOLUTION_PICKUP_PROCESS]
            case ClaimProgressStageE.PICKUP_ACTION_REQUIRED_PAX_DATA: return <PickupDataEntry t={t} claim={claim} disabled={entry.isDisabled} /> // pickup pax input data [SOLUTION_PICKUP_PENDING]
            case ClaimProgressStageE.PICKUP_AWAITING_SPARE_PARTS: return <PickupSpareParts t={t} /> // [SOLUTION_PENDING_SPARE_PARTS]
            case ClaimProgressStageE.PICKUP_COLLECTED_FROM_PAX: return <PickupCompleted t={t} /> // [SOLUTION_PICKUP_COMPLETED]
            case ClaimProgressStageE.PICKUP_EVALUATION_BY_CUSTOMER_SERVICE: return <PickupEvaluationByCustomerService t={t} />
            case ClaimProgressStageE.PICKUP_PROCESS_BY_PARTNER: return <PickupProcessByPartner t={t} country={entry.values?.country} countryCode={entry.values?.countryCode} />
            case ClaimProgressStageE.PICKUP_REPAIR_COMPLETED: return <PickupRepairCompleted t={t} /> // [SOLUTION_REPAIR_COMPLETED]
            case ClaimProgressStageE.PICKUP_REPAIR_COMPLETED_BY_EXTERNAL_PARTNER: return <PickupRepairCompletedByPartner country={entry.values?.country} externalPartnerName={entry.values?.externalPartnerName} t={t} /> // [SOLUTION_REPAIR_BY_EXTERNAL_PARTNER]
            case ClaimProgressStageE.PICKUP_REPAIR_IN_PROGRESS: return <PickupRepair t={t} /> // [SOLUTION_PICKUP_PROCESS]
            case ClaimProgressStageE.PICKUP_SHIPMENT: return <PickupShipment t={t} />
            case ClaimProgressStageE.PICKUP_SHIPMENT_TRACKING: return <PickupShipmentTracking t={t} url={entry?.values?.trackingUrl} trackingNumber={entry?.values?.trackingNumber} />

            case ClaimProgressStageE.ASSESSMENT_VERDICT_MONEY_COMPENSATION_BY_DOLFI: return <MCByDolfi t={t} verdict={verdict} airlineName={entry?.values?.airlineName || ''} disabled={entry.isDisabled} /> // [SOLUTION_REFUND_PENDING]
            case ClaimProgressStageE.ASSESSMENT_VERDICT_MONEY_COMPENSATION_BY_AIRLINE: return <MCByAirline t={t} airlineName={entry?.values?.airlineName || ''} /> // [SOLUTION_REFUND_PROCESS]
            case ClaimProgressStageE.MONEY_COMPENSATION_ACTION_REQUIRED_PAX_ACCOUNT_DATA: return <MCDataEntry t={t} disabled={entry.isDisabled} moneyCompensationUrl={entry.values?.moneyCompensationUrl || ''} />
            case ClaimProgressStageE.MONEY_COMPENSATION_REFUND_IN_PROGRESS: return <MCRefundProcess t={t} bankAccount={entry.values?.bankAccount || {}} />
            case ClaimProgressStageE.REPAIR_BUDGET_ACTION_REJECTED_PAX_DATA: return <MCNoReport t={t} />

            case ClaimProgressStageE.REPAIR_BUDGET_ACTION_REQUIRED_PAX_DATA: return <RepairBudgetDataEntry t={t} disabled={entry.isDisabled} claimId={claim.id} />
            case ClaimProgressStageE.REPAIR_BUDGET_ACTION_COMPLETED_PAX_DATA: return <RepairBudgetCompleted t={t} disabled={entry.isDisabled} photos={entry?.values?.repairBudgetPhotos || []} />

            case ClaimProgressStageE.SHIPMENT_READY_FOR_DISPATCH: return <PickupShipmentPending t={t} /> // [SHIPMENT_PENDING]
            case ClaimProgressStageE.SHIPMENT_IN_PROGRESS: return <PickupShipmentInProcess t={t} shipment={entry?.values?.shipment} /> // [SHIPMENT_OUT_FOR_DELIVERY]

            case ClaimProgressStageE.VALIDATION_PAX_PROBLEM_REPORTED: return <ReportProblem t={t} claim={claim} disabled={entry.isDisabled} tags={entry?.values?.tags || []} reportId={entry?.values?.reportId} requiredDocuments={entry?.values?.requiredDocuments || []} />
            case ClaimProgressStageE.VALIDATION_PAX_PROBLEM_ADDRESSED: return <ReportProblemCompleted t={t} resolution={entry?.values?.resolution} />

            // case ClaimProgressStageE.PAX_ACTION_PICKUP_DATA_PROVIDED: return <PickupDropOff claim={claim} t={t} />
            case ClaimProgressStageE.BLOCKED:
            case ClaimProgressStageE.BLACKCASE_RETURNED_TO_SF:
                return <Blocked claim={claim} t={t} verdict={verdict} blockReason={entry?.values?.blockReason || claim?.blockReason || ''} />
            case ClaimProgressStageE.CLOSED: return <Closed t={t} review={entry?.values?.review} claim={claim} />

            case ClaimProgressStageE.ASSESSMENT_VERDICT_ONE_TO_ONE_REPLACEMENT: return <OneToOneReplacement t={t} />
            // case ClaimProgressStageE.ASSESSMENT_VERDICT_ACCESSS_TO_SHOP: return <PickupDropOff t={t} claim={claim} />

            case ClaimProgressStageE.VALIDATION_PAX_MISSING_ORIGINAL_DOCUMENTS: return <OriginalDocuments t={t} branch={entry?.values?.branch || claim.branch} missingOriginalDocuments={entry?.values?.missingOriginalDocuments || []} />

            case ClaimProgressStageE.BLACKCASE_AWAITING_PAX_COMPLETION: 
            case ClaimProgressStageE.DOWNLOADED_FROM_SF: 
                return <BlackCaseAwaitingPaxCompletion t={t} claim={claim} openCreateClaimDesktop={openCreateClaimDesktop} />

            default: return null;
        }
    }, [entry, t, claim, openCreateClaimDesktop]);

    return (
        <div className={style.root}>
            {chooseStage}
        </div>
    );
}
export default React.memo(Entry);